import React from 'react'
import {connect} from "react-redux";
import {NavLink, withRouter} from 'react-router-dom';

import {API, Storage} from "aws-amplify";
import {Alert, Button} from 'antd';

import moment from 'moment';
import defaultPdf from "../../assets/img/receipts/downloadInvoicePdf.png";
import withStyles from "@material-ui/core/styles/withStyles";
import {isEmpty} from "../../libs/miscFunc";


const styles = {
    payNowButton: {
        marginRight: '20px',
        backgroundColor: 'rgb(74, 176, 193)',
        borderColor: 'rgb(74, 176, 193)',
        color: '#fff',
        textTransform: 'uppercase',
        '&:hover': {
            backgroundColor: 'rgb(74, 176, 193)',
            color: '#fff'
        }
    },

}
class ClientStatusAlert extends React.Component {
    constructor(props) {
        super(props)
        this.state = {
        }
    }

    componentDidMount() {
        //
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        if (prevProps.app_state.current_client !== this.props.app_state.current_client) {
          //
        }
      }
    
    render() {
        if (!this.props.app_state) return null
        if (!this.props.app_state.current_client) return null

        switch(this.props.app_state.current_client.account_status) {
        
            case 1:
                return <Alert
                message={<span><strong>Foreign Exchange Account Status: </strong> </span>}
                description={<>
                    <div style={{display: "flex", justifyContent: 'space-between'}}>
                            <p>
                            Thank you. Your online registration has been received.
                            <br/>
                            To activate your account, Australian regulations require that we verify your identity.
                            <br/>
                            Once we receive your documents we will electronically verify you based on the documents that you have provided.
                            <br/>
                            When we successfully verify you, your account will be activated and you will be notified via email.
                            </p>
                        </div>
                    </>}
                type="warning"
                showIcon
                closable
                />
                break
            case 5:
                return <Alert
                message={<span><strong>Foreign Exchange Account Status: </strong> </span>}
                description={<>
                    <div style={{display: "flex", justifyContent: 'space-between'}}>
                            Your account status is INCOMPLETE.
                            <br/>
                            To fully activate your account, and make foreign exchange transactions, 
                            Australian regulations require that we verify your identity.
                            <br/>
                            Please click the button to the right to complete your application.
                            <hr/>
                            <Button size={"large"} 
                                onClick={() => this.props.history.push('/registration')}
                            >Complete Application</Button>
                        </div>
                    </>}
                type="warning"
                showIcon
                closable
                />
                break
            default:
                return null
        }

    }
}

// export default (TransferAlerts)


const mapStateToProps = state => {
    return {
      app_state: state.app_state,
    };
  };
  
  const mapDispatchToProps = dispatch => {
    return {
    };
  };
  
  ClientStatusAlert = connect(
    mapStateToProps,
    mapDispatchToProps
  )(withRouter(ClientStatusAlert));
  
  export default withStyles(styles)(ClientStatusAlert);
  