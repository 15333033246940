import React, {useEffect, useRef, useState} from 'react';
import {API, Storage} from 'aws-amplify'
import {
    Alert,
    Button,
    Card,
    Col,
    Collapse,
    Descriptions,
    Divider,
    Form,
    Image,
    Input,
    InputNumber,
    message,
    Modal,
    Row,
    Select,
    Space,
    Typography
} from "antd";
import CurrencySelect from "../../components/CurrencySelect/CurrencySelect";
import {connect} from "react-redux";
import {fetchCurrenciesNotDeletedList} from "../../redux/reducers/currencies";
import {useForm} from "antd/es/form/Form";
import withStyles from "@material-ui/core/styles/withStyles";
import {CloseOutlined, PlusOutlined} from "@ant-design/icons";
import FileUploader from "../../components/FileUploader/FileUploader";
import axios from "axios";
import BeneficiaryModal from "../../components/Beneficiaries/BeneficiaryModal";
import {VectorMap} from "react-jvectormap";
import Draggable from "react-draggable";
import TypographyCurrency from "../../components/CurrencySelect/TypographyCurrency";
import {CurrencyLocales} from "../../AppUtil";
import dayjs from "dayjs";

const {Panel} = Collapse;

const currency = {
    1: ['AU'],
    5: ['US'],
    6: ['AT'],
    8: ['GB'],
    9: ['GB', 'IT', 'ES', 'FR', 'PT', 'DE'],
    16: ['JP'],
    17: ['NZ'],
    18: ['CA'],
    19: ['HK'],
    20: ['SG'],
    21: ['CH'],
    22: ['TH'],
    23: ['DK'],
    24: ['NO'],
    25: ['SE'],
    30: ['NP'],
    31: ['VN']
}

const getCurrencyObject = (currencyId, value) => {
    const currencies = currency[currencyId] || [];
    return currencies.reduce((acc, currency) => {
        acc[currency] = value;
        return acc;
    }, {});
};

const styles = {
    card: {
        border: 0,
        width: '100%',
        padding: 0,
        margin: 0,
        marginBottom: '10px',
        borderRadius: '8px'
    }
}
const BillPayments = (props) => {

    const [form] = useForm();
    const [state, setState] = useState({
        currencies: []
    });
    const beneficiaryModalRef = useRef();
    const [countDown, setCountDown] = useState(30);
    const [componentState, setComponentState] = useState({
        fromCurrency: 1,
        toCurrency: 5,
        formValues: null,
        beneficiariesList: [],
        allBeneficiariesList: [],
        purposeOfPayment: [],
        client_rate: 0,
        isModalOpen: false,
        mapData: {AU: 0, US: 1},
        activeFile: null,
        beneficiaryDetail: null,
        isConfirmed: false,
    });



    const setBeneficiary = (name, val) => {
        setComponentState(prev => ({
            ...prev,
            beneficiaryDetail: {
                ...prev.beneficiaryDetail,
                [name]: prev.beneficiariesList.find(item => item.id === val)
            }
        }))
    }

    const [modalDrag, setModalDrag] = useState({
        disabled: true,
        bounds: {left: 0, top: 0, bottom: 0, right: 0}
    });
    const draggableRef = useRef(null);

    const initData = () => {
        Promise.all([
            API.post("commons", "/fetch", {
                body: {
                    context: 'currencies',
                    fields: ['*'],
                    condition: {deleted: false}
                }
            }),
            API.post("commons", "/fetch", {
                body: {
                    context: 'beneficiaries',
                    fields: ['*'],
                    condition: {deleted: false, client_id: props.app_state.current_client.id}
                }
            }),
            API.post("commons", "/fetch", {
                    body: {
                        context: 'admin_purpose_of_payment',
                        fields: ['*'],
                        condition: {teamId: [0, props.app_state.current_client['team_id']]}
                    }
                }
            )
        ]).then(([currencies, beneficiaries, purposeOfPayment]) => {
            beneficiaries.forEach(item => {
                item.currency = currencies.find(i => i.id === item.account_currency);
            })
            setComponentState(prev => ({
                ...prev,
                allBeneficiariesList: beneficiaries,
                beneficiariesList: beneficiaries.filter(item => item.account_currency === componentState.toCurrency),
                purposeOfPayment: purposeOfPayment
            }));
            setState(prev => ({...prev, currencies: currencies}));
        })
    }

    const getFormFieldValue = (key, fieldName) => {
        return componentState.formValues
            && componentState.formValues.beneficiaries
            && componentState.formValues.beneficiaries[key]
            && componentState.formValues.beneficiaries[key][fieldName] || null;
    }

    const getFormBeneficiaryInfo = (key, fieldName) => {
        const beneId = getFormFieldValue(key, fieldName);
        if (beneId != null) {
            const bene = componentState.beneficiariesList.find(item => item.id === beneId);
            if (bene != null) {
                return bene.nickname
            }
        }
    }

    const confirmPayment = () => {
        const values = form.getFieldsValue();
        const from_currency = getCurrency('from_currency');
        const to_currency = getCurrency('to_currency');
        values.nickname = `${values.amount_from} ${from_currency.iso_alpha_3} to ${values.amount_to} ${to_currency.iso_alpha_3} settling ${dayjs(new Date()).startOf('day').format('DD/MM/YYYY')}`
        values.client_id = props.app_state.current_client.id;
        values.client_rate = componentState.client_rate;
        values.isMultiPay = values.beneficiaries.length > 1;
        if (values.beneficiaries && (values.beneficiaries.some(i => i == null))) {
            message.error("Please fill the payments Properly");
            return;
        }
        if (values.beneficiaries.length === 0) {
            message.info("At least one payment must be made");
            return;
        }
        values.beneficiaries.forEach(item => {
            if (item.amount_reverse != null && item.amount_reverse !== "") {
                item.amount = item.amount_reverse / componentState.client_rate;
            }
        });
        Modal.confirm({
            title: 'Proceed with Payment',
            content: 'Are you sure you want to proceed with the payment ?',
            onOk: () => {
                const loading = message.loading("Creating Bill payments. Please wait..", 0);
                API.post("billPayments", "/create", {body: values})
                    .then(res => {
                        message.success("Bill Payment created");
                        props.history.push("/transfers/current_list");
                    })
                    .catch(err => {
                        console.log(err);
                        message.error("Unable to make a bill payment request. Please try again..");
                    })
                    .finally(() => loading());
            }
        });
    }

    const fetchCurrencyRate = (curState) => {
        const fromCurrency = state.currencies.find(i => i.id === curState.from_currency);
        const toCurrency = state.currencies.find(i => i.id === curState.to_currency);
        const url = `https://apilayer.net/api/live?access_key=a4eb7fd0501842eb4d4712cc459cae5f&currencies=${toCurrency.iso_alpha_3}&source=${fromCurrency.iso_alpha_3}&format=1`;
        axios.get(url).then(res => {
            if (curState.from_currency === curState.to_currency) {
                setComponentState(prev => ({...prev, client_rate: 1.00}));
            } else {
                const rate = res.data.quotes[`${fromCurrency.iso_alpha_3}${toCurrency.iso_alpha_3}`];
                setComponentState(prev => ({...prev, client_rate: rate}));
            }
        })
    }

    const setFormFields = (values) => {
        if (values.beneficiaries && values.beneficiaries.length > 0) {
            let fromAmount = 0;
            let toAmount = 0;
            values.beneficiaries.forEach(item => {

                if (item && item.amount != null && item.amount !== "") {
                    fromAmount += (item && item.amount / componentState.client_rate) || 0;
                    toAmount += (item && item.amount || 0);
                }
                if (item && item.amount_reverse != null && item.amount_reverse !== "") {
                    fromAmount +=  (item && item.amount_reverse) || 0;
                    toAmount += (item && item.amount_reverse * componentState.client_rate) || 0;
                }
            });
            form.setFieldsValue({
                amount_from: fromAmount.toFixed(2),
                amount_to: toAmount.toFixed(2)
            });
        }
    }

    const setMap = () => {
        const fromCurrency = state.currencies.find(i => i.id === componentState.fromCurrency);
        const toCurrency = state.currencies.find(i => i.id === componentState.toCurrency);
        setComponentState(prev => ({
            ...prev,
            mapData: {
                ...getCurrencyObject(fromCurrency.id, 0),
                ...getCurrencyObject(toCurrency.id, 1)
            }
        }));
    }

    const getUploadedFilePreview = async (file) => {
        file.fileUrl = await Storage.get(file.name, {expires: 60});
        setComponentState(prev => ({...prev, activeFile: file}));
    }

    const onStart = (_event, uiData) => {
        const { clientWidth, clientHeight } = window.document.documentElement;
        const targetRect = draggableRef.current.getBoundingClientRect();
        if (!targetRect) {
            return;
        }
        setModalDrag(prev => ({
            ...prev,
            bounds: {
                left: -targetRect.left + uiData.x,
                right: clientWidth - (targetRect.right - uiData.x),
                top: -targetRect.top + uiData.y,
                bottom: clientHeight - (targetRect.bottom - uiData.y),
            }
        }));
    };

    const getCurrency = (currencyId) => {
        return state.currencies.find(i => i.id === form.getFieldValue(currencyId));
    }

    const renderPayments = () => {
        const beneficiaries = form.getFieldValue("beneficiaries") || [];
        const nodes = [];
        beneficiaries.filter(i => i && i.beneficiary_id != null).forEach((item, index) => {
            const beneficiary = componentState.allBeneficiariesList.find(i => item.beneficiary_id === i.id);
            const purpose_of_payment = componentState.purposeOfPayment.find(i => i.id === item.purpose_of_payment);
            nodes.push(<>
                <Descriptions column={1} size={'small'} bordered={false}>
                    <Descriptions.Item span={8} label={'Beneficiary'}>
                        <div style={{ display: 'flex', flexDirection: 'column'}}>
                            <span>{beneficiary.nickname}</span>
                            <span>(ACC: {beneficiary.account_number})</span>
                        </div>
                    </Descriptions.Item>
                    <Descriptions.Item span={8} label={'Amount'}>{item.amount} {getCurrency('to_currency').full_name}</Descriptions.Item>
                    <Descriptions.Item span={8} label={'Purpose of payment'}>{purpose_of_payment ? purpose_of_payment.description : '-'}</Descriptions.Item>
                    {
                        item.detail_1 && <Descriptions.Item span={8} label={'Detail'}>{item.detail_1}</Descriptions.Item>
                    }
                    <Descriptions.Item span={8} label={'Payment Detail'}>{item.purpose_of_payment_detail}</Descriptions.Item>
                </Descriptions>
                {
                    index > 0 && <Divider />
                }
            </>);
        });
        return nodes;
    }

    useEffect(() => {
        if (state.currencies.length > 0) {
            fetchCurrencyRate({from_currency: componentState.fromCurrency, to_currency: componentState.toCurrency});
        }
    }, [state.currencies]);


    useEffect(() => {
        if (props.app_state.current_client) {
            initData();
        }
    }, [props.app_state.current_client]);

    useEffect(() => {
        if (state.currencies.length > 0) {
            fetchCurrencyRate({from_currency: componentState.fromCurrency, to_currency: componentState.toCurrency});
            setMap();
        }
    }, [componentState.fromCurrency, componentState.toCurrency]);

    useEffect(() => {
        if (state.currencies.length > 0) {
            setFormFields(form.getFieldsValue());
        }
    }, [componentState.client_rate]);

    useEffect(() => {
        if (componentState.isConfirmed) {
            if (countDown > 0) {
                const timer = setTimeout(() => setCountDown(countDown - 1), 1000);
                return () => clearTimeout(timer);
            } else {
                fetchCurrencyRate({
                    from_currency: form.getFieldValue("from_currency"),
                    to_currency: form.getFieldValue("to_currency")
                })
                message.info("Exchange Rate has been refreshed", 3);
                setCountDown(30); // Reset countdown to 30 seconds
            }
        }
    }, [countDown, componentState.isConfirmed]);

    return (
        props.app_state.current_client != null &&
        <Card title={'Bill Payments (Multi-Pay)'} style={{marginTop: '10px'}} bodyStyle={{padding: '15px'}}>
            <div style={{background: '#EEEEEE', padding: '10px', borderRadius: '8px'}}>
                <Form layout={'vertical'} name={'bill-payment-form'} initialValues={{
                    from_currency: 1,
                    to_currency: 5,
                    amount_from: 0,
                    amount_to: 0,
                    beneficiaries: [{
                        beneficiary_id: null,
                        uploads: []
                    }],
                }} form={form}
                      onValuesChange={(changedVal, allVal) => {
                          setComponentState(prev => ({...prev, formValues: allVal}));
                          setFormFields(allVal);
                      }}
                      onFinish={() => {
                        setComponentState(prev => ({...prev, isConfirmed: true }));
                        window.scrollTo({top: 0, behavior: 'smooth'});
                      }}>
                    <Row gutter={[16, 16]} style={{marginBottom: '10px'}}>
                        <Col xs={24} sm={24} md={24} lg={12} xl={12} xxl={12}>
                            <Card className={props.classes.card} size={'small'} style={{ display: componentState.isConfirmed ? 'none' : 'block' }}>
                            <span>Transfer Summary:</span>
                                <div style={{
                                    display: 'flex',
                                    justifyContent: 'space-between',
                                    alignItems: 'center',
                                    gap: '10px',
                                    marginBottom: '10px'
                                }}>
                                    <CurrencySelect state={state}
                                                    onCurrencyChange={(val, d) => {
                                                        setComponentState(prev => ({...prev, fromCurrency: d}));
                                                    }}
                                                    rightLabel={'You Send'}
                                                    validated
                                                    disabled={true}
                                                    currencyFieldName={'from_currency'}
                                                    fieldName={'amount_from'}></CurrencySelect>
                                </div>
                                <div style={{
                                    display: 'flex',
                                    justifyContent: 'space-between',
                                    alignItems: 'center',
                                    gap: '10px'
                                }}>
                                    <CurrencySelect state={state}
                                                    onCurrencyChange={(val, d) => {
                                                        const benes = componentState.allBeneficiariesList.filter(i => i.account_currency === d);
                                                        setComponentState(prev => ({ ...prev, toCurrency: d, beneficiariesList: benes}));
                                                        form.setFieldsValue({beneficiaries: [
                                                                {beneficiary_id: null, uploads: []}
                                                            ]});
                                                        if (beneficiaryModalRef.current != null) {
                                                            beneficiaryModalRef.current.setFields({ account_currency: d });
                                                        }
                                                        setComponentState(prev => ({...prev, beneficiaryDetail: null}));
                                                    }}
                                                    rightLabel={'Total Payouts'}
                                                    validated
                                                    disabled={true}
                                                    currencyFieldName={'to_currency'}
                                                    fieldName={'amount_to'}></CurrencySelect>
                                </div>
                            </Card>
                            <Card title={<div style={{display: 'flex', justifyContent: 'space-between'}}>
                                <span>Payouts:</span>
                                <Button type={'primary'}
                                        size={'small'}
                                        // danger={true}
                                        onClick={() => {
                                            setComponentState(prev => ({...prev, isModalOpen: true}));
                                            if (beneficiaryModalRef.current != null) {
                                                beneficiaryModalRef.current.setFields({ account_currency: form.getFieldValue("to_currency")});
                                            }
                                        }}
                                        icon={<PlusOutlined/>}>Add New Beneficiary</Button>
                            </div>}
                                  size={'small'}
                                  style={{borderRadius: '5px', display: componentState.isConfirmed ? 'none' : 'block' }}>
                                <Form.List name={'beneficiaries'}>
                                    {(fields, {add, remove}) => (
                                        <>
                                            <>
                                                {fields.map(({key, name, ...restField}) =>
                                                    <>
                                                        <Space direction={'vertical'} style={{width: '100%'}}>
                                                            <Collapse style={{marginBottom: '10px'}} defaultActiveKey={key}>
                                                                <Panel header={<div style={{
                                                                    display: 'flex',
                                                                    justifyContent: 'space-between'
                                                                }}>
                                                                <span>
                                                                    <Typography.Text strong={true}>
                                                                        [Payment {name + 1}] {getFormFieldValue(name, 'amount') || '-' } {getFormBeneficiaryInfo(name, 'beneficiary_id') || '[No Payout Selected]'}
                                                                    </Typography.Text>
                                                                </span>
                                                                    <Button type={'link'}
                                                                            size='small'
                                                                            danger={true}
                                                                            icon={<CloseOutlined/>}
                                                                            onClick={(e) => {
                                                                                e.stopPropagation();
                                                                                remove(name);
                                                                                setComponentState(prev => {
                                                                                    if (prev.beneficiaryDetail && prev.beneficiaryDetail[name]) {
                                                                                        delete prev.beneficiaryDetail[name];
                                                                                    } else {
                                                                                        message.info("Assign beneficiary before removing..");
                                                                                    }
                                                                                    return prev;
                                                                                })
                                                                            }}>Remove</Button>
                                                                </div>}
                                                                       key={key}>
                                                                    <Row gutter={[16, 16]}>
                                                                        <Col xs={24} sm={24} md={24} lg={12} xl={12}
                                                                             xxl={12}>
                                                                            <Form.Item labelAlign={'left'}
                                                                                       label={'Select Beneficiary'}
                                                                                       rules={[{
                                                                                           required: true,
                                                                                           message: 'Beneficiary is required'
                                                                                       }]}
                                                                                       name={[name, 'beneficiary_id']} {...restField}>
                                                                                <Select optionFilterProp={'alt'}
                                                                                        style={{width: '100%'}}
                                                                                        onChange={(val) => setBeneficiary(name, val) }
                                                                                        placeholder={'-- SELECT --'}
                                                                                        allowClear={true}
                                                                                        showSearch={true}
                                                                                        options={componentState.beneficiariesList.map(i => ({
                                                                                            ...i,
                                                                                            label: <TypographyCurrency iso_alpha_3={i.currency.iso_alpha_3}>{i.nickname || i.ben_legal_name}</TypographyCurrency>,
                                                                                            alt: i.nickname || i.ben_legal_name || i.ben_email_main,
                                                                                            value: i.id
                                                                                        }))}></Select>
                                                                            </Form.Item>
                                                                            <Form.Item labelAlign={'left'}
                                                                                       label={getCurrency('from_currency') && <TypographyCurrency iso_alpha_3={getCurrency('from_currency').iso_alpha_3}>Amount ({getCurrency('from_currency').iso_alpha_3})</TypographyCurrency>}
                                                                                       rules={[
                                                                                           {
                                                                                               validator: (_, value) => {
                                                                                                   const benes = form.getFieldValue('beneficiaries');
                                                                                                   if (value) {
                                                                                                       benes[name].amount = '';
                                                                                                       form.setFieldValue("beneficiaries", benes);
                                                                                                   }
                                                                                                   return Promise.resolve();
                                                                                               },
                                                                                           },
                                                                                           {
                                                                                               validator: (_, value) => {
                                                                                                   const benes = form.getFieldValue('beneficiaries');
                                                                                                   if (!benes[name].amount_reverse && !benes[name].amount) {
                                                                                                       return Promise.reject(new Error('At least one field must be filled'));
                                                                                                   }
                                                                                                   return Promise.resolve();
                                                                                               },
                                                                                           }
                                                                                       ]}
                                                                                       name={[name, 'amount_reverse']} {...restField}>
                                                                                <InputNumber style={{width: '200px'}}/>
                                                                            </Form.Item>
                                                                            <Form.Item labelAlign={'left'}
                                                                                       label={getCurrency('to_currency') && <TypographyCurrency iso_alpha_3={getCurrency('to_currency').iso_alpha_3}>Amount ({getCurrency('to_currency').iso_alpha_3})</TypographyCurrency>}
                                                                                       rules={[
                                                                                           {
                                                                                               validator: (_, value) => {
                                                                                                   const benes = form.getFieldValue('beneficiaries');
                                                                                                   if (value) {
                                                                                                       benes[name].amount_reverse = '';
                                                                                                       form.setFieldValue("beneficiaries", benes);
                                                                                                   }
                                                                                                   return Promise.resolve();
                                                                                               },
                                                                                           },
                                                                                           {
                                                                                               validator: (_, value) => {
                                                                                                   const benes = form.getFieldValue('beneficiaries');
                                                                                                   if (!benes[name].amount_reverse && !benes[name].amount) {
                                                                                                       return Promise.reject(new Error('At least one field must be filled'));
                                                                                                   }
                                                                                                   return Promise.resolve();
                                                                                               },
                                                                                           }
                                                                                       ]}
                                                                                       name={[name, 'amount']} {...restField}>
                                                                                <InputNumber style={{ width: '200px' }}/>
                                                                            </Form.Item>
                                                                            <Form.Item
                                                                                labelAlign={'left'} {...restField}
                                                                                label={'Purpose of Payment'}
                                                                                rules={[{
                                                                                    required: true,
                                                                                    message: 'Purpose of payment required'
                                                                                }]}
                                                                                name={[name, 'purpose_of_payment']}>
                                                                                <Select optionFilterProp={'label'}
                                                                                        placeholder={'-- SELECT --'}
                                                                                        allowClear={true}
                                                                                        showSearch={true}
                                                                                        options={componentState.purposeOfPayment.map(i => ({
                                                                                            ...i,
                                                                                            label: i.description,
                                                                                            value: i.id
                                                                                        }))}></Select>
                                                                            </Form.Item>
                                                                            {
                                                                                getFormFieldValue(name, 'purpose_of_payment') === 1
                                                                                && <Form.Item
                                                                                    labelAlign={'left'} {...restField}
                                                                                    rules={[{
                                                                                        required: true,
                                                                                        message: 'Field is required if selected (other)'
                                                                                    }]}
                                                                                    label={'Specify Purpose of payment'}
                                                                                    name={[name, 'detail_1']}>
                                                                                    <Input/>
                                                                                </Form.Item>
                                                                            }
                                                                            <Form.Item
                                                                                labelAlign={'left'} {...restField}
                                                                                rules={[{
                                                                                    required: true,
                                                                                    message: 'payment detail / invoice no. is required'
                                                                                }]}
                                                                                label={'Payment Detail / Invoice No.'}
                                                                                name={[name, 'purpose_of_payment_detail']}>
                                                                                <Input/>
                                                                            </Form.Item>
                                                                        </Col>
                                                                        <Col xs={24} sm={24} md={24} lg={12} xl={12}
                                                                             xxl={12}>
                                                                            {
                                                                                componentState.beneficiaryDetail && componentState.beneficiaryDetail[name]
                                                                                && <Alert style={{ marginBottom: '10px' }} description={<Descriptions column={1} size={'small'}>
                                                                                    <strong>{componentState.beneficiaryDetail[name].nickname}</strong>
                                                                                    <Descriptions.Item style={{paddingBottom: 0}} label={'Account'}>{componentState.beneficiaryDetail[name].account_number}</Descriptions.Item>
                                                                                    {componentState.beneficiaryDetail[name].iban ? <Descriptions.Item style={{paddingBottom: 0}} label={'IBAN'}>{componentState.beneficiaryDetail[name].iban}</Descriptions.Item> : null}
                                                                                    {componentState.beneficiaryDetail[name].swift_code ? <Descriptions.Item style={{paddingBottom: 0}} label={'Swift'}>{componentState.beneficiaryDetail[name].swift_code}</Descriptions.Item> : null}
                                                                                    {componentState.beneficiaryDetail[name].bsb_code ? <Descriptions.Item style={{paddingBottom: 0}} label={'BSB'}>{componentState.beneficiaryDetail[name].bsb_code}</Descriptions.Item> : null}
                                                                                    {componentState.beneficiaryDetail[name].sort_code ? <Descriptions.Item style={{paddingBottom: 0}} label={'Sort Code'}>{componentState.beneficiaryDetail[name].sort_code}</Descriptions.Item> : null}
                                                                                    {componentState.beneficiaryDetail[name].transit_code ? <Descriptions.Item style={{paddingBottom: 0}} label={'Transit Code'}>{componentState.beneficiaryDetail[name].transit_code}</Descriptions.Item> : null}
                                                                                    {componentState.beneficiaryDetail[name].aba_routing_number ? <Descriptions.Item style={{paddingBottom: 0}} abel={'ABA Routing'}>{componentState.beneficiaryDetail[name].aba_routing_number}</Descriptions.Item> : null}
                                                                                </Descriptions>}></Alert>
                                                                            }
                                                                            <Form.Item name={[name, 'uploads']}
                                                                                       style={{marginBottom: 0}}
                                                                                       getValueFromEvent={(e) => {
                                                                                           return e.map(i => i.filename);
                                                                                       }}>
                                                                                <FileUploader state={state}
                                                                                              fetchFileList={() => {
                                                                                                  let files = componentState.formValues
                                                                                                  && componentState.formValues.beneficiaries != null
                                                                                                  && componentState.formValues.beneficiaries.uploads
                                                                                                      ? componentState.formValues.beneficiaries[name].uploads || []
                                                                                                      : [];
                                                                                                  const uploadedFiles = files.map(i => ({
                                                                                                      uid: i,
                                                                                                      status: 'done',
                                                                                                      name: i,
                                                                                                      filename: i
                                                                                                  }))
                                                                                                  return Promise.resolve(uploadedFiles);
                                                                                              }}
                                                                                              disablePreview={true}
                                                                                              emitFilePreview={(file) => {
                                                                                                  getUploadedFilePreview(file);
                                                                                              }}
                                                                                              onDelete={(file) => Promise.resolve(file)}
                                                                                              uploaderType={'DRAGDROP'}/>
                                                                            </Form.Item>
                                                                        </Col>
                                                                    </Row>
                                                                </Panel>
                                                            </Collapse>
                                                        </Space>
                                                    </>
                                                )}
                                            </>
                                            <Button type={'primary'} onClick={() => add()}
                                                    icon={<PlusOutlined/>}>Add New Payment</Button>
                                        </>
                                    )}
                                </Form.List>
                            </Card>
                            <Card title={'New Bill Pay Request - Confirm your Status'} style={{ display: componentState.isConfirmed ? 'block' : 'none' }}>
                                <Descriptions column={1} size={'small'} bordered={true}>
                                    <Descriptions.Item label={'You Buy'}>
                                        { getCurrency('from_currency') && <TypographyCurrency iso_alpha_3={getCurrency("from_currency").iso_alpha_3}>
                                            {new Intl
                                                .NumberFormat(CurrencyLocales[getCurrency("from_currency").iso_alpha_3], {
                                                    currency: getCurrency("from_currency").iso_alpha_3,
                                                    style: 'currency',
                                                    useGrouping: true,
                                                    minimumFractionDigits: 2,
                                                    maximumFractionDigits: 2
                                                })
                                                .format(form.getFieldValue("amount_from"))}
                                        </TypographyCurrency>}
                                    </Descriptions.Item>
                                    <Descriptions.Item label={'You Sell'}>
                                        { getCurrency('to_currency') && <TypographyCurrency iso_alpha_3={getCurrency("to_currency").iso_alpha_3}>
                                            {new Intl
                                                .NumberFormat(CurrencyLocales[getCurrency("to_currency").iso_alpha_3], {
                                                    currency: getCurrency("to_currency").iso_alpha_3,
                                                    style: 'currency',
                                                    useGrouping: true,
                                                    minimumFractionDigits: 2,
                                                    maximumFractionDigits: 2
                                                })
                                                .format(form.getFieldValue("amount_to"))}
                                        </TypographyCurrency> }
                                    </Descriptions.Item>
                                    <Descriptions.Item label={'Exchange Rate'}>{componentState.client_rate}</Descriptions.Item>
                                    <Descriptions.Item label={'Payments'}>{renderPayments()}</Descriptions.Item>
                                    {
                                        getCurrency('from_currency') && getCurrency('to_currency')
                                        && <Descriptions.Item label={'Request Summary'}>You sell {form.getFieldValue("amount_from")} {getCurrency("from_currency").iso_alpha_3} to buy {form.getFieldValue("amount_to")} {getCurrency("to_currency").iso_alpha_3}</Descriptions.Item>
                                    }
                                </Descriptions>
                            </Card>
                        </Col>
                        <Col xs={24} sm={24} md={24} lg={12} xl={12} xxl={12}>
                            <Card size={'small'}>
                                {!componentState.activeFile && <VectorMap
                                    map={'world_mill'}
                                    backgroundColor="transparent"
                                    zoomOnScroll={false}
                                    containerStyle={{
                                        width: '100%',
                                        height: '420px',
                                    }}
                                    containerClassName="map"
                                    regionStyle={{
                                        initial: {
                                            fill: '#e4e4e4',
                                            'fill-opacity': 0.9,
                                            stroke: 'none',
                                            'stroke-width': 0,
                                            'stroke-opacity': 0,
                                        },
                                    }}
                                    series={{
                                        regions: [
                                            {
                                                values: componentState.mapData,
                                                scale: ['#26c6da', '#4caf50'],
                                                normalizeFunction: 'polynomial',
                                            },
                                        ],
                                    }}
                                />}
                                {componentState.activeFile
                                    ? <> <h3>File Preview <Button type={'primary'} danger={true} onClick={() => setComponentState(prev => ({...prev, activeFile: null}))} icon={<CloseOutlined />}></Button></h3>
                                        {componentState.activeFile.type.startsWith('image') ?
                                        <div style={{marginTop: 16}}>
                                            <Image
                                                width={'100%'}
                                                src={componentState.activeFile.fileUrl}
                                                alt="Image preview"
                                                preview={false} // Disable the default Ant Design preview modal
                                            />
                                        </div> : <>
                                            <iframe src={componentState.activeFile.fileUrl} style={{ width: '100%', height: 500 }} title="PDF Preview" />;
                                        </>}</>
                                    : <></>}
                            </Card>
                        </Col>
                    </Row>
                    <Card bodyStyle={{padding: '10px', display: 'flex', justifyContent: 'end', gap: '5px' }}>
                        {
                            componentState.isConfirmed &&  <>
                                <Button type={'default'} htmlType={'button'} danger={true} onClick={() => {
                                    setComponentState(prev => ({...prev, isConfirmed: false}));
                                }}> Modify Request</Button>
                                <Button type={'primary'} htmlType={'button'} onClick={() => confirmPayment()}> Proceed ({countDown})</Button>
                            </>
                        }
                        {
                            !componentState.isConfirmed && <Button type={'primary'} htmlType={'submit'}> Next</Button>
                        }
                    </Card>
                </Form>
            </div>
            <Modal visible={componentState.isModalOpen}
                   width={'900px'}
                   style={{top: 10}}
                   bodyStyle={{maxHeight: '80vh', overflowY: 'scroll', padding: '10px', background: '#E0e0e0'}}
                   onCancel={() => {
                       beneficiaryModalRef.current.resetFields();
                       setComponentState(prev => ({...prev, isModalOpen: false}))
                   }}
                   onOk={() => {
                       beneficiaryModalRef.current.submit();
                   }}
                   modalRender={(modal) => (
                       <Draggable
                           disabled={modalDrag.disabled}
                           bounds={modalDrag.bounds}
                           nodeRef={draggableRef}
                           onStart={(event, uiData) => onStart(event, uiData)}
                       >
                           <div ref={draggableRef}>{modal}</div>
                       </Draggable>
                   )}
                   title={
                        <div style={{ width: '100%', cursor: 'move' }}
                             onMouseOver={() => {
                                 if (modalDrag.disabled) {
                                     setModalDrag(prev => ({...prev, disabled: false}));
                                 }
                             }}
                             onMouseOut={() => {
                                 setModalDrag(prev => ({...prev, disabled: true}));
                             }}>Add New Beneficiary</div>
                   }>
                <BeneficiaryModal clientId={props.app_state.current_client.id}
                                  onSubmit={(val) => {
                                      initData();
                                      setComponentState(prev => ({...prev, isModalOpen: false}));
                                  }}
                                  ref={beneficiaryModalRef}></BeneficiaryModal>
            </Modal>
        </Card>)
}

const mapStateToProps = (state) => {
    return {
        app_state: state.app_state
    }
}

const mapDispatchToProps = (dispatch) => {
    return {
        fetchCurrencies: () => {
            dispatch(fetchCurrenciesNotDeletedList())
        }
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(withStyles(styles)(BillPayments));

