import DashboardPageContainer from "../views/Pages/DashboardPage";
import ExternalTransferPageContainer from "../views/Pages/ExternalTransfer";
import DashboardLegacyPageContainer from "../views/Pages/DashboardLegacyPage";
import ShortcutTransferPageContainer from "../views/Pages/ShortcutTransferPage.jsx";
// import Reports from "../views/Dashboard/Reports.jsx";
// import NewsListContainer from "../views/Dashboard/News/NewsListContainer.js";
// import NewsShowContainer from "../views/Dashboard/News/NewsShowContainer.js";
import UserProfileContainer from "../views/Dashboard/UserProfile.jsx";
// @material-ui/icons
import DashboardIcon from "@material-ui/icons/Dashboard";
import GridOn from "@material-ui/icons/GridOn";
import SearchIcon from "@material-ui/icons/Search";
// import Timeline from "@material-ui/icons/Timeline";
import PermContactCalendar from "@material-ui/icons/PermContactCalendar";
import Accessibility from "@material-ui/icons/Accessibility";

//import UserSettings from "../views/Dashboard/UserSettings";
// import AddBeneficiary from "../views/Dashboard/AddBeneficiary";
// import BeneficiaryNewContainer from "../views/Dashboard/Beneficiaries/BeneficiariesNewContainer.js";
import BeneficiaryNewContainer from "../views/Dashboard/Beneficiaries/BeneficiariesNew.jsx";
import BeneficiariesListContainer from "../views/Dashboard/Beneficiaries/BeneficiariesList.jsx";
import BeneficiariesUploadContainer from '../views/Dashboard/Beneficiaries/BeneficiariesUpload';
import BeneficiaryEditContainer from "../views/Dashboard/Beneficiaries/BeneficiaryEdit";
// import TransferList from "../views/Dashboard/TransferList";

import TransfersListContainer from "../views/Dashboard/Transfers/TransfersListContainer";

import TransfersNewContainer from "../views/Dashboard/Transfers/TransfersNewContainer";
import TransfersEditPageContainer from "../views/Dashboard/Transfers/TransfersEditPage";
import IdentificationNewContainer from "../views/Dashboard/Identification/IdentificationNewContainer";
import IdentificationEditContainer from "../views/Dashboard/Identification/IdentificationEditContainer";
import IdentificationListContainer from "../views/Dashboard/Identification/IdentificationListContainer";
// import TransfersPaymentContainer from "../views/Dashboard/Transfers/TransfersPaymentContainer";
import WorldPayResponseContainer from "../views/Dashboard/Transfers/WorldPayResponse";
import TransfersNew2Container from "../views/Dashboard/Transfers_2/TransfersNew";
import CurrencyTools from "../views/Dashboard/CurrencyTools";
import MultipayPage from "../views/Pages/MultipayPage";
import SearchTransferPage from "../views/Pages/SearchTransferPage";
import Registration from "../views/Dashboard/Registration";
import PendingRegistration from "../views/Dashboard/PendingRegistration";
import TransfersListCurrentContainer from "../views/Dashboard/Transfers/TransfersListCurrentContainer";
import TransfersListPastContainer from "../views/Dashboard/Transfers/TransfersListPastContainer";
import PaymentRequestListPage from "../views/Dashboard/Transfers/PaymentRequestListPage";
import InvoicesListContainer from "../views/Dashboard/Invoicing/InvoicesListContainer";
import ViewInvoicePage from "../views/Dashboard/Invoicing/ViewInvoicePage";
import MigrateFromBackendPage from "../views/Dashboard/MigrateFromBackendPage";
import TransferReceiptPageContainer from "../views/Dashboard/Transfers/TransferReceiptPage.jsx";
import PayoutReceiptPageContainer from "../views/Dashboard/Transfers/PayoutReceiptPage.jsx";
import PaymentRequestViewPage from "../views/Dashboard/Transfers/PaymentRequestViewPage.jsx";
import HoldingAccountReceiptPageContainer from "../views/Dashboard/HoldingAccount/HoldingAccountReceiptPage";
import TransfersRemittancePageContainer from "../views/Dashboard/Transfers/TransfersRemittancePage.jsx";
import ForwardsListContainer from "../views/Dashboard/Forwards/ForwardsListContainer";
import ForwardsDetailContainer from "../views/Dashboard/Forwards/ForwardsDetailContainer";

//#785
import HoldingAccountPageContainer from "../views/Dashboard/HoldingAccount/HoldingAccountPage";
import PastForwardsListContainer from "../views/Dashboard/Forwards/PastForwardListContainer";
import NewDashboardPage from "../views/Pages/NewDashboardPage";
import RateAlerts from "../views/Dashboard/RateAlerts/RateAlerts";
import BillPayments from "../views/Pages/BillPayments";
// import CashFlowPlanner from "../views/Dashboard/CashflowPlanner/CashFlowPlanner";
import ExposureMonitor from "../views/Dashboard/ExposureMonitor/ExposureMonitor";

//setup for language components
// import LanguageSelectionContainer from "../components/CustomDropdown/LanguageSelectionContainer";
// import LanguageSelection from "../components/CustomDropdown/LanguageSelection";

//setup for language components
//let languageObj_en_json = require('../translations/en.json');
let languageObj_zh_json = require("../translations/zh.json");
let languageObj_de_json = require("../translations/de.json");
let languageObj_es_json = require("../translations/es.json");
let languageObj_fr_json = require("../translations/fr.json");
let languageObj_it_json = require("../translations/it.json");
let languageObj_pt_json = require("../translations/pt.json");

var dashRoutes = [
  {
    path: "/registration",
    name: "Registration",
    // name_es: languageObj_es_json["icon.dashboard"],
    // name_de: languageObj_de_json["icon.dashboard"],
    // name_zh: languageObj_zh_json["icon.dashboard"],
    // name_fr: languageObj_fr_json["icon.dashboard"],
    // name_it: languageObj_it_json["icon.dashboard"],
    // name_pt: languageObj_pt_json["icon.dashboard"],
    icon: Accessibility,
    // invisible: true,
    component: Registration
  },
  {
    path: "/pending-registration",
    name: "Pending Verification",
    // name_es: languageObj_es_json["icon.dashboard"],
    // name_de: languageObj_de_json["icon.dashboard"],
    // name_zh: languageObj_zh_json["icon.dashboard"],
    // name_fr: languageObj_fr_json["icon.dashboard"],
    // name_it: languageObj_it_json["icon.dashboard"],
    // name_pt: languageObj_pt_json["icon.dashboard"],
    icon: Accessibility,
    // invisible: true,
    component: PendingRegistration
  },
  {
    path: "/dashboard",
    name: "Dashboard",
    //icon.externalTransfer
    name_es: languageObj_es_json["icon.externalTransfer"],
    name_de: languageObj_de_json["icon.externalTransfer"],
    name_zh: languageObj_zh_json["icon.externalTransfer"],
    name_fr: languageObj_fr_json["icon.externalTransfer"],
    name_it: languageObj_it_json["icon.externalTransfer"],
    name_pt: languageObj_pt_json["icon.externalTransfer"],
    icon: DashboardIcon,
    // component: DashboardPageContainer,
    component: NewDashboardPage,
  },
  {
    path: "/new-dashboard",
    name: "Dashboard",
    //icon.externalTransfer
    name_es: languageObj_es_json["icon.externalTransfer"],
    name_de: languageObj_de_json["icon.externalTransfer"],
    name_zh: languageObj_zh_json["icon.externalTransfer"],
    name_fr: languageObj_fr_json["icon.externalTransfer"],
    name_it: languageObj_it_json["icon.externalTransfer"],
    name_pt: languageObj_pt_json["icon.externalTransfer"],
    icon: DashboardIcon,
    invisible: true,
    component: NewDashboardPage,
  },
  {
    //Old code for #879
    path: "/dashboard/:prefill",
    name: "External Transfer",
    //"icon.externalTransfer":"External Transfer"
    name_es: languageObj_es_json["icon.externalTransfer"],
    name_de: languageObj_de_json["icon.externalTransfer"],
    name_zh: languageObj_zh_json["icon.externalTransfer"],
    name_fr: languageObj_fr_json["icon.externalTransfer"],
    name_it: languageObj_it_json["icon.externalTransfer"],
    name_pt: languageObj_pt_json["icon.externalTransfer"],
    icon: DashboardIcon,
    invisible: true,
    component: ExternalTransferPageContainer
  },
  {
    path: "/shortcut_transfer/:prefill",
    name: "Shortcut Transfer",
    //"icon.externalTransfer":"External Transfer"
    name_es: languageObj_es_json["icon.externalTransfer"],
    name_de: languageObj_de_json["icon.externalTransfer"],
    name_zh: languageObj_zh_json["icon.externalTransfer"],
    name_fr: languageObj_fr_json["icon.externalTransfer"],
    name_it: languageObj_it_json["icon.externalTransfer"],
    name_pt: languageObj_pt_json["icon.externalTransfer"],
    icon: DashboardIcon,
    invisible: true,
    component: ShortcutTransferPageContainer
  },
  {
    path: "/externalTransfer",
    name: "External Transfer",
    //icon.externalTransfer
    name_es: languageObj_es_json["icon.externalTransfer"],
    name_de: languageObj_de_json["icon.externalTransfer"],
    name_zh: languageObj_zh_json["icon.externalTransfer"],
    name_fr: languageObj_fr_json["icon.externalTransfer"],
    name_it: languageObj_it_json["icon.externalTransfer"],
    name_pt: languageObj_pt_json["icon.externalTransfer"],
    icon: DashboardIcon,
    component: ExternalTransferPageContainer
  },
  {
    path: "/dashboard-legacy",
    name: "External Transfer",
    //icon.externalTransfer
    name_es: languageObj_es_json["icon.externalTransfer"],
    name_de: languageObj_de_json["icon.externalTransfer"],
    name_zh: languageObj_zh_json["icon.externalTransfer"],
    name_fr: languageObj_fr_json["icon.externalTransfer"],
    name_it: languageObj_it_json["icon.externalTransfer"],
    name_pt: languageObj_pt_json["icon.externalTransfer"],
    icon: DashboardIcon,
    invisible: true,
    component: DashboardLegacyPageContainer
  },
  {
    path: "/multipay",
    name: "Multi Pay",
    code: "multi_pay",
    //icon.multiPay
    name_es: languageObj_es_json["icon.multiPay"],
    name_de: languageObj_de_json["icon.multiPay"],
    name_zh: languageObj_zh_json["icon.multiPay"],
    name_fr: languageObj_fr_json["icon.multiPay"],
    name_it: languageObj_it_json["icon.multiPay"],
    name_pt: languageObj_pt_json["icon.multiPay"],
    icon: DashboardIcon,
    hide_gostudy: true,
    component: MultipayPage
  },
  {
    path: "/bill-payments",
    name: "Bill Payments",
    code: "bill_payment",
    //icon.multiPay
    name_es: languageObj_es_json["icon.multiPay"],
    name_de: languageObj_de_json["icon.multiPay"],
    name_zh: languageObj_zh_json["icon.multiPay"],
    name_fr: languageObj_fr_json["icon.multiPay"],
    name_it: languageObj_it_json["icon.multiPay"],
    name_pt: languageObj_pt_json["icon.multiPay"],
    icon: DashboardIcon,
    beta: true,
    hide_gostudy: true,
    component: BillPayments
  },
  {
    collapse: true,
    path: "/transfers",
    name: "Transfers",
    name_es: languageObj_es_json["icon.transfers"],
    name_de: languageObj_de_json["icon.transfers"],
    name_zh: languageObj_zh_json["icon.transfers"],
    name_fr: languageObj_fr_json["icon.transfers"],
    name_it: languageObj_it_json["icon.transfers"],
    name_pt: languageObj_pt_json["icon.transfers"],
    state: "openTransferList",
    icon: GridOn,
    show_gostudy: true,
    views: [
      {
        path: "/payment/new",
        name: "New Payment",
        code: "new_payment",
        name_es: languageObj_es_json["icon.transfers.newPayment"],
        name_de: languageObj_de_json["icon.transfers.newPayment"],
        name_zh: languageObj_zh_json["icon.transfers.newPayment"],
        name_fr: languageObj_fr_json["icon.transfers.newPayment"],
        name_it: languageObj_it_json["icon.transfers.newPayment"],
        name_pt: languageObj_pt_json["icon.transfers.newPayment"],
        mini: "NP",
        show_gostudy: true,
        component: TransfersNewContainer
      },
      {
        path: "/transfers/new",
        name: "New Transfer",
        code: "new_transfer",
        name_es: languageObj_es_json["icon.transfers.new"],
        name_de: languageObj_de_json["icon.transfers.new"],
        name_zh: languageObj_zh_json["icon.transfers.new"],
        name_fr: languageObj_fr_json["icon.transfers.new"],
        name_it: languageObj_it_json["icon.transfers.new"],
        name_pt: languageObj_pt_json["icon.transfers.new"],
        mini: "NT",
        hide_gostudy: true,
        component: TransfersNew2Container
      },
      // {
      //   path: "/transfers/payment",
      //   name: "New Payment",
      //   mini: "NP",
      //   component: TransfersNewContainer
      // },
      {
        path: "/transfers/edit/:transfer_id",
        name: "Edit Transfer",
        name_es: languageObj_es_json["icon.transfers.edit"],
        name_de: languageObj_de_json["icon.transfers.edit"],
        name_zh: languageObj_zh_json["icon.transfers.edit"],
        name_fr: languageObj_fr_json["icon.transfers.edit"],
        name_it: languageObj_it_json["icon.transfers.edit"],
        name_pt: languageObj_pt_json["icon.transfers.edit"],
        mini: "NC",
        invisible: true,
        component: TransfersEditPageContainer
      },
      {
        path: "/worldpay/:result/:transfer_id",
        name: "WorldPay Result",
        mini: "NC",
        invisible: true,
        component: WorldPayResponseContainer
      }
    ]
  },
  {
    path: "/search_transfer",
    name: "Search Transfer",
    code: "searchtransfers",
    //icon.TrackTransfer
    name_es: languageObj_es_json["icon.trackTransfer"],
    name_de: languageObj_de_json["icon.trackTransfer"],
    name_zh: languageObj_zh_json["icon.trackTransfer"],
    name_fr: languageObj_fr_json["icon.trackTransfer"],
    name_it: languageObj_it_json["icon.trackTransfer"],
    name_pt: languageObj_pt_json["icon.trackTransfer"],
    icon: SearchIcon,
    component: SearchTransferPage
  },
  // {
  //   path: "/transfers/list",
  //   name: "Past Transfers",
  //   name_es: languageObj_es_json["icon.transfers.list"],
  //   name_de: languageObj_de_json["icon.transfers.list"],
  //   name_zh: languageObj_zh_json["icon.transfers.list"],
  //   name_fr: languageObj_fr_json["icon.transfers.list"],
  //   name_it: languageObj_it_json["icon.transfers.list"],
  //   name_pt: languageObj_pt_json["icon.transfers.list"],
  //   icon: DashboardIcon,
  //   component: TransfersListContainer
  // },

  //Current Transfers
  //icon.CurrentTransfers
  {
    path: "/transfers/current_list",
    name: "Current Transfers",
    name_es: languageObj_es_json["icon.currentTransfers"],
    name_de: languageObj_de_json["icon.currentTransfers"],
    name_zh: languageObj_zh_json["icon.currentTransfers"],
    name_fr: languageObj_fr_json["icon.currentTransfers"],
    name_it: languageObj_it_json["icon.currentTransfers"],
    name_pt: languageObj_pt_json["icon.currentTransfers"],
    icon: DashboardIcon,
    //mini: "CT",
    component: TransfersListCurrentContainer
  },
  //entry for Past Transfer #743
  
  //icon.PastTransfers
  {
    path: "/transfers/past_list",
    name: "Past Transfers",
    name_es: languageObj_es_json["icon.pastTransfers"],
    name_de: languageObj_de_json["icon.pastTransfers"],
    name_zh: languageObj_zh_json["icon.pastTransfers"],
    name_fr: languageObj_fr_json["icon.pastTransfers"],
    name_it: languageObj_it_json["icon.pastTransfers"],
    name_pt: languageObj_pt_json["icon.pastTransfers"],
    icon: DashboardIcon,
    //mini: "CT",
    component: TransfersListPastContainer
  },
  {
    path: "/transfers/receipt/transfer/:transfer_id",
    name: "Transfer Receipt",
    name_es: languageObj_es_json["icon.pastTransfers"],
    name_de: languageObj_de_json["icon.pastTransfers"],
    name_zh: languageObj_zh_json["icon.pastTransfers"],
    name_fr: languageObj_fr_json["icon.pastTransfers"],
    name_it: languageObj_it_json["icon.pastTransfers"],
    name_pt: languageObj_pt_json["icon.pastTransfers"],
    icon: DashboardIcon,
    invisible: true,
    //mini: "CT",
    component: TransferReceiptPageContainer
  },
  {
    path: "/transfers/receipt/payout/:transfer_id",
    name: "Payout Receipt",
    name_es: languageObj_es_json["icon.pastTransfers"],
    name_de: languageObj_de_json["icon.pastTransfers"],
    name_zh: languageObj_zh_json["icon.pastTransfers"],
    name_fr: languageObj_fr_json["icon.pastTransfers"],
    name_it: languageObj_it_json["icon.pastTransfers"],
    name_pt: languageObj_pt_json["icon.pastTransfers"],
    icon: DashboardIcon,
    invisible: true,
    //mini: "CT",
    component: PayoutReceiptPageContainer
  },
  {
    path: "/transfers/remittance/transfer/:transfer_id",
    name: "Remittance Instructions",
    // name_es: languageObj_es_json["icon.pastTransfers"],
    // name_de: languageObj_de_json["icon.pastTransfers"],
    // name_zh: languageObj_zh_json["icon.pastTransfers"],
    // name_fr: languageObj_fr_json["icon.pastTransfers"],
    // name_it: languageObj_it_json["icon.pastTransfers"],
    // name_pt: languageObj_pt_json["icon.pastTransfers"],
    icon: DashboardIcon,
    invisible: true,
    //mini: "CT",
    component: TransfersRemittancePageContainer
  },

  {
    path: "/invoices/list",
    name: "Invoice List",
    code: "invoicelist",
    name_es: languageObj_es_json["icon.pastTransfers"],
    name_de: languageObj_de_json["icon.pastTransfers"],
    name_zh: languageObj_zh_json["icon.pastTransfers"],
    name_fr: languageObj_fr_json["icon.pastTransfers"],
    name_it: languageObj_it_json["icon.pastTransfers"],
    name_pt: languageObj_pt_json["icon.pastTransfers"],
    icon: DashboardIcon,
    //mini: "CT",
    component: InvoicesListContainer
  },
  {
    path: "/payment-requests/list",
    name: "Payment Requests",
    code: "paymentRequestList",
    name_es: languageObj_es_json["icon.pastTransfers"],
    name_de: languageObj_de_json["icon.pastTransfers"],
    name_zh: languageObj_zh_json["icon.pastTransfers"],
    name_fr: languageObj_fr_json["icon.pastTransfers"],
    name_it: languageObj_it_json["icon.pastTransfers"],
    name_pt: languageObj_pt_json["icon.pastTransfers"],
    icon: DashboardIcon,
    //mini: "CT",
    component: PaymentRequestListPage
  },
  {
    path: "/payment-requests/view/:payment_request_id",
    name: "View Invoice",
    name_es: languageObj_es_json["icon.pastTransfers"],
    name_de: languageObj_de_json["icon.pastTransfers"],
    name_zh: languageObj_zh_json["icon.pastTransfers"],
    name_fr: languageObj_fr_json["icon.pastTransfers"],
    name_it: languageObj_it_json["icon.pastTransfers"],
    name_pt: languageObj_pt_json["icon.pastTransfers"],
    icon: DashboardIcon,
    invisible: true,
    //mini: "CT",
    component: PaymentRequestViewPage
  },
  {
    path: "/invoices/view/:transfer_id",
    name: "View Invoice",
    name_es: languageObj_es_json["icon.pastTransfers"],
    name_de: languageObj_de_json["icon.pastTransfers"],
    name_zh: languageObj_zh_json["icon.pastTransfers"],
    name_fr: languageObj_fr_json["icon.pastTransfers"],
    name_it: languageObj_it_json["icon.pastTransfers"],
    name_pt: languageObj_pt_json["icon.pastTransfers"],
    icon: DashboardIcon,
    invisible: true,
    //mini: "CT",
    component: ViewInvoicePage
  },

  // Forwards
  {
    collapse: true,
    path: "/forwards",
    name: "Forwards",
    code: "forwards",
    name_es: languageObj_es_json["icon.forwards"],
    name_de: languageObj_de_json["icon.forwards"],
    name_zh: languageObj_zh_json["icon.forwards"],
    name_fr: languageObj_fr_json["icon.forwards"],
    name_it: languageObj_it_json["icon.forwards"],
    name_pt: languageObj_pt_json["icon.forwards"],
    childRoutes: true,
    basePath: 'forwards',
    state: 'openForwards',
    icon: DashboardIcon,
    views: [
      {
        collapse: true,
        path: "/forwards/list",
        name: "Current Forwards",
        code: "forwards",
        name_es: languageObj_es_json["icon.forwards"],
        name_de: languageObj_de_json["icon.forwards"],
        name_zh: languageObj_zh_json["icon.forwards"],
        name_fr: languageObj_fr_json["icon.forwards"],
        name_it: languageObj_it_json["icon.forwards"],
        name_pt: languageObj_pt_json["icon.forwards"],
        basePath: 'forwards',
        state: 'CURRENT_FORWARD',
        icon: DashboardIcon,
        component: ForwardsListContainer
      },
      {
        collapse: true,
        path: "/forwards/past",
        name: "Past Forwards",
        code: "past_forwards",
        name_es: languageObj_es_json["icon.forwards"],
        name_de: languageObj_de_json["icon.forwards"],
        name_zh: languageObj_zh_json["icon.forwards"],
        name_fr: languageObj_fr_json["icon.forwards"],
        name_it: languageObj_it_json["icon.forwards"],
        name_pt: languageObj_pt_json["icon.forwards"],
        basePath: 'forwards',
        icon: DashboardIcon,
        state: 'PAST_FORWARD',
        component: PastForwardsListContainer
      },
    ]
  },
  {
    path: "/forwards/detail/:id",
    name: "Forwards",
    name_es: languageObj_es_json["icon.forwards"],
    name_de: languageObj_de_json["icon.forwards"],
    name_zh: languageObj_zh_json["icon.forwards"],
    name_fr: languageObj_fr_json["icon.forwards"],
    name_it: languageObj_it_json["icon.forwards"],
    name_pt: languageObj_pt_json["icon.forwards"],
    icon: DashboardIcon,
    invisible: true,
    component: ForwardsDetailContainer
  },
  //#785 Create Holding Account page
  {
    path: "/holding-account/receipt/:holding_account_line_item",
    name: "Holding Account Receipt",
    // name_es: languageObj_es_json["icon.pastTransfers"],
    // name_de: languageObj_de_json["icon.pastTransfers"],
    // name_zh: languageObj_zh_json["icon.pastTransfers"],
    // name_fr: languageObj_fr_json["icon.pastTransfers"],
    // name_it: languageObj_it_json["icon.pastTransfers"],
    // name_pt: languageObj_pt_json["icon.pastTransfers"],
    icon: DashboardIcon,
    invisible: true,
    //mini: "CT",
    component: HoldingAccountReceiptPageContainer
  },
  {
    path: "/holding-account",
    name: "Currency Balances",
    icon: "money",
    component: HoldingAccountPageContainer,
    code: "holding_account",
  },


  {
    collapse: true,
    path: "/currency-tools",
    name: "Currency Tools",
    name_es: languageObj_es_json["icon.currencyTools"],
    name_de: languageObj_de_json["icon.currencyTools"],
    name_zh: languageObj_zh_json["icon.currencyTools"],
    name_fr: languageObj_fr_json["icon.currencyTools"],
    name_it: languageObj_it_json["icon.currencyTools"],
    name_pt: languageObj_pt_json["icon.currencyTools"],
    code: "currencytools",
    icon: "money",
    component: CurrencyTools,
    views: [
      {
        path: "/currency-tools/charts",
        name: "Charts",
        name_es: languageObj_es_json["icon.currencyTools"],
        name_de: languageObj_de_json["icon.currencyTools"],
        name_zh: languageObj_zh_json["icon.currencyTools"],
        name_fr: languageObj_fr_json["icon.currencyTools"],
        name_it: languageObj_it_json["icon.currencyTools"],
        name_pt: languageObj_pt_json["icon.currencyTools"],
        mini: "CT",
        component: CurrencyTools
      },
      {
        path: "/currency-tools/alerts",
        name: "Rate Alerts",
        name_es: languageObj_es_json["icon.currencyTools"],
        name_de: languageObj_de_json["icon.currencyTools"],
        name_zh: languageObj_zh_json["icon.currencyTools"],
        name_fr: languageObj_fr_json["icon.currencyTools"],
        name_it: languageObj_it_json["icon.currencyTools"],
        name_pt: languageObj_pt_json["icon.currencyTools"],
        mini: "RA",
        code: "ratealerts",
        component: RateAlerts
      },
      // {
      //   path: "/currency-tools/em",
      //   name: "Exposure Monitor",
      //   name_es: languageObj_es_json["icon.currencyTools"],
      //   name_de: languageObj_de_json["icon.currencyTools"],
      //   name_zh: languageObj_zh_json["icon.currencyTools"],
      //   name_fr: languageObj_fr_json["icon.currencyTools"],
      //   name_it: languageObj_it_json["icon.currencyTools"],
      //   name_pt: languageObj_pt_json["icon.currencyTools"],
      //   mini: "EM",
      //   beta: true,
      //   code: "exposure-monitor",
      //   component: CashFlowPlanner
      // },
      {
        path: "/currency-tools/exposure-monitor",
        name: "Exposure Monitor",
        name_es: languageObj_es_json["icon.currencyTools"],
        name_de: languageObj_de_json["icon.currencyTools"],
        name_zh: languageObj_zh_json["icon.currencyTools"],
        name_fr: languageObj_fr_json["icon.currencyTools"],
        name_it: languageObj_it_json["icon.currencyTools"],
        name_pt: languageObj_pt_json["icon.currencyTools"],
        mini: "EM",
        beta: true,
        code: "exposure-monitor",
        component: ExposureMonitor
      },
    ]
  },


  {
    collapse: true,
    path: "/beneficiaries",
    name: "Beneficiaries",
    name_es: languageObj_es_json["icon.beneficiaries"],
    name_de: languageObj_de_json["icon.beneficiaries"],
    name_zh: languageObj_zh_json["icon.beneficiaries"],
    name_fr: languageObj_fr_json["icon.beneficiaries"],
    name_it: languageObj_it_json["icon.beneficiaries"],
    name_pt: languageObj_pt_json["icon.beneficiaries"],
    state: "openBeneficiaries",
    icon: "people",
    views: [
      {
        path: "/beneficiaries/create",
        name: "Add New Beneficiary",
        name_es: languageObj_es_json["icon.beneficiaries.new"],
        name_de: languageObj_de_json["icon.beneficiaries.new"],
        name_zh: languageObj_zh_json["icon.beneficiaries.new"],
        name_fr: languageObj_fr_json["icon.beneficiaries.new"],
        name_it: languageObj_it_json["icon.beneficiaries.new"],
        name_pt: languageObj_pt_json["icon.beneficiaries.new"],
        mini: "AB",
        component: BeneficiaryNewContainer
      },
      {
        path: "/beneficiaries/edit/:beneficiary_id",
        name: "View Beneficiary",
        name_es: languageObj_es_json["icon.beneficiaries.edit"],
        name_de: languageObj_de_json["icon.beneficiaries.edit"],
        name_zh: languageObj_zh_json["icon.beneficiaries.edit"],
        name_fr: languageObj_fr_json["icon.beneficiaries.edit"],
        name_it: languageObj_it_json["icon.beneficiaries.edit"],
        name_pt: languageObj_pt_json["icon.beneficiaries.edit"],
        mini: "EC",
        invisible: true,
        component: BeneficiaryEditContainer
      },
      {
        path: "/beneficiaries/view-beneficiaries",
        name: "View Beneficiaries",
        name_es: languageObj_es_json["icon.beneficiaries.list"],
        name_de: languageObj_de_json["icon.beneficiaries.list"],
        name_zh: languageObj_zh_json["icon.beneficiaries.list"],
        name_fr: languageObj_fr_json["icon.beneficiaries.list"],
        name_it: languageObj_it_json["icon.beneficiaries.list"],
        name_pt: languageObj_pt_json["icon.beneficiaries.list"],
        mini: "VB",
        component: BeneficiariesListContainer
      },
      {
        path: '/beneficiaries/upload',
        name: 'Upload Beneficiaries',
        name_es: languageObj_es_json["icon.beneficiaries.upload"],
        name_de: languageObj_de_json["icon.beneficiaries.upload"],
        name_zh: languageObj_zh_json["icon.beneficiaries.upload"],
        name_fr: languageObj_fr_json["icon.beneficiaries.upload"],
        name_it: languageObj_it_json["icon.beneficiaries.upload"],
        name_pt: languageObj_pt_json["icon.beneficiaries.upload"],
        mini: 'UB',
        component: BeneficiariesUploadContainer
      }
    ]
  },
  // {
  //   path: "/reports",
  //   name: "Reports",
  //   icon: Timeline,
  //   component: Reports
  // },
  // {
  //   path: "/news/show/:news_id",
  //   name: "Show News",
  //   mini: "SN",
  //   invisible: true,
  //   component: NewsShowContainer
  // },
  // {
  //   path: "/news",
  //   name: newsString,
  //   icon: Timeline,
  //   component: NewsListContainer
  // },

  {
    collapse: true,
    path: "/identification",
    name: "Identification",
    code: "identification",
    name_es: languageObj_es_json["icon.identification"],
    name_de: languageObj_de_json["icon.identification"],
    name_zh: languageObj_zh_json["icon.identification"],
    name_fr: languageObj_fr_json["icon.identification"],
    name_it: languageObj_it_json["icon.identification"],
    name_pt: languageObj_pt_json["icon.identification"],
    state: "openIdentification",
    icon: PermContactCalendar,
    views: [
      {
        path: "/identification/new",
        name: "Update Identification",
        name_es: languageObj_es_json["icon.identification.new"],
        name_de: languageObj_de_json["icon.identification.new"],
        name_zh: languageObj_zh_json["icon.identification.new"],
        name_fr: languageObj_fr_json["icon.identification.new"],
        name_it: languageObj_it_json["icon.identification.new"],
        name_pt: languageObj_pt_json["icon.identification.new"],
        mini: "NB",
        component: IdentificationNewContainer
      },
      {
        path: "/identification/edit/:identification_id",
        name: "Edit Identification",
        name_es: languageObj_es_json["icon.identification.edit"],
        name_de: languageObj_de_json["icon.identification.edit"],
        name_zh: languageObj_zh_json["icon.identification.edit"],
        name_fr: languageObj_fr_json["icon.identification.edit"],
        name_it: languageObj_it_json["icon.identification.edit"],
        name_pt: languageObj_pt_json["icon.identification.edit"],
        mini: "NC",
        invisible: true,
        component: IdentificationEditContainer
      },
      {
        path: "/identification/list",
        name: "Identification List",
        code: "identification_list",
        name_es: languageObj_es_json["icon.identification.list"],
        name_de: languageObj_de_json["icon.identification.list"],
        name_zh: languageObj_zh_json["icon.identification.list"],
        name_fr: languageObj_fr_json["icon.identification.list"],
        name_it: languageObj_it_json["icon.identification.list"],
        name_pt: languageObj_pt_json["icon.identification.list"],
        mini: "BL",
        component: IdentificationListContainer
      }
    ]
  },

  {
    path: "/user-profile",
    name: "User Profile",
    name_es: languageObj_es_json["icon.userprofile"],
    name_de: languageObj_de_json["icon.userprofile"],
    name_zh: languageObj_zh_json["icon.userprofile"],
    name_fr: languageObj_fr_json["icon.userprofile"],
    name_it: languageObj_it_json["icon.userprofile"],
    name_pt: languageObj_pt_json["icon.userprofile"],
    icon: "person",
    component: UserProfileContainer
  },

  
  {
    path: "/migrate",
    name: "Migrate",
    code: "migratefrombackend",
    icon: "person",
    invisible: true,
    component: MigrateFromBackendPage
  },

  { redirect: true, path: "/", pathTo: "/dashboard", name: "Dashboard" }
];

export default dashRoutes;
