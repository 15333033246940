import {connect} from "react-redux";
import withStyles from "@material-ui/core/styles/withStyles";
import ListTable from "../../../components/ListTable/ListTable";
import {FormattedNumber} from "react-intl";
import React, {useEffect, useMemo, useRef, useState} from "react";
import {useForm} from "antd/es/form/Form";
import {API} from "aws-amplify";
import dayjs from "dayjs";
import {
    Button,
    Card,
    Col,
    DatePicker,
    Descriptions,
    Form,
    Input,
    message,
    Modal,
    Row,
    Segmented,
    Select,
    Table
} from "antd";
import {
    BarsOutlined,
    CloseOutlined,
    DeleteOutlined,
    EditOutlined,
    PlusOutlined,
    ReloadOutlined
} from "@ant-design/icons";
import TypographyCurrency from "../../../components/CurrencySelect/TypographyCurrency";
import ChartistGraph from "react-chartist";
import CurrencySelect from "../../../components/CurrencySelect/CurrencySelect";
import FileUploader from "../../../components/FileUploader/FileUploader";
import Draggable from "react-draggable";
import BeneficiaryModal from "../../../components/Beneficiaries/BeneficiaryModal";
import {CurrencyLocales} from "../../../AppUtil";
import {notification} from "antd/es";

const style = theme => ({
    chartistTooltip: {
        position: 'absolute',
        display: 'none',
        padding: '5px',
        background: 'rgba(0, 0, 0, 0.8)',
        color: 'white',
        borderRadius: '3px',
        pointerEvents: 'none',
        zIndex: '100',
    }, currency: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-between',
        width: 'fit-content',
        '& > .currency-flag': {
            marginRight: '0.5rem', width: 52, height: 26,
        },
    }, currencyLabel: {
        textAlign: 'left', '& > p': {
            marginBottom: -5, fontSize: '0.6rem',
        },
    }
})
const CurrencyElement = ({classes, currency, value}) => (<div className={classes.currency}>
    <div
        className={`currency-flag currency-flag-${currency ? currency.toLowerCase() : ''}`}
    />
    <div className={classes.currencyLabel}>
        <p>{currency}</p>
        <FormattedNumber
            minimumFractionDigits={2}
            maximumFractionDigits={2}
            value={value}
        />
    </div>
</div>);

const Context = React.createContext({
    name: 'Default',
});

const ExposureMonitor = ({app_state, classes, getColumnSearchProps, history}) => {

    const [payoutForm] = useForm();
    const [transferForm] = useForm();
    const [api, contextHolder] = notification.useNotification();

    const uploaderRef = useRef();
    const beneficiaryModalRef = useRef();
    const draggableRef = useRef(null);

    const [modalDrag, setModalDrag] = useState({
        disabled: true, bounds: {left: 0, top: 0, bottom: 0, right: 0}
    });

    const [pieCharts, setPieCharts] = useState({
        currencyAverage: {
            labels: [], series: [], data: []
        }
    });


    const [barCharts, setBarCharts] = useState({
        fxExposure: {
            labels: [], series: [], chartConfig: {
                seriesBarDistance: 15, height: '400px', showPoint: true, axisX: {
                    labelInterpolationFnc: function (value, i, j) {
                        return value;
                    },
                },
            }
        }, currencyPlan: {
            labels: [], series: [], chartConfig: {
                seriesBarDistance: 15, height: '400px', showPoint: true, axisX: {
                    labelInterpolationFnc: function (value, i, j) {
                        return value;
                    },
                },
            }
        }

    });

    const [componentState, setComponentState] = useState({
        transferType: 'SPOT',
        activePayoutId: null,
        isPayoutModalOpen: false,
        isBenModalOpen: false,
        isForwardModalOpen: false,
        isListModalOpen: false,
        isTransferModalOpen: false,
        isPayoutListLoading: false,
        beneficiaryList: [],
        currencyBeneficiaryList: [],
        fxCurrency: 5,
        data: []
    });

    const initPrefs = () => {
        if (!app_state.current_client) return true;
        API.post("commons", "/fetch", {
            body: {
                context: 'beneficiaries', fields: ['*'], condition: {client_id: app_state.current_client.id}
            }
        }).then(res => {
            const data = res.filter(i => i.account_currency === componentState.fxCurrency);
            setComponentState(prev => ({...prev, beneficiaryList: res, currencyBeneficiaryList: data}));
        }).catch(err => {
            console.log("Unable to fetch beneficiaries", err);
        });
    }

    const prepareMappedData = (datum) => {
        let data = [];
        if (datum.payouts && datum.payouts.length > 0) {
            const d = datum.payouts.map(item => ({
                id: item.id,
                bid: item.beneficiary_id,
                identifier: 'cashplan_records',
                type: 'PAYOUTS',
                amount: item.payout,
                currencyID: item.currencyID,
                date: item.scheduled
            }));
            data = [...data, ...d];
        }
        if (datum.transfers && datum.transfers.length > 0) {
            const d = datum.transfers.map(item => ({
                id: item.id,
                identifier: 'transfers',
                bid: item.beneficiary_id,
                type: 'TRANSFERS',
                amount: item.amount_to,
                currencyID: item.currency_to_id,
                currencyFromId: item.currency_from_id,
                date: item.settlement_date,
                rate: item.client_rate
            }));
            data = [...data, ...d];
        }
        if (datum.forwards && datum.forwards.length > 0) {
            const d = datum.forwards.map(item => ({
                id: item.id,
                identifier: 'forwards',
                bid: null,
                type: 'FORWARDS',
                amount: item.amount_to,
                currencyID: item.currency_to_id,
                currencyFromId: item.currency_from_id,
                date: item.settlement_date,
                rate: item.client_rate
            }));
            data = [...data, ...d];
        }

        const fxExposureList = data.filter(i => !!dayjs(i.date).isAfter(dayjs()));
        data = data.filter(item => item.currencyID === componentState.fxCurrency && !!dayjs(item.date).isAfter(dayjs()));
        return {
            data: data,
            fxExposureList: fxExposureList
        };
    }

    const initData = () => {
        if (!app_state.current_client) return true;

        setComponentState(prev => ({...prev, isPayoutListLoading: true }));
        Promise.all([API.post("commons", "/fetch", {
            body: {
                context: 'cashplan_records',
                fields: ['*'],
                condition: {deleted: null, receipt: null, clientID: app_state.current_client.id}
            }
        }), API.post("commons", "/fetch", {
            body: {
                context: 'forwards', fields: ['*'], condition: {
                    deleted: false, client_id: app_state.current_client.id
                }
            }
        }), API.post("commons", "/fetch", {
            body: {
                context: 'transfers', fields: ['*'], condition: {
                    deleted: false, client_id: app_state.current_client.id
                }
            }
        })]).then(([payouts, forwards, transfers]) => prepareMappedData({payouts, forwards, transfers})).then(res => {

            setComponentState(prev => ({
                ...prev,
                data: res.data,
                isPayoutListLoading: false,
                fxExposureList: res.fxExposureList
            }));


            const {eBarLabels = [], eBarSeries = []} = prepareFXExposureChart([...res.fxExposureList]);
            const {barLabels, barSeries} = prepareCurrencyPlanChart(res.data);
            setBarCharts(prev => ({
                ...prev,
                currencyPlan: {
                    ...prev.currencyPlan, labels: barLabels, series: barSeries
                },
                fxExposure: {
                    ...prev.fxExposure,  labels: eBarLabels, series: eBarSeries
                }
            }));

            const {pieLabels, pieSeries, data} = prepareCurrencyAverageChart(res.data);
            setPieCharts(prev => ({
                ...prev, currencyAverage: {
                    ...prev.currencyAverage, series: pieSeries, labels: pieLabels, data: data
                }
            }));
        })
    }

    const prepareFXExposureChart = (data) => {
        const currencyIds = [...new Set(data.map(item => item.currencyID))];
        const labels = currencyIds.map(id => app_state.currency_list.find(i => i.id === id).iso_alpha_3).filter(Boolean);

        const seriesTypes = ['PAYOUTS', 'TRANSFERS', 'FORWARDS'];
        const eBarSeries = seriesTypes.map(type =>
            labels.map(label => {
                const currencyId = app_state.currency_list.find(i => i.iso_alpha_3 === label).id;
                return data
                    .filter(i => i.currencyID === currencyId && i.type === type)
                    .reduce((sum, { amount }) => sum + amount, 0);
            })
        );

        return { eBarLabels: labels, eBarSeries };
    };

    const prepareCurrencyPlanChart = (data) => {
        const groupedData = data.reduce((acc, {type, amount, date}) => {
            const formattedDate = dayjs(date).format('MMM YYYY');
            acc[type] = acc[type] || {};
            acc[type][formattedDate] = (acc[type][formattedDate] || 0) + amount;
            return acc;
        }, {});

        const labels = Array.from({length: 12}, (_, i) => dayjs().add(i, 'month').format('MMM YYYY'));
        const fillMissingMonths = (dataObject = {}) => labels.reduce((acc, month) => ({
            ...acc,
            [month]: dataObject[month] || 0
        }), {});

        const series = Object.keys(groupedData).map(key => [...Object.values(fillMissingMonths(groupedData[key]))]);
        return {
            barLabels: Array.from({length: 12}, (_, i) => dayjs().add(i, 'month').format('MMM YYYY')),
            barSeries: series
        }
    }

    const prepareCurrencyAverageChart = (data) => {

        const payout = data.filter(i => i.type === 'PAYOUTS').reduce((acc, obj) => acc + obj.amount, 0);
        const covered = data.filter(i => i.type !== 'PAYOUTS').reduce((acc, obj) => acc + obj.amount, 0);
        const uncovered = payout - covered;

        const series = [
            {label: 'Uncovered', value: uncovered},
            {label: 'Spot', value: data.filter(i => i.type === 'TRANSFERS').reduce((acc, obj) => acc + obj.amount, 0)},
            {label: 'Forwards', value: data.filter(i => i.type === 'FORWARDS').reduce((acc, obj) => acc + obj.amount, 0)},
        ]

        const total = covered + uncovered;

        const labels = series.map(i => {
            const percentage = ((i.value / total) * 100).toFixed(0);
            return `${i.label} = ${percentage}%`;
        });

        const desc = [...series];
        desc.push({label: 'Total Payout', value: payout});

        const index = series.findIndex(i => i.value === 0);

        return {
            pieSeries: series.filter(i => i.value !== 0),
            pieLabels: labels.filter((val, i) => i !== index),
            data: desc
        }
    }

    const onEditBtnClick = (payoutId) => {
        const loading = message.loading(`Loading id #${payoutId}. Please wait..`, 0);
        API.post("commons", "/fetch", {
            body: {
                context: 'cashplan_records', condition: {id: payoutId}, fields: ['*']
            }
        }).then(res => {
            const [d] = res;
            setComponentState(prev => ({
                ...prev, isPayoutModalOpen: true, activePayoutId: payoutId
            }));
            if (d.attachments == null && d.attachments === "") {
                const bottle = (JSON.parse(d.attachments) || []).map(i => ({
                    name: i, uid: i, status: 'done'
                }));
                uploaderRef.current.setFileList(bottle);
            }
            payoutForm.setFieldsValue({...d, date: dayjs(d.date)});
        }).catch(err => {
            console.log(err);
            message.error("Failed to load. Please try again..");
        }).finally(() => loading());
    }

    const buildColumns = () => {
        let columns = [{
            key: 'id', title: 'ID', dataIndex: 'id'
        }, {
            key: 'date',
            title: 'Scheduled On',
            dataIndex: 'date',
            sorter: (a, b) => new Date(a.date) - new Date(b.date),
            defaultSortOrder: 'descend', ...getColumnSearchProps({
                dataIndex: 'date', filterInputType: 'DATE', render: (text, record) => dayjs(text).format('DD/MM/YYYY')
            })
        }, {
            key: 'bid', title: 'Beneficiary', dataIndex: 'bid', render: (text, record) => {
                const ben = componentState.beneficiaryList.find(i => i.id === text);
                if (ben != null) {
                    return ben.nickname == null ? ben.ben_legal_name : ben.nickname
                }
                return '-'
            }
        }, {
            key: 'amount', title: 'Amount', dataIndex: 'amount', render: (text, record) => {
                const currency = app_state.currency_list.find(i => i.id === record.currencyID);
                if (currency && text !== null) {
                    return <CurrencyElement
                        currency={currency.iso_alpha_3}
                        value={text}
                        classes={classes}
                    />
                } else {
                    return '-'
                }
            }
        }, {
            key: 'type',
            title: '',
            dataIndex: 'type',
            render: (text, record) => {
                if (text === 'PAYOUTS') {
                    return <div style={{display: 'flex', justifyContent: 'start', gap: 5}}>
                        <Button type={'primary'} icon={<EditOutlined/>}
                                onClick={() => onEditBtnClick(record.id)}></Button>
                        <Button type={'primary'} icon={<DeleteOutlined/>} danger={true} onClick={() => {
                            Modal.confirm({
                                title: `Delete ?`,
                                content: `Are you sure, you want to delete this payout record #${record.id} ?`,
                                onOk: () => {
                                    const loading = message.loading("Removing. Please wait..", 0);
                                    API.post("commons", "/update", {
                                        body: {
                                            context: record.identifier,
                                            data: {deleted: true},
                                            condition: {id: record.id}
                                        }
                                    }).then(() => {
                                        initData();
                                        message.success("Removed");
                                    }).catch(err => {
                                        console.log(err);
                                        message.success("Failed");
                                    }).finally(() => loading())
                                }
                            })
                        }}></Button>
                    </div>
                } else {
                    return <></>
                }
            }
        }];

        if (componentState.isTransferModalOpen) {
            const currencyFromCol = {
                key: 'currencyFromId',
                title: 'Currency From',
                dataIndex: 'currencyFromId',
                render: (text, record) => {
                    const currency = app_state.currency_list.find(i => i.id === text);
                    if  (currency) {
                        return <TypographyCurrency iso_alpha_3={currency.iso_alpha_3}>{currency.iso_alpha_3}</TypographyCurrency>
                    }
                    return <></>
                }
            };
            const currencyToCol = {
                key: 'currencyID',
                title: 'Currency To',
                dataIndex: 'currencyID',
                render: (text, record) => {
                    const currency = app_state.currency_list.find(i => i.id === text);
                    if  (currency) {
                        return <TypographyCurrency iso_alpha_3={currency.iso_alpha_3}>{currency.iso_alpha_3}</TypographyCurrency>
                    }
                    return <></>
                }
            };
            columns.splice(3, 0, currencyFromCol, currencyToCol)
        }
        return columns;
    }

    useEffect(() => {
        initPrefs();
        initData();
    }, [app_state.current_client]);

    useEffect(() => {
        initData();
    }, [componentState.fxCurrency]);

    useEffect(() => {
        const chart1 = document.querySelector('.ct-chart-tBar');
        const chart2 = document.querySelector('.ct-chart-mBar');

        [chart1, chart2].forEach((chart, index) => {
            const tooltip = document.createElement('div');
            tooltip.className = `${classes.chartistTooltip}`;
            document.body.appendChild(tooltip);

            chart.addEventListener('mouseover', (event) => {
                if (event.target.classList.contains('ct-bar')) {
                    const value = event.target.getAttribute('ct:value');
                    const meta = event.target.getAttribute('meta');
                    const label = event.target.getAttribute('hoverLabel');

                    let str = '';
                    if  (componentState.data.length > 0) {
                        const currency = componentState.fxCurrency;
                        const payouts = componentState.fxExposureList
                            .filter(i => i.currencyID === currency && i[index] != null && dayjs(i.date).format('MM/YYYY') === meta);
                        payouts.forEach(i => {
                            str += dayjs(i.date).format('DD/MM/YYYY') + ":" + i.amount + `<br />`;
                        })
                    }
                    tooltip.innerHTML = `${label}: ${value}<br />${meta}<br /> ${str}`;
                    tooltip.style.display = 'block';
                    tooltip.style.left = `${event.pageX}px`;
                    tooltip.style.top = `${event.pageY - 40}px`;
                }
            });

            chart.addEventListener('mouseout', () => {
                tooltip.style.display = 'none';
            });

            chart.addEventListener('mousemove', (event) => {
                tooltip.style.left = `${event.pageX}px`;
                tooltip.style.top = `${event.pageY - 40}px`;
            });
        })


        const pie1 = document.querySelector('.ct-pie-chart');
        const pie2 = document.querySelector('.ct-pie-chart-2');

        if (pie1 && pie2) {

            [pie1, pie2].forEach((pie, index) => {

                const legendContainer = document.getElementById(`chart-legend-${index}`);
                legendContainer.innerHTML = "";
                const series = pie.querySelectorAll('.ct-series');
                const labels = pieCharts.currencyAverage.labels;

                series.forEach((serie, index) => {
                    const legendItem = document.createElement('div');
                    legendItem.classList.add('legend-item');

                    legendItem.style.display = 'flex';
                    legendItem.style.alignItems = 'center';
                    legendItem.style.marginBottom = '5px';
                    legendItem.style.marginRight = '10px';

                    const label = document.createElement('span');
                    const labelss = labels[index];
                    label.textContent = labels[index]; // Add corresponding label

                    if (labelss != null) {
                        const img = document.createElement('img');
                        img.setAttribute('src', `https://fwwportal-branding.s3.us-east-1.amazonaws.com/currencies/${labelss.toLowerCase()}.png`);
                        img.setAttribute('width', '15px');
                        img.setAttribute('height', '10px');
                        img.style.marginRight = '5px';
                        legendItem.appendChild(img)
                    }
                    legendItem.appendChild(label);
                    legendContainer.appendChild(legendItem);
                })
            })
        }
    }, [componentState.fxCurrency]);

    const renderTopBlock = () => {
        return <div style={{display: 'flex', justifyContent: 'space-between', alignItems: 'center'}}>
            <Form.Item label={'Currency'} name={'selected_currency'} style={{marginBottom: 0}}>
                <Select allowClear={false}
                        showSearch={true}
                        defaultValue={componentState.fxCurrency}
                        style={{width: '250px'}}
                        optionFilterProp={'alt'}
                        options={app_state.currency_list.filter(i => i.id !== 1).map(i => ({
                            ...i,
                            label: <TypographyCurrency
                                iso_alpha_3={i.iso_alpha_3}>[{i.iso_alpha_3}] {i.full_name}</TypographyCurrency>,
                            value: i.id,
                            alt: `${i.full_name} ${i.iso_alpha_3}`
                        }))}
                        onChange={((val, datum) => setComponentState(prev => ({...prev, fxCurrency: datum['id']})))}
                        placeholder={'-- SELECT --'}></Select>
            </Form.Item>
            <div style={{display: 'flex', alignItems: 'start', gap: 5}}>
                <Button type={'primary'}
                        size={'small'}
                        icon={<PlusOutlined/>}
                        onClick={() => {
                            setComponentState(prev => ({
                                ...prev,
                                isPayoutModalOpen: true,
                                activePayoutId: null,
                                currencyBeneficiaryList: componentState.currencyBeneficiaryList
                            }))
                        }}>
                    Add Future Payout
                </Button>
                <Button type={'primary'}
                        size={'small'}
                        icon={<PlusOutlined/>}
                        onClick={() => {
                            setComponentState(prev => ({
                                ...prev, isForwardModalOpen: true
                            }))
                        }}>
                    Add Coverage
                </Button>
                <div style={{display: "flex", gap: '5px'}}>
                    <Button type={'primary'} size={'small'}
                            onClick={() => {
                                setComponentState(prev => ({
                                    ...prev, isListModalOpen: true
                                }))
                            }}
                            icon={<BarsOutlined/>}>View Payouts</Button>
                    <Button type={'primary'} size={'small'}
                            onClick={() => {
                                setComponentState(prev => ({
                                    ...prev, isTransferModalOpen: true
                                }))
                            }}
                            icon={<BarsOutlined/>}>View Transfers</Button>
                </div>
            </div>
        </div>
    }

    const getSelectedCurrency = () => {
        const currency = app_state.currency_list.find(item => item.id === componentState.fxCurrency);
        if (currency != null) {
            return currency.iso_alpha_3
        } else {
            return '-'
        }
    }

    const listener = (strokeWidth = null) => {
        return {
            draw: (data) => {
                if (data.type === 'bar') {
                    const xAxisLabel = data.axisX.ticks[data.index];
                    data.element.attr({
                        'meta': xAxisLabel,
                        'series': data.seriesIndex,
                        'hoverLabel': data.seriesIndex === 0 ? 'Payouts' : (data.seriesIndex === 1 ? 'Transfers' : 'Forwards')
                    });
                    if (strokeWidth) {
                        data.element.getNode().style.strokeWidth = strokeWidth.toString();
                    }
                    // data.element.style({ strokeWidth: '30px' });
                }

                if (data.type === 'label' && data.axis.units.pos === 'x') {
                    data.element.empty()._node.innerHTML = data.text.length === 3 ? `
                <span class="ct-label ct-horizontal ct-end" style="width: 78px; height: 20px">
                    <img alt='currency' style="margin-right: 5px"
                     src='https://fwwportal-branding.s3.us-east-1.amazonaws.com/currencies/${(data.text || '').toLowerCase()}.png'
                     width="15px" height="10px"/> ${data.text}
                 </span>
                ` : `<span class="ct-label ct-horizontal ct-end" style="width: 78px; height: 20px">${data.text}</span>`;
                }
            }
        }
    }

    const handleSubmit = (payload) => {
        const loading = message.loading("Saving payout record. Please wait..", 0);
        if (!componentState.activePayoutId) {
            payload.recordCreated = dayjs(new Date()).startOf("day");
        }
        payload.clientID = app_state.current_client.id;
        payload.attachments = JSON.stringify(payload.attachments);

        const request = componentState.activePayoutId != null ? API.post("commons", "/update", {
            body: {
                context: 'cashplan_records',
                data: payload,
                condition: {id: componentState.activePayoutId}
            }
        }) : API.post("commons", "/insert", {
            body: {
                context: 'cashplan_records',
                data: payload
            }
        });
        request.then(() => {
            message.success("Payout record saved");
            setComponentState(prev => ({...prev, activePayoutId: null, isPayoutModalOpen: false }));
            initData();
        }).catch(err => {
            console.log(err);
            message.error("Unable to save payout record. Please try again..");
        }).finally(() => loading());
    }


    const onStart = (_event, uiData) => {
        const { clientWidth, clientHeight } = window.document.documentElement;
        const targetRect = draggableRef.current.getBoundingClientRect();
        if (!targetRect) {
            return;
        }
        setModalDrag(prev => ({
            ...prev,
            bounds: {
                left: -targetRect.left + uiData.x,
                right: clientWidth - (targetRect.right - uiData.x),
                top: -targetRect.top + uiData.y,
                bottom: clientHeight - (targetRect.bottom - uiData.y),
            }
        }));
    };

    const handleForwardsSubmit = async (values) => {

        if (componentState.transferType === 'SPOT') {
            const fromCurrency = app_state.currency_list.find(item => values.from_currency === item.id).iso_alpha_3;
            const toCurrency = app_state.currency_list.find(item => values.to_currency === item.id).iso_alpha_3;
            history.push(`/shortcut_transfer/${toCurrency}-${fromCurrency}--${values.amount}-----`);
        } else {
            Modal.confirm({
                title: 'Are you sure ?',
                content: 'Please confirm that you are requesting a forward ?',
                onOk: async () => {
                    await API.post("commons", "/mail", {
                        body: {
                            to: `Portal Notifications <portalNotifications@forexworldwide.com>`,
                            subject: 'Forward Request from client # ' + app_state.current_client.id,
                            header: ['New Forward Quote Request'],
                            data: [
                                {
                                    label: 'Client',
                                    value: `${app_state.current_client.nickname} #${app_state.current_client.id}`
                                },
                                {
                                    label: 'Currency From',
                                    value: values.from_currency ? app_state.currency_list.find(item => item.id === values.from_currency).iso_alpha_3 : '-'
                                },
                                {
                                    label: 'Currency To',
                                    value: values.to_currency ? app_state.currency_list.find(item => item.id === values.to_currency).iso_alpha_3 : '-'
                                },
                                {label: 'Amount', value: values.amount},
                                {label: 'Description', value: values.description},
                                {label: 'Settlement', value: values.payment_due_date},
                            ],
                            footer: []
                        }
                    });
                    transferForm.resetFields();
                    message.success("Forwards request has been sent to the admin.");
                    setComponentState(prev => ({...prev, isForwardModalOpen: false}));
                    api.info({
                        message: `Forward Request Alert !`,
                        description: <Context.Consumer>{() => `Our staff will be in touch to confirm this forward shortly.`}</Context.Consumer>,
                        placement: 'bottomLeft',
                    });
                }
            });
            const loading = message.loading("Creating forwards. Please wait...", 0);
            try {

            } catch (e) {
                console.log(e);
                message.error("Failed to send forwards. Please try again...");
                setComponentState(prev => ({...prev, isForwardModalOpen: false}));
            } finally {
                loading()
            }
        }
    }

    const contextValue = useMemo(() => ({ name: 'Forward Notification' }), []);

    return <Context.Provider value={contextValue}>
        {contextHolder}
        <Card title={renderTopBlock()} style={{ marginBottom: '10px' }}>
            <Row gutter={[16, 16]}>
                <Col xs={24} sm={24} md={24} lg={12} xl={12} xxl={12}>
                    <h3 style={{textAlign: 'center'}}>AUD / {getSelectedCurrency()} Coverage</h3>
                    <h6 style={{textAlign: 'center'}}>[12 month forward forecast coverage calculations]</h6>
                    <div style={{display: 'flex', justifyContent: 'center', gap: 50, alignItems: 'center'}}>
                        <div>
                            <ChartistGraph data={pieCharts.currencyAverage}
                                           style={{width: '200px'}}
                                           className={'ct-pie-chart-2'}
                                           options={{
                                               height: 250, labelInterpolationFnc: function (value) {
                                                   return value;
                                               },
                                           }} type={'Pie'}/>
                            <div id={'chart-legend-1'} style={{display: 'flex', gap: 5}}></div>
                        </div>
                        {<Descriptions column={1}>
                            {pieCharts.currencyAverage.data.map(i => (<Descriptions.Item label={i.label}>
                                {new Intl.NumberFormat(CurrencyLocales[getSelectedCurrency()], {
                                    currency: getSelectedCurrency(),
                                    style: 'currency',
                                    useGrouping: true,
                                    currencyDisplay: 'narrowSymbol',
                                    maximumFractionDigits: 2,
                                    minimumFractionDigits: 2
                                }).format(i.value)}
                            </Descriptions.Item>))}
                        </Descriptions>}
                    </div>
                </Col>
                <Col xs={24} sm={24} md={24} lg={12} xl={12} xxl={12}>
                    <div>
                        <h3 style={{textAlign: 'center'}}>Global FX Exposure</h3>
                        <h6 style={{textAlign: 'center'}}>[12 month forward forecast totals]</h6>
                        <ChartistGraph
                            style={{margin: 20, fontWeight: 600}}
                            data={barCharts.fxExposure}
                            type="Bar"
                            className={'ct-chart-mBar'}
                            listener={listener()}
                            options={barCharts.fxExposure.chartConfig}
                        />
                    </div>
                </Col>
            </Row>
        </Card>
        <Card title={'+12 month Exposure Chart (monthly grouping)'}>
            <>
                <ChartistGraph
                    key={componentState.fxCurrency}
                    style={{margin: 20, fontWeight: 600}}
                    className={'ct-chart-tBar'}
                    data={barCharts.currencyPlan}
                    type="Bar"
                    listener={listener(14)}
                    options={barCharts.currencyPlan.chartConfig}
                />
            </>
        </Card>
        <Modal visible={componentState.isPayoutModalOpen}
               style={{top: 3}}
               title={componentState.activePayoutId ? `Update Payout #${componentState.activePayoutId} Record` : 'Add New Record'}
               okText={
                   componentState.activePayoutId
                       ? `Update Payout`
                       : `Add Payout`
               }
               onCancel={() => {
                   payoutForm.resetFields();
                   uploaderRef.current.clearList();
                   setComponentState(prev => ({
                       ...prev,
                       isPayoutModalOpen: false,
                       activePayoutId: null
                   }));
               }}
               onOk={() => payoutForm.submit()}>
            <Form layout={'vertical'} initialValues={{ currencyID: componentState.fxCurrency, attachments: [] }} name={'payout-form'} form={payoutForm} onFinish={handleSubmit}>
                <Form.Item label={'Payment Due Date'} name={'scheduled'} normalize={(dayjsValue) =>
                    dayjsValue && dayjsValue.startOf("day").format('YYYY-MM-DD')
                }
                           getValueProps={(value) =>
                               value && {value: dayjs(value, 'YYYY-MM-DD').startOf("day")}
                           }>
                    <DatePicker format={'DD/MM/YYYY'}/>
                </Form.Item>
                <span style={{ display: 'block', marginBottom: '10px' }}>
                        <CurrencySelect state={{ currencies: app_state.currency_list.filter(i => i.id !== 1) || [] }}
                                        rightLabel={'Beneficiary gets'}
                                        onCurrencyChange={(val, d) => {
                                            const benes = componentState.beneficiaryList.filter(i => i.account_currency === d);
                                            setComponentState(prev => ({...prev, currencyBeneficiaryList: benes }));
                                        }}
                                        validated
                                        currencyFieldName={'currencyID'}
                                        fieldName={!componentState.isPlanReceipt ? 'payout' : 'receipt'}></CurrencySelect>
                    </span>
                {
                    !componentState.isPlanReceipt && <><Form.Item name={'beneficiary_id'} label={'Beneficiary'}>
                        <Select placeholder={' -- SELECT BENEFICIARY --'}
                                options={componentState.currencyBeneficiaryList.map(i => {
                                    const curr = app_state.currency_list.find(k => k.id === i.account_currency);
                                    return {
                                        alt: i.nickname == null ? i.ben_legal_name : i.nickname,
                                        label: <TypographyCurrency iso_alpha_3={curr.iso_alpha_3}>{i.nickname == null ? i.ben_legal_name : i.nickname}</TypographyCurrency>,
                                        value: i.id
                                    };
                                })}
                                optionFilterProp={'alt'}></Select>
                    </Form.Item>
                        <Button style={{ marginBottom: '10px' }} type={'primary'} size={'small'} onClick={() => setComponentState(prev => ({...prev, isBenModalOpen: true}))}>Add Beneficiary</Button>
                    </>
                }
                <Form.Item name={'description'} label={'Description'}>
                    <Input.TextArea />
                </Form.Item>
                <Form.Item name="attachments" getValueFromEvent={(e) => e.map(i => i.name)}>
                    <FileUploader key={componentState.activePayoutId} uploaderType={'DRAGDROP'} ref={uploaderRef}></FileUploader>
                </Form.Item>
                <Modal visible={componentState.isBenModalOpen}
                       width={'900px'}
                       style={{top: 10}}
                       bodyStyle={{maxHeight: '80vh', overflowY: 'scroll', padding: '10px', background: '#E0e0e0'}}
                       onCancel={() => {
                           beneficiaryModalRef.current.resetFields();
                           setComponentState(prev => ({...prev, isBenModalOpen: false}))
                       }}
                       onOk={() => {
                           beneficiaryModalRef.current.submit();
                       }}
                       modalRender={(modal) => (
                           <Draggable
                               disabled={modalDrag.disabled}
                               bounds={modalDrag.bounds}
                               nodeRef={draggableRef}
                               onStart={(event, uiData) => onStart(event, uiData)}
                           >
                               <div ref={draggableRef}>{modal}</div>
                           </Draggable>
                       )}
                       title={
                           <div style={{ width: '100%', cursor: 'move' }}
                                onMouseOver={() => {
                                    if (modalDrag.disabled) {
                                        setModalDrag(prev => ({...prev, disabled: false}));
                                    }
                                }}
                                onMouseOut={() => {
                                    setModalDrag(prev => ({...prev, disabled: true}));
                                }}>Add New Beneficiary</div>
                       }>
                    <BeneficiaryModal clientId={app_state.current_client ? app_state.current_client.id : null}
                                      initialCurrency={5}
                                      onSubmit={(val) => {
                                          initPrefs();
                                          setComponentState(prev => ({...prev, isBenModalOpen: false}));
                                      }}
                                      ref={beneficiaryModalRef}></BeneficiaryModal>
                </Modal>
            </Form>
        </Modal>
        <Modal name={'Forwards-Modal'}
               title={'Add New Forwards'}
               onCancel={() => {
                   transferForm.resetFields();
                   setComponentState(prev => ({...prev, isForwardModalOpen: false}));
               }}
               visible={componentState.isForwardModalOpen}
               onOk={() => transferForm.submit()}>
            <Form layout={'vertical'} name={'forwards-form'} form={transferForm} onFinish={handleForwardsSubmit}>
                <Segmented defaultValue={componentState.transferType}
                           block={true}
                           style={{ marginBottom: '10px' }}
                           options={[{label: 'Spot', value: 'SPOT'}, {label: 'Forward', value: 'FORWARD'}]}
                           onChange={(val) => setComponentState(prev => ({...prev, transferType: val }))}></Segmented>
                {
                    componentState.transferType === 'FORWARD' && <Form.Item label={'Payment Due Date'} name={'payment_due_date'} normalize={(dayjsValue) =>
                        dayjsValue && dayjsValue.startOf("day").format('YYYY-MM-DD')
                    }
                                                                            getValueProps={(value) =>
                                                                                value && {value: dayjs(value, 'YYYY-MM-DD').startOf("day")}
                                                                            }>
                        <DatePicker format={'DD/MM/YYYY'}/>
                    </Form.Item>
                }
                <Form.Item name={'to_currency'} label={'From Currency'}>
                    <Select optionFilterProp={'alt'} options={(app_state.currency_list || []).map(i => ({
                        ...i,
                        value: i.id,
                        alt: i.full_name + i.iso_alpha_3,
                        label: <TypographyCurrency iso_alpha_3={i.iso_alpha_3}>{i.full_name}</TypographyCurrency>
                    }))}></Select>
                </Form.Item>
                <Form.Item name={'from_currency'} label={'To Currency'}>
                    <Select optionFilterProp={'alt'} options={(app_state.currency_list || []).map(i => ({
                        ...i,
                        value: i.id,
                        alt: i.full_name + i.iso_alpha_3,
                        label: <TypographyCurrency iso_alpha_3={i.iso_alpha_3}>{i.full_name}</TypographyCurrency>
                    }))}></Select>
                </Form.Item>
                <Form.Item name={'amount'} label={'Amount'} required={[
                    {required: true, message: ''},
                    {
                        validator: (_, value) => {
                            if (Number(value) === 0) {
                                return Promise.reject('Enter valid amount');
                            }
                            if (!/^\d+(\.\d{1,2})?$/.test(value)) {
                                return Promise.reject('Please enter a valid number with up to 2 decimal places');
                            }
                            if (value && parseFloat(value) === 0) {
                                return Promise.reject('Amount cannot be zero');
                            }
                            return Promise.resolve();
                        },
                    }
                ]}>
                    <Input />
                </Form.Item>
                {
                    componentState.transferType === 'FORWARD' && <Form.Item name={'description'} label={'Description'}>
                        <Input.TextArea />
                    </Form.Item>
                }
            </Form>
        </Modal>
        <Modal visible={componentState.isListModalOpen}
               title={'Payouts List'}
               width={'700px'}
               onCancel={() => setComponentState(prev => ({...prev, isListModalOpen: false }))}
               cancelButtonProps={{ type: 'primary', icon: <CloseOutlined /> }}
               okButtonProps={{ hidden: true }}
               cancelText={'Close'}>
            <div style={{ display: 'flex', justifyContent: 'end' }}>
                <Button type={'primary'} danger={true} size={'small'} onClick={() => initData()}
                        icon={<ReloadOutlined/>}>Refresh List</Button>
            </div>
            <Table rowKey={'id'}
                   key={app_state.current_client ? app_state.current_client.id : 0}
                   columns={buildColumns()}
                   size="small"
                   summary={(data) => {
                       const total = data.reduce((acc, item) => acc + item.amount, 0);
                       return <Table.Summary.Row>
                           <Table.Summary.Cell index={0}>Total</Table.Summary.Cell>
                           <Table.Summary.Cell index={1}></Table.Summary.Cell>
                           <Table.Summary.Cell index={2}></Table.Summary.Cell>
                           <Table.Summary.Cell index={3}>Total Amt. {new Intl.NumberFormat('en-Au', {
                               minimumFractionDigits: 2,
                               maximumFractionDigits: 2,
                               useGrouping: true
                           }).format(total)}</Table.Summary.Cell>
                       </Table.Summary.Row>
                   }}
                   dataSource={componentState.data.filter(i => i.type === 'PAYOUTS')}
                   loading={componentState.isPayoutListLoading}></Table>
        </Modal>
        <Modal visible={componentState.isTransferModalOpen}
               title={'Forwards and Transfers'}
               width={'1000px'}
               onCancel={() => setComponentState(prev => ({...prev, isTransferModalOpen: false }))}
               cancelButtonProps={{ type: 'primary', icon: <CloseOutlined /> }}
               okButtonProps={{ hidden: true }}
               cancelText={'Close'}>
            <div style={{ display: 'flex', justifyContent: 'end', marginBottom: '10px' }}>
                <Button type={'primary'} danger={true} size={'small'} onClick={() => initData()}
                        icon={<ReloadOutlined/>}>Refresh List</Button>
            </div>
            <Segmented defaultValue={componentState.transferType}
                       block={true}
                       style={{ marginBottom: '10px' }}
                       options={[{label: 'Spot', value: 'SPOT'}, {label: 'Forward', value: 'FORWARD'}]}
                       onChange={(val) => setComponentState(prev => ({...prev, transferType: val }))}></Segmented>
                <Table rowKey={'id'}
                       key={app_state.current_client ? app_state.current_client.id : 0}
                       columns={buildColumns()}
                       size="small"
                       summary={(data) => {
                           const total = data.reduce((acc, item) => acc + item.amount, 0);
                           return <Table.Summary.Row>
                               <Table.Summary.Cell index={0}>Total</Table.Summary.Cell>
                               <Table.Summary.Cell index={1}></Table.Summary.Cell>
                               <Table.Summary.Cell index={2}></Table.Summary.Cell>
                               <Table.Summary.Cell index={3}></Table.Summary.Cell>
                               <Table.Summary.Cell index={4}>Average Forward Rate: .xxxx%</Table.Summary.Cell>
                               <Table.Summary.Cell index={5}>Total Amt. {new Intl.NumberFormat('en-Au', {
                                   minimumFractionDigits: 2,
                                   maximumFractionDigits: 2,
                                   useGrouping: true
                               }).format(total)}</Table.Summary.Cell>
                           </Table.Summary.Row>
                       }}
                       dataSource={componentState.data.filter(i => componentState.transferType === 'SPOT' ? i.type === 'TRANSFERS' : i.type === 'FORWARDS')}
                       loading={componentState.isPayoutListLoading}></Table>
        </Modal>
    </Context.Provider>
}
const mapStateToProps = (state) => ({
    app_state: state.app_state
});

const mapDispatchToProps = (dispatch) => ({});

export default connect(mapStateToProps, mapDispatchToProps)(withStyles(style)(ListTable(ExposureMonitor)));