import React from 'react'
import { connect } from 'react-redux'
import { withRouter } from 'react-router-dom'

// @material-ui/core components
import withStyles from '@material-ui/core/styles/withStyles'
// core components
import GridContainer from 'components/Grid/GridContainer.jsx'
import GridItem from 'components/Grid/GridItem.jsx'
import Card from 'components/Card/Card.jsx'
import CardHeader from 'components/Card/CardHeader.jsx'
import CardBody from 'components/Card/CardBody.jsx'
import CardFooter from 'components/Card/CardFooter.jsx'
import Button from 'components/CustomButtons/Button.jsx'

// import ReactTable from 'react-table'
// import { FormattedNumber } from 'react-intl'
// import Table from 'components/Table/Table.jsx'
import { injectIntl } from 'react-intl'

// import * as jsPDF from 'jspdf'
// import html2canvas from 'html2canvas'

import { API } from 'aws-amplify'
// import { Divider } from '@material-ui/core'

import defaultPdf from 'assets/img/receipts/downloadInvoicePdf.png'
import { Storage } from 'aws-amplify'
import { Descriptions, Table } from 'antd'

const axios = require('axios')
var moment = require('moment')

const forexWorldWideLogo = require('assets/img/pdf_img/forexworldwide_logo_transparent_280x50.42dc12df.png')
const forexSportLogo = require('assets/img/pdf_img/fxsport_signature_gmail.png')
const logo_4qpf = require('assets/img/brand/4QPFa.png')
const logo_skiaspen = require('assets/img/brand/skiaspen.png')
const goStudyLogo = require('assets/img/pdf_img/GOSTUDY_HorizontalUsage_Black_250x50.png')

// const us_flag = require("assets/img/flags/US.png");
// const au_flag = require("assets/img/flags/AU.png");
// const gb_flag = require("assets/img/flags/GB.png");

// var systemNews = [
//   "Alpha Testing: Staff",
//   "Alpha Testing: Clients",
//   "Implementing: Beneficiaries",
//   "Development: Transfers"
// ];

const style = {
    root: {},
    pageHeadings: {
        fontFamily: 'Arial',
        fontWeight: 600,
        fontSize: 22,
    },
    pageSubHeadings: {
        fontFamily: 'Arial',
        fontWeight: 600,
        fontSize: 18,
    },
    tableHeaders: {
        width: 150,
        textAlign: 'left',
        verticalAlign: 'top',
        paddingTop: 0,
        paddingBottom: 0,
        paddingLeft: 8,
    },
    tableCells: {
        textAlign: 'left',
        verticalAlign: 'top',
        paddingTop: 0,
        paddingBottom: 0,
    },
    backgroundBlue: {
        backgroundColor: '#348feb20',
    },
    headerImage: {
        // width: 100,
        // height: 100,
    },
}

// let language = navigator.language.split(/[-_]/)[0];
const TheadComponent = props => null // a component returning null (to hide) or you could write as per your requirement

class PaymentRequestViewPage extends React.Component {
    state = {
        value: 0,
        currencies_list_priority: [],
        map_data: {},
        // beneficiaries_list: [],
        beneficiary_id: '',
        transfer_heading: 'Current Transfers',
        transfer: {},
        currency_from: {},
        currency_to: {
            iso_alpha_3: '',
        },
        payouts_list: [],
    }

    componentDidMount() {
        // console.log(this.props.match.params);
        this.updateTransferData(this.props.match.params.payment_request_id)
        // API.get("currencies", `/currencies/get-list-priority`)
        //   .then(response => {
        //     this.setState({
        //       // currencies_list: response.fullList,
        //       currencies_list_priority: response
        //     });
        //   })
        //   .catch(error => {
        //     console.log(error);
        //   });

        // API.get("beneficiaries", `/beneficiaries/get`)
        //   .then(response => {
        //     this.setState({
        //       beneficiaries_list: response
        //     });
        //   })
        //   .catch(error => {
        //     console.log(error);
        //   });
    }

    componentWillUpdate(nextProps, nextState, nextContext) {
        if (nextProps.prefill !== this.props.prefill) {
            this.updateTransferData()
        }
    }

    updateTransferData(option_value) {
        this.setState({ transfer_id: option_value })

        let purpose_of_payment_str = ''
        // let transfer_list = this.state.transfers_list;
        // for (var j = 0; j < transfer_list.length; j++){
        //   if(transfer_list[j].id == option_value){
        //     this.setState({
        //       // amount_from: transfer_list[j].currency_from_iso_alpha_3 +" " + transfer_list[j].amount_from,
        //       amount_from: transfer_list[j].amount_from,
        //       currency_from: transfer_list[j].currency_from_iso_alpha_3,
        //       // amount_to: transfer_list[j].currency_to_iso_alpha_3 +" " + transfer_list[j].amount_to,
        //       amount_to: transfer_list[j].amount_to,
        //       currency_to: transfer_list[j].currency_to_iso_alpha_3,
        //       client_rate: transfer_list[j].client_rate,
        //       active_status: transfer_list[j].status,
        //       transfer_id_exist:true
        //     });

        //     // this.updateMapData("from_currency", previous_from_currency, previous_to_currency, transfer_list[j].currency_from_id);
        // this.updateMapData("to_currency", previous_from_currency, previous_to_currency, transfer_list[j].currency_to_id);

        API.get('transfers_pending', `/get-full/id/${option_value}`)
            .then(response => {
                console.log(response)
                this.setState({
                    transfer: response.transfer_pending,
                    payouts_list: response.payouts,
                })

                // API.get(
                //     'currencies',
                //     `/currencies/get/${response.fullList.currency_from_id}`
                // )
                //     .then(response => {
                //         // console.log(response);
                //         this.setState({
                //             currency_from: response,
                //         })
                //     })
                //     .catch(error => {
                //         console.log(error)
                //     })

                API.get(
                    'currencies',
                    `/currencies/get/${
                        response.transfer_pending.currency_to_id
                    }`
                )
                    .then(response => {
                        console.log(response)
                        this.setState({
                            currency_to: response,
                        })
                    })
                    .catch(error => {
                        console.log(error)
                    })
            })
            .catch(error => {
                console.log(error)
            })

        // API.get('beneficiaries', `/beneficiaries/get`).then(response => {
        //     // console.log(response);
        //     this.setState({
        //         beneficiaries_list: response,
        //     })
        //     return response
        // })

        API.get('currencies', `/currencies/get-list-priority`)
            .then(response => {
                // console.log(response);
                this.setState({
                    currencies_list_priority: response,
                })
            })
            .catch(error => {
                console.log(error)
            })
    }

    // function that verifies if a string has a given length or not
    verifyLength(value, length) {
        if (value.length >= length) {
            return true
        }
        return false
    }

    // function that verifies if a value is larger than a number or not
    isLargerThanNumber(value, number) {
        if (value !== '' && value >= number) {
            return true
        }
        return false
    }

    change(event, stateName, type, stateNameEqualTo) {
        switch (type) {
            case 'length':
                if (this.verifyLength(event.target.value, stateNameEqualTo)) {
                    this.setState({ [stateName + '_state']: 'success' })
                } else {
                    this.setState({ [stateName + '_state']: 'error' })
                }
                break
            case 'isLargerThanNumber':
                if (
                    this.isLargerThanNumber(
                        event.target.value,
                        stateNameEqualTo
                    )
                ) {
                    this.setState({ [stateName + '_state']: 'success' })
                } else {
                    this.setState({ [stateName + '_state']: 'error' })
                }
                break
            default:
                break
        }
        this.setState({ [stateName]: event.target.value })
    }

    handleChange = (event, value) => {
        this.setState({ value })
    }
    handleChangeIndex = index => {
        this.setState({ value: index })
    }

    // printDocument() {
    //     const input = document.getElementById('divToPrint')
    //     html2canvas(input).then(canvas => {
    //         const imgData = canvas.toDataURL('image/png')
    //         const pdf = new jsPDF('p', 'mm', 'a4')
    //         var width = pdf.internal.pageSize.getWidth()
    //         var height = pdf.internal.pageSize.getHeight()
    //         pdf.addImage(imgData, 'JPEG', 0, 0, width, height)
    //         // pdf.output('dataurlnewwindow');
    //         pdf.save(`payment_request`)
    //     })
    // }

    buildData = () => {
        let return_data = []

        return_data.push({ date: ['DATE', 'sdsaa'] })

        return return_data
    }
    //temp <img src={brandingImg}https://forexworldwide.com/static/media/forexworldwide_logo_transparent_280x50.42dc12df.png" alt="ForexWorldWide Logo"/>

    /*
   * = There are three logos images we can use in here #882 by Tom
    forexWorldWideLogo
    forexSportLogo
    goStudyLogo
  */
    // getSignatureLine = brand => {
    //     switch (brand) {
    //         case '1':
    //             return (
    //                 <React.Fragment>
    //                     <p>
    //                         <br />
    //                         <br />
    //                         <img
    //                             src={forexWorldWideLogo}
    //                             alt="ForexWorldWide Logo"
    //                         />
    //                         <br />
    //                         <strong>Forex WorldWide</strong>
    //                         <br />
    //                         ABN 22 147 363 175
    //                         <br />
    //                         AFSL 401379
    //                         <br />
    //                         Level 6, 100 Collins Street
    //                         <br />
    //                         Melbourne Vic 3000
    //                         <br />
    //                         <br />E admin@forexworldwide.com
    //                         <br />T +61 (03) 9008 1880
    //                         <br />W www.forexworldwide.com
    //                         <br />
    //                     </p>
    //                     <p>
    //                         Forex Sport Pty Ltd, ABN 22 147 363 175, is
    //                         regulated by the Australian Securities and
    //                         Investments Commission (ASIC) in Australia and holds
    //                         an Australian Financial Services Licence (AFSL
    //                         401379)
    //                     </p>
    //                 </React.Fragment>
    //             )
    //             break
    //         case '2':
    //             break
    //         case '4':
    //             return (
    //                 <React.Fragment>
    //                     <p>
    //                         <strong>Forex Student</strong>
    //                         <br />
    //                         Forex Student is a trading name of Forex Sport Pty
    //                         Ltd AFSL 401379.
    //                         <br />
    //                         Forex Student is a provider of money transfer
    //                         services for Go Study Australia Pty Ltd.
    //                         <br />
    //                         <br />
    //                         <table width="40%">
    //                             <tr>
    //                                 <td>
    //                                     <img
    //                                         src={forexWorldWideLogo}
    //                                         alt="ForexWorldWide Logo"
    //                                     />
    //                                 </td>
    //                                 <td>
    //                                     <img
    //                                         src={goStudyLogo}
    //                                         alt="ForexWorldWide Logo"
    //                                     />
    //                                 </td>
    //                             </tr>
    //                             <tr>
    //                                 <td>
    //                                     ABN 22 147 363 175
    //                                     <br />
    //                                     AFSL 401379
    //                                     <br />
    //                                     Level 6, 100 Collins Street
    //                                     <br />
    //                                     Melbourne Vic 3000
    //                                     <br />
    //                                     <br />E admin@forexworldwide.com
    //                                     <br />T +61 (03) 9008 1880
    //                                     <br />W www.forexworldwide.com
    //                                     <br />
    //                                 </td>
    //                                 <td>
    //                                     ABN 000 000 000 <br />
    //                                     <br />
    //                                     000 <br />
    //                                     000
    //                                     <br />
    //                                     <br />E abc@abc.com
    //                                     <br />T +61 (03) 0000 0000
    //                                     <br />F +61 (03) 0000 0000
    //                                     <br />W www.gostudy.com.au
    //                                     <br />
    //                                 </td>
    //                             </tr>
    //                         </table>
    //                         <br />
    //                     </p>
    //                     <p>
    //                         Forex Sport Pty Ltd, ABN 22 147 363 175, is
    //                         regulated by the Australian Securities and
    //                         Investments Commission (ASIC) in Australia and holds
    //                         an Australian Financial Services Licence (AFSL
    //                         401379)
    //                     </p>
    //                 </React.Fragment>
    //             )
    //             break
    //         case '24':
    //             return (
    //                 <React.Fragment>
    //                     <p>
    //                         <br />
    //                         <br />
    //                         <img src={forexSportLogo} alt="ForexSport Logo" />
    //                         <br />
    //                         <br />
    //                         <strong>Forex Sport</strong>
    //                         <br />
    //                         ABN 22 147 363 175
    //                         <br />
    //                         AFSL 401379
    //                         <br />
    //                         Level 6, 100 Collins Street
    //                         <br />
    //                         Melbourne Vic 3000
    //                         <br />
    //                         <br />E admin@forexsport.com
    //                         <br />W www.forexsport.com
    //                         <br />
    //                     </p>
    //                     <p>
    //                         Forex Sport Pty Ltd, ABN 22 147 363 175, is
    //                         regulated by the Australian Securities and
    //                         Investments Commission (ASIC) in Australia and holds
    //                         an Australian Financial Services Licence (AFSL
    //                         401379)
    //                     </p>
    //                 </React.Fragment>
    //             )
    //             break
    //         case '29':
    //             return (
    //                 <React.Fragment>
    //                     <p>
    //                         <br />
    //                         <br />
    //                         <img src={forexSportLogo} alt="ForexSport Logo" />
    //                         <br />
    //                         <strong>Forex Sport</strong>
    //                         <br />
    //                         ABN 22 147 363 175
    //                         <br />
    //                         AFSL 401379
    //                         <br />
    //                         Level 6, 100 Collins Street
    //                         <br />
    //                         Melbourne Vic 3000
    //                         <br />
    //                         <br />E admin@forexsport.com
    //                         <br />T +61 (03) 9008 1880
    //                         <br />W www.forexsport.com
    //                         <br />
    //                     </p>
    //                     <p>
    //                         Forex Sport Pty Ltd, ABN 22 147 363 175, is
    //                         regulated by the Australian Securities and
    //                         Investments Commission (ASIC) in Australia and holds
    //                         an Australian Financial Services Licence (AFSL
    //                         401379)
    //                     </p>
    //                 </React.Fragment>
    //             )
    //             break
    //         case '49':
    //             return (
    //                 <React.Fragment>
    //                     <p>
    //                         <br />
    //                         <br />
    //                         <img src={forexSportLogo} alt="ForexSport Logo" />
    //                         <br />
    //                         <br />
    //                         <strong>Forex Sport</strong>
    //                         <br />
    //                         ABN 22 147 363 175
    //                         <br />
    //                         AFSL 401379
    //                         <br />
    //                         Level 6, 100 Collins Street
    //                         <br />
    //                         Melbourne Vic 3000
    //                         <br />
    //                         <br />E admin@forexsport.com
    //                         <br />W www.forexsport.com
    //                         <br />
    //                     </p>
    //                     <p>
    //                         Forex Sport Pty Ltd, ABN 22 147 363 175, is
    //                         regulated by the Australian Securities and
    //                         Investments Commission (ASIC) in Australia and holds
    //                         an Australian Financial Services Licence (AFSL
    //                         401379)
    //                     </p>
    //                 </React.Fragment>
    //             )
    //             break
    //         case 58:
    //             return (
    //                 <React.Fragment>
    //                     <p>
    //                         <strong>4QPF & Experiences Pty Ltd</strong><br />
    //                         E kirstin@walkwithme.com.au<br />
    //                         W www.walkwithme.com.au<br />
    //                     </p>
    //                     <p>
    //                         <em>Powered by <strong>Forex WorldWide</strong></em><br/>
    //                         <a href="https://forexworldwide.com" target="_blank">www.forexworldwide.com</a>
    //                     </p>
    //                 </React.Fragment>
    //             )
    //             break
    //         default:
    //             return (
    //                 <React.Fragment>
    //                     <p>
    //                         <br />
    //                         <br />
    //                         <img
    //                             src={forexWorldWideLogo}
    //                             alt="ForexWorldWide Logo"
    //                         />
    //                         <br />
    //                         <strong>Forex WorldWide</strong>
    //                         <br />
    //                         ABN 22 147 363 175
    //                         <br />
    //                         AFSL 401379
    //                         <br />
    //                         Level 6, 100 Collins Street
    //                         <br />
    //                         Melbourne Vic 3000
    //                         <br />
    //                         <br />E admin@forexworldwide.com
    //                         <br />T +61 (03) 9008 1880
    //                         <br />W www.forexworldwide.com
    //                         <br />
    //                     </p>
    //                     <p>
    //                         Forex Sport Pty Ltd, ABN 22 147 363 175, is
    //                         regulated by the Australian Securities and
    //                         Investments Commission (ASIC) in Australia and holds
    //                         an Australian Financial Services Licence (AFSL
    //                         401379)
    //                     </p>
    //                 </React.Fragment>
    //             )
    //     }
    // }

    payoutItemTable = () => {
        const { classes } = this.props
        // console.log(id)
        // console.log(this.state)
        // console.log(this.state.payouts_list[id])

        let totalPayment = 0
        for (let i = 0; i < this.state.payouts_list.length; i++) {
            totalPayment += Number.parseFloat(
                this.state.payouts_list[i].amount_to
            )
        }

        let returnValue = []

        if (!this.state.payouts_list.length) {
            returnValue.push(
                <tr>
                    <table style={{ width: '100%' }}>
                        <tbody>
                            <tr className={classes.backgroundBlue}>
                                <th className={classes.tableHeaders}>
                                    Beneficiary
                                </th>
                                <td className={classes.tableCells}>
                                    <React.Fragment>
                                        <strong>
                                            Payout will be made to your currency
                                            balance
                                        </strong>
                                    </React.Fragment>
                                </td>
                            </tr>

                            <tr>
                                <th className={classes.tableHeaders}>
                                    Amount Due
                                </th>
                                <td className={classes.tableCells}>
                                    <React.Fragment>
                                        <strong>
                                            {this.props.intl.formatNumber(
                                                this.state.transfer.amount_to,
                                                {
                                                    minimumFractionDigits: 2,
                                                    maximumFractionDigits: 2,
                                                }
                                            )}
                                            {` `}
                                            <div
                                                className={`currency-flag currency-flag-${this.state.currency_to.iso_alpha_3.toLowerCase()}`}
                                            />
                                            {` `}
                                            {this.state.currency_to.iso_alpha_3.toUpperCase()}
                                        </strong>
                                    </React.Fragment>
                                </td>
                            </tr>
                        </tbody>
                    </table>
                </tr>
            )
        }

        for (let i = 0; i < this.state.payouts_list.length; i++) {
            const payout = this.state.payouts_list[i]
            console.log(payout)
            if (
                i == 0 ||
                (i > 0 &&
                    payout.beneficiary_id !=
                        this.state.payouts_list[0].beneficiary_id)
            ) {
                let beneficiaryNickname = payout.nickname
                if (payout.holdingAccountPayee) {
                    beneficiaryNickname = `Payment made to ${this.state.currency_to.iso_alpha_3.toUpperCase()} Currency Balance`
                }

                returnValue.push(
                    <tr>
                        <table style={{ width: '100%' }}>
                            <tbody style={{ width: '100%' }}>
                                <tr style={{ width: '100%' }}>
                                    <td className={classes.tableCells}>
                                        <table
                                            style={{
                                                width: '100%',
                                            }}
                                        >
                                            <tbody>
                                                <tr>
                                                    <th
                                                        className={
                                                            classes.tableHeaders
                                                        }
                                                    >
                                                        Payable To
                                                    </th>
                                                    <td
                                                        className={
                                                            classes.tableCells
                                                        }
                                                    >
                                                        <React.Fragment>
                                                            <strong>
                                                                {
                                                                    beneficiaryNickname
                                                                }
                                                            </strong>
                                                        </React.Fragment>
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <th
                                                        className={
                                                            classes.tableHeaders
                                                        }
                                                    >
                                                        Address
                                                    </th>
                                                    <td
                                                        className={
                                                            classes.tableCells
                                                        }
                                                    >
                                                        <React.Fragment>
                                                            {
                                                                payout.ben_address_line_1
                                                            }
                                                            &nbsp;
                                                            {
                                                                payout.ben_address_line_2
                                                            }
                                                            &nbsp;
                                                            {
                                                                payout.ben_address_suburb
                                                            }
                                                            &nbsp;
                                                            {
                                                                payout.ben_address_state
                                                            }
                                                            &nbsp;
                                                            {
                                                                payout.ben_address_postcode
                                                            }
                                                            &nbsp;
                                                            {
                                                                payout.beneficiary_country_name
                                                            }
                                                        </React.Fragment>
                                                    </td>
                                                </tr>
                                                {payout.internalAccount && (
                                                    <>
                                                        <tr>
                                                            <th
                                                                className={
                                                                    classes.tableHeaders
                                                                }
                                                            >
                                                                Payment Type
                                                            </th>
                                                            <td
                                                                className={
                                                                    classes.tableCells
                                                                }
                                                            >
                                                                Internal Account
                                                                Payment
                                                            </td>
                                                        </tr>
                                                    </>
                                                )}{' '}
                                                {/* payout.internalAccount &&  */}
                                                {!payout.internalAccount && (
                                                    <>
                                                        <tr>
                                                            <th
                                                                className={
                                                                    classes.tableHeaders
                                                                }
                                                            >
                                                                Bank Name
                                                            </th>
                                                            <td
                                                                className={
                                                                    classes.tableCells
                                                                }
                                                            >
                                                                {payout.bank_legal_name ? (
                                                                    <span>
                                                                        {
                                                                            payout.bank_legal_name
                                                                        }
                                                                        <br />
                                                                    </span>
                                                                ) : (
                                                                    ''
                                                                )}
                                                            </td>
                                                        </tr>

                                                        <tr>
                                                            <th
                                                                className={
                                                                    classes.tableHeaders
                                                                }
                                                            >
                                                                Address
                                                            </th>
                                                            <td
                                                                className={
                                                                    classes.tableCells
                                                                }
                                                            >
                                                                <React.Fragment>
                                                                    {
                                                                        payout.bank_address_line_1
                                                                    }
                                                                    &nbsp;
                                                                    {
                                                                        payout.bank_address_line_2
                                                                    }
                                                                    &nbsp;
                                                                    {
                                                                        payout.bank_address_suburb
                                                                    }
                                                                    &nbsp;
                                                                    {
                                                                        payout.bank_address_state
                                                                    }
                                                                    &nbsp;
                                                                    {
                                                                        payout.bank_address_state
                                                                    }
                                                                    &nbsp;
                                                                    {
                                                                        payout.bank_address_postcode
                                                                    }
                                                                    &nbsp;
                                                                    {
                                                                        payout.beneficiary_country_name
                                                                    }
                                                                </React.Fragment>
                                                            </td>
                                                        </tr>
                                                    </>
                                                )}{' '}
                                                {/* !payout.internalAccount &&  */}
                                                {payout.swift_code && (
                                                    <tr>
                                                        <th
                                                            className={
                                                                classes.tableHeaders
                                                            }
                                                        >
                                                            SWIFT Code
                                                        </th>
                                                        <td
                                                            className={
                                                                classes.tableCells
                                                            }
                                                        >
                                                            {payout.swift_code
                                                                ? payout.swift_code
                                                                : ''}
                                                        </td>
                                                    </tr>
                                                )}
                                                {payout.bsb_code && (
                                                    <tr>
                                                        <th
                                                            className={
                                                                classes.tableHeaders
                                                            }
                                                        >
                                                            BSB
                                                        </th>
                                                        <td
                                                            className={
                                                                classes.tableCells
                                                            }
                                                        >
                                                            {payout.bsb_code
                                                                ? payout.bsb_code
                                                                : ''}
                                                        </td>
                                                    </tr>
                                                )}
                                                {payout.account_number && (
                                                    <tr>
                                                        <th
                                                            className={
                                                                classes.tableHeaders
                                                            }
                                                        >
                                                            Account Number
                                                        </th>
                                                        <td
                                                            className={
                                                                classes.tableCells
                                                            }
                                                        >
                                                            {payout.account_number
                                                                ? payout.account_number
                                                                : ''}
                                                        </td>
                                                    </tr>
                                                )}
                                                {payout.iban && (
                                                    <tr>
                                                        <th
                                                            className={
                                                                classes.tableHeaders
                                                            }
                                                        >
                                                            IBAN
                                                        </th>
                                                        <td
                                                            className={
                                                                classes.tableCells
                                                            }
                                                        >
                                                            {payout.iban ? (
                                                                <span>
                                                                    {
                                                                        payout.iban
                                                                    }
                                                                </span>
                                                            ) : (
                                                                ''
                                                            )}
                                                        </td>
                                                    </tr>
                                                )}
                                                {payout.aba_routing_number && (
                                                    <tr>
                                                        <th
                                                            className={
                                                                classes.tableHeaders
                                                            }
                                                        >
                                                            ABA Routing
                                                        </th>
                                                        <td
                                                            className={
                                                                classes.tableCells
                                                            }
                                                        >
                                                            {payout.aba_routing_number ? (
                                                                <span>
                                                                    {
                                                                        payout.aba_routing_number
                                                                    }
                                                                    <br />
                                                                </span>
                                                            ) : (
                                                                ''
                                                            )}
                                                        </td>
                                                    </tr>
                                                )}
                                                {payout.sort_code && (
                                                    <tr>
                                                        <th
                                                            className={
                                                                classes.tableHeaders
                                                            }
                                                        >
                                                            Sort Code
                                                        </th>
                                                        <td
                                                            className={
                                                                classes.tableCells
                                                            }
                                                        >
                                                            {payout.sort_code ? (
                                                                <span>
                                                                    {
                                                                        payout.sort_code
                                                                    }
                                                                    <br />
                                                                </span>
                                                            ) : (
                                                                ''
                                                            )}
                                                        </td>
                                                    </tr>
                                                )}
                                                {payout.transit_code && (
                                                    <tr>
                                                        <th
                                                            className={
                                                                classes.tableHeaders
                                                            }
                                                        >
                                                            Transit Code
                                                        </th>
                                                        <td
                                                            className={
                                                                classes.tableCells
                                                            }
                                                        >
                                                            {payout.transit_code ? (
                                                                <span>
                                                                    {
                                                                        payout.transit_code
                                                                    }
                                                                    <br />
                                                                </span>
                                                            ) : (
                                                                ''
                                                            )}
                                                        </td>
                                                    </tr>
                                                )}
                                                {payout.bank_code && (
                                                    <tr>
                                                        <th
                                                            className={
                                                                classes.tableHeaders
                                                            }
                                                        >
                                                            Bank Code
                                                        </th>
                                                        <td
                                                            className={
                                                                classes.tableCells
                                                            }
                                                        >
                                                            {payout.bank_code ? (
                                                                <span>
                                                                    {
                                                                        payout.bank_code
                                                                    }
                                                                    <br />
                                                                </span>
                                                            ) : (
                                                                ''
                                                            )}
                                                        </td>
                                                    </tr>
                                                )}
                                            </tbody>
                                        </table>
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                    </tr>
                )
            }
            if (
                this.state.payouts_list.length > 1 &&
                payout.beneficiary_id ==
                    this.state.payouts_list[0].beneficiary_id
            ) {
                // console.log(payout.amount)

                returnValue.push(
                    <tr>
                        <table style={{ width: '100%' }}>
                            <tbody>
                                {/* {
    this.state.payouts_list.map((item) => {
    return <tr> */}
                                <tr style={{ width: '100%' }}>
                                    {/* <td style={{ verticalAlign: "top", paddingRight: 20, paddingLeft: 8, paddingTop: 10, paddingBottom: 10 }}> */}
                                    <td>
                                        <table style={{ width: '100%' }}>
                                            <tbody style={{ width: '100%' }}>
                                                <tr
                                                    style={{
                                                        width: '100%',
                                                    }}
                                                >
                                                    <td
                                                        className={
                                                            classes.tableCells
                                                        }
                                                    >
                                                        <table
                                                            style={{
                                                                width: '100%',
                                                            }}
                                                        >
                                                            <tbody>
                                                                <tr>
                                                                    <th
                                                                        className={
                                                                            classes.tableHeaders
                                                                        }
                                                                    />
                                                                    <td
                                                                        className={
                                                                            classes.tableCells
                                                                        }
                                                                    >
                                                                        <React.Fragment
                                                                        >
                                                                            <em>
                                                                                Payout{' '}
                                                                                {i +
                                                                                    1}

                                                                                :
                                                                            </em>
                                                                            &nbsp;&nbsp;&nbsp;
                                                                            {this.props.intl.formatNumber(
                                                                                payout.amount_to,
                                                                                {
                                                                                    minimumFractionDigits: 2,
                                                                                    maximumFractionDigits: 2,
                                                                                }
                                                                            )}
                                                                            {` `}
                                                                            <div
                                                                                className={`currency-flag currency-flag-${this.state.currency_to.iso_alpha_3.toLowerCase()}`}
                                                                            />
                                                                            {` `}
                                                                            {this.state.currency_to.iso_alpha_3.toUpperCase()}
                                                                        </React.Fragment>
                                                                    </td>
                                                                </tr>
                                                            </tbody>
                                                        </table>
                                                    </td>
                                                </tr>
                                            </tbody>
                                        </table>
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                    </tr>
                )
            }
        }
        return returnValue
    }

    proceedToTransfer = async (
        pendingTransferId,
        currency_iso_alpha_3,
        amount_to,
        tpp_beneficiary_id,
        purpose_of_payment_detail,
        detail_1
    ) => {
        await API.put('transfers_pending', `/update/${pendingTransferId}`, {
            body: {
                status_id: 3,
            },
        })
            .then(apiResponse => {
                console.log(apiResponse)
            })
            .catch(error => {
                console.log(error)
            })
        this.props.history.push(
            `/shortcut_transfer/AUD-${currency_iso_alpha_3}--${amount_to}-${tpp_beneficiary_id}-${purpose_of_payment_detail}--${detail_1}?pt=${pendingTransferId}`
        )
    }

    getLogo = brand => {
        switch (brand) {
            case 58:
                return logo_4qpf
                break
            case 59:
                return logo_skiaspen
                break
            default:
                return forexWorldWideLogo
                break
        }
    }

    downloadInvoice = () => {
        Storage.get(this.state.transfer.attachmentFileId, { expires: 60 }).then(
            item => {
                fetch(item)
                    .then(response => response.blob())
                    .then(blob => {
                        const a = document.createElement('a')
                        const url = window.URL.createObjectURL(blob)
                        a.href = url
                        a.download = this.state.transfer.id + '_REQUEST_INVOICE'
                        a.click()
                        window.URL.revokeObjectURL(url)
                    })
                    .catch(error =>
                        console.error('Error downloading file:', error)
                    )
            }
        )
    }

    buildPayoutList = () => {
        const payoutList = this.state.payouts_list.map(i => ({
            key: i.id,
            date:
                this.state.transfer && this.state.transfer.record_created
                    ? moment(
                          this.state.transfer &&
                              this.state.transfer.record_created
                      ).format('DD/MM/YYYY')
                    : '-',
            description: i.invoice_detail,
            amount: this.props.intl.formatNumber(i.amount_to, {
                minimumFractionDigits: 2,
                maximumFractionDigits: 2,
            }),
        }))
        payoutList.push({
            date: '',
            description: '',
            key: '',
            amount: (
                <strong>
                    {this.props.intl.formatNumber(
                        this.state.transfer.amount_to,
                        {
                            minimumFractionDigits: 2,
                            maximumFractionDigits: 2,
                        }
                    )}
                </strong>
            ),
        })
        return payoutList
    }

    buildPayoutColumns = () => {
        const columns = [
            { key: 1, dataIndex: 'date', title: 'Date' },
            { key: 2, dataIndex: 'description', title: 'Description' },
            { key: 3, dataIndex: 'amount', title: 'Amount' },
        ]
        return columns
    }

    printDocument = () => {
        const requestBody = {
            url: 'https://portal.forexworldwide.com/pages/payment-requests/print/' + this.state.transfer.id,
            selector: '#divToPrint'
        };
        this.setState({toast: true});
        axios.post('https://api.neutronapi.com/pdfservice/api/generate', requestBody, {
            responseType: 'arraybuffer',
            headers: {
                'Accept': 'application/pdf',
                'Access-Control-Allow-Origin': '*'
            }
        }).then(response => {
            const blob = new Blob([response.data], {type: 'application/pdf'});
            const link = document.createElement('a');
            link.href = window.URL.createObjectURL(blob);
            link.download = `transfer_receipt_${this.props.match.params.transfer_id.padStart(5, '0')}`;
            link.click();
            this.setState({toast: false});
        }).catch(err => {
            console.log('error');
        });
    }

    render() {
        const { classes } = this.props

        if (!this.props.app_state.current_client) {
            return null
        }
        // if (this.props.app_state.current_client.account_status == 1) {
        //     this.props.history.push('/pending-registration')
        // }
        // if (this.props.app_state.current_client.account_status == 5) {
        //     this.props.history.push('/registration')
        // }

        if (this.props.app_state.current_client.account_status == 6) {
            this.props.history.push('/migrate')
        }

        console.log(this.state.transfer)
        // let main_component = null
        // main_component = <TrackTransfer prefill={this.props.match.params.prefill}/>

        if (!this.state.transfer)
            return (
                <React.Fragment>
                    <strong>Error: Unable to access transfer</strong>
                </React.Fragment>
            )
        if (!this.state.currency_from) return null
        if (!this.state.currency_to) return null
        if (!this.state.payouts_list) return null
        if (!this.state.payouts_list.length) return null

        // Branding for top of sidebar (based on app_state.current_domain
        // ************************************************************************************************
        // let logo = require(`../../../assets/img/receipts/${
        //     this.props.app_state.current_domain.domain_receipt_brand_image
        // }`)
        let logo = this.getLogo(this.props.app_state.current_team.id)
        // ************************************************************************************************

        console.log(this.state.transfer)
        console.log(this.state.payouts_list)
        // console.log(this.props);

        // let payouts = <span />
        // let str1 = "";
        // for (let i = 0, l = this.state.payouts_list.length; i < l; i++) {
        //   // str1 = str1.concat(`<tr><td width=\"30%\"><strong>${payouts_list[i].nickname}</strong></td><td>${currency_to.iso_alpha_3} ${Number(payouts_list[i].amount_to)}</td><td>${payouts_list[i].description}</td><td>${payouts_list[i].detail_1}</td></tr>`);
        //   str1 = str1.concat(`<tr><td><strong>${this.state.payouts_list[i].nickname}</strong><br/> ${
        //     this.state.payouts_list[i].ben_address_line_1
        //       ? this.state.payouts_list[i].ben_address_line_1
        //       : ""} ${
        //         this.state.payouts_list[i].ben_address_suburb
        //       ? this.state.payouts_list[i].ben_address_suburb
        //       : ""
        //     }<br/><br/></td><td>${this.state.transfer.currency_to_iso_alpha_3} ${Number(this.state.payouts_list[i].amount_to)}</td><td>${this.state.payouts_list[i].detail_1}</td></tr>`);
        // str1 = str1.concat(`<tr><td></td><td>${payouts_list[i].description} : ${payouts_list[i].detail_1}</td></tr>`);

        // str1 = str1.concat(`<tr><td></td><td colspan="3">
        //   ${payouts_list[i].ben_address_line_1 ? "Beneficiary Address: " + payouts_list[i].ben_address_line_1 + " " + payouts_list[i].ben_address_suburb + "<br/>": ""}
        //   ${payouts_list[i].bank_legal_name ? "Beneficiary Bank: " + payouts_list[i].bank_legal_name + "<br/>": ""}
        //   ${payouts_list[i].swift_code ? "SWIFT Code: " + payouts_list[i].swift_code + "<br/>": ""}
        //   ${payouts_list[i].iban ? "IBAN: " + payouts_list[i].iban + "<br/>": ""}
        //   ${payouts_list[i].bsb_code ? "BSB: " + payouts_list[i].bsb_code + "<br/>": ""}
        //   ${payouts_list[i].sort_code ? "Sort Code: " + payouts_list[i].sort_code + "<br/>": ""}
        //   ${payouts_list[i].aba_routing_number ? "ABA Routing: " + payouts_list[i].aba_routing_number + "<br/>": ""}
        //   ${payouts_list[i].transit_code ? "Transit Code: " + payouts_list[i].transit_code + "<br/>": ""}
        //   ${payouts_list[i].bank_code ? "Bank Code: " + payouts_list[i].bank_code + "<br/>": ""}
        //   ${payouts_list[i].account_number ? "Account: " + payouts_list[i].account_number + "<br/>": ""}
        //   </td></tr>`);
        // str1 = str1.concat(`<tr><td colspan="4" style="color:e6e6e6;"><hr/></td></tr>`);
        // }
        // payouts = payouts.concat(str1);

        return (
            <React.Fragment>
                <div>
                    <GridContainer alignItems="stretch">
                        <GridItem xs={12}>
                            <Card style={{ height: '90%' }}>
                                <CardHeader color="info" stats icon />
                                <CardBody>
                                    <p className={classes.cardCategory}>
                                        {/* {branding} */}
                                        {/*<FormattedMessage*/}
                                        {/*  id="dashboard.heading1"*/}
                                        {/*  defaultMessage={*/}
                                        {/*    `Welcome to`}*/}
                                        {/*/>*/}
                                    </p>
                                    <GridContainer justify="center">
                                        <GridItem xs={12} md={10}>
                                            <div style={{ float: 'right' }}>
                                                <Button
                                                    color="primary"
                                                    style={{ float: 'right' }}
                                                    onClick={() =>
                                                        this.proceedToTransfer(
                                                            this.state.transfer
                                                                .id,
                                                            this.state
                                                                .currency_to
                                                                .iso_alpha_3,
                                                            this.state.transfer
                                                                .amount_to,
                                                            this.state
                                                                .payouts_list[0]
                                                                .beneficiary_id,
                                                            this.state
                                                                .payouts_list[0]
                                                                .purpose_of_payment_detail,
                                                            this.state
                                                                .payouts_list[0]
                                                                .detail_1
                                                        )
                                                    }
                                                >
                                                    Pay Now
                                                </Button>
                                                {/* <Button color="primary"
                                                         style={{ float: 'right' }}
                                                         onClick={() => this.printDocument()}>Save PDF</Button> */}
                                            </div>
                                        </GridItem>
                                    </GridContainer>

                                    <div
                                        id="divToPrint"
                                        className="mt4"
                                        style={{
                                            hidden: 'hidden',

                                            width: '210mm',
                                            minHeight: 'auto',
                                            marginLeft: 'auto',
                                            marginRight: 'auto',
                                            padding: '20mm',
                                        }}
                                    >
                                        <div
                                            style={{
                                                display: 'flex',
                                                justifyContent: 'space-between',
                                                alignItems: 'center',
                                            }}
                                        >
                                            <img
                                                src={require(`assets/img/brand/${
                                                    this.props.app_state
                                                        .current_team
                                                        .brand_sidebar_logo
                                                }`)}
                                                alt="logo"
                                                width={'100px'}
                                                className={classes.headerImage}
                                                style={{
                                                    width:
                                                        this.state
                                                            .payouts_list[0]
                                                            .beneficiary_id ===
                                                        5585
                                                            ? '100px'
                                                            : '',
                                                }}
                                            />
                                            {this.state.payouts_list.length >
                                                0 && (
                                                <>
                                                    <span
                                                        style={{
                                                            display: 'flex',
                                                            flexDirection:
                                                                'column',
                                                            alignItems: 'end',
                                                        }}
                                                    >
                                                        <h4>
                                                            <span>
                                                                {
                                                                    this.state
                                                                        .payouts_list[0]
                                                                        .ben_legal_name
                                                                }
                                                            </span>
                                                        </h4>
                                                        <span
                                                            style={{
                                                                display: 'flex',
                                                                flexDirection:
                                                                    'column',
                                                                justifyContent:
                                                                    'end',
                                                                alignItems:
                                                                    'end',
                                                            }}
                                                        >
                                                            <React.Fragment>
                                                                <span>
                                                                    {
                                                                        this
                                                                            .state
                                                                            .payouts_list[0]
                                                                            .ben_address_line_1
                                                                    }
                                                                </span>
                                                                <span>
                                                                    {
                                                                        this
                                                                            .state
                                                                            .payouts_list[0]
                                                                            .ben_address_line_2
                                                                    }
                                                                </span>
                                                                <span>
                                                                    {
                                                                        this
                                                                            .state
                                                                            .payouts_list[0]
                                                                            .ben_address_suburb
                                                                    }{' '}
                                                                    {
                                                                        this
                                                                            .state
                                                                            .payouts_list[0]
                                                                            .ben_address_state
                                                                    }{' '}
                                                                    {
                                                                        this
                                                                            .state
                                                                            .payouts_list[0]
                                                                            .ben_address_postcode
                                                                    }
                                                                </span>
                                                                <span>
                                                                    {
                                                                        this
                                                                            .state
                                                                            .payouts_list[0]
                                                                            .beneficiary_country_name
                                                                    }
                                                                </span>
                                                            </React.Fragment>
                                                        </span>
                                                    </span>
                                                </>
                                            )}
                                        </div>
                                        <hr />
                                        <h3 className={classes.pageHeadings}>
                                            {
                                                this.props.app_state
                                                    .current_team
                                                    .brand_pr_paymentRequestHeading
                                            }
                                        </h3>
                                        <GridContainer>
                                            <GridItem
                                                xs={12}
                                                md={12}
                                                style={{ textAlign: 'right' }}
                                            >
                                                <strong>Due Date: </strong>
                                                {moment(
                                                    this.state.transfer
                                                        .expiryDate
                                                ).format('DD/MM/YYYY')}
                                                <br />
                                                <a
                                                    href="#"
                                                    onClick={e => {
                                                        e.preventDefault()
                                                        this.proceedToTransfer(
                                                            this.state.transfer
                                                                .id,
                                                            this.state
                                                                .currency_to
                                                                .iso_alpha_3,
                                                            this.state.transfer
                                                                .amount_to,
                                                            this.state
                                                                .payouts_list[0]
                                                                .beneficiary_id,
                                                            this.state
                                                                .payouts_list[0]
                                                                .purpose_of_payment_detail,
                                                            this.state
                                                                .payouts_list[0]
                                                                .detail_1
                                                        )
                                                    }}
                                                >
                                                    <strong>
                                                        Click to Pay Now
                                                    </strong>
                                                </a>
                                            </GridItem>
                                        </GridContainer>
                                        <br />

                                        <GridContainer justify="center">
                                            <GridItem xs={12} md={12}>
                                                <h5
                                                    className={
                                                        classes.pageSubHeadings
                                                    }
                                                >
                                                    Invoice To:
                                                </h5>

                                                <p>
                                                    <strong>
                                                        {this.state.transfer
                                                            .client_nickname
                                                            ? this.state
                                                                  .transfer
                                                                  .client_nickname
                                                            : ''}
                                                    </strong>
                                                    <br />

                                                    {this.props.app_state
                                                        .current_client !=
                                                    null ? (
                                                        <>
                                                            <span>
                                                                {
                                                                    this.props
                                                                        .app_state
                                                                        .current_client
                                                                        .residential_street_line_1
                                                                }
                                                            </span>
                                                            <br />
                                                            <span>
                                                                {
                                                                    this.props
                                                                        .app_state
                                                                        .current_client
                                                                        .residential_street_line_2
                                                                }
                                                            </span>
                                                            {this.props
                                                                .app_state
                                                                .current_client
                                                                .residential_street_line_2 && (
                                                                <span>
                                                                    <br />
                                                                </span>
                                                            )}
                                                            <span>
                                                                {
                                                                    this.props
                                                                        .app_state
                                                                        .current_client
                                                                        .residential_street_suburb
                                                                }
                                                            </span>
                                                            &nbsp;
                                                            <span>
                                                                {
                                                                    this.props
                                                                        .app_state
                                                                        .current_client
                                                                        .residential_street_state
                                                                }
                                                            </span>
                                                            &nbsp;
                                                            <span>
                                                                {
                                                                    this.props
                                                                        .app_state
                                                                        .current_client
                                                                        .residential_street_postcode
                                                                }
                                                            </span>
                                                            &nbsp;
                                                        </>
                                                    ) : (
                                                        ''
                                                    )}
                                                </p>

                                                <br />
                                                <h5
                                                    className={
                                                        classes.pageSubHeadings
                                                    }
                                                >
                                                    Invoice Details:
                                                </h5>
                                                <p>
                                                    <strong>Currency:</strong>
                                                    {` `}
                                                    <div
                                                        className={`currency-flag currency-flag-${this.state.currency_to.iso_alpha_3.toLowerCase()}`}
                                                    />
                                                    {` `}
                                                    {this.state.currency_to.iso_alpha_3.toUpperCase()}
                                                </p>
                                                <p>
                                                    <strong>Summary:</strong>
                                                    {` `}
                                                    {
                                                        this.state
                                                            .payouts_list[0]
                                                            .detail_1
                                                    }
                                                </p>

                                                <Table
                                                    dataSource={this.buildPayoutList()}
                                                    size={'small'}
                                                    columns={this.buildPayoutColumns()}
                                                    pagination={false}
                                                    bordered={true}
                                                    style={{
                                                        marginBottom: '5px',
                                                    }}
                                                />
                                                <hr/>
                                                <h5 className={classes.pageSubHeadings}>
                                                    Payment Details
                                                </h5>
                                                <p>
                                                    <a
                                                    href="#"
                                                    onClick={e => {
                                                        e.preventDefault()
                                                        this.proceedToTransfer(
                                                            this.state.transfer
                                                                .id,
                                                            this.state
                                                                .currency_to
                                                                .iso_alpha_3,
                                                            this.state.transfer
                                                                .amount_to,
                                                            this.state
                                                                .payouts_list[0]
                                                                .beneficiary_id,
                                                            this.state
                                                                .payouts_list[0]
                                                                .purpose_of_payment_detail,
                                                            this.state
                                                                .payouts_list[0]
                                                                .detail_1
                                                        )
                                                    }}
                                                >
                                                    <strong>
                                                        Click Here to Pay Now
                                                    </strong>
                                                </a>
                                                </p>
                                                <div
                                                    style={{
                                                        border:
                                                            '1px solid #eee',
                                                    }}
                                                >
                                                    <table
                                                        style={{
                                                            width: '100%',
                                                        }}
                                                    >
                                                        <tbody>
                                                            {this.payoutItemTable()}
                                                        </tbody>
                                                    </table>
                                                </div>
                                            </GridItem>
                                        </GridContainer>
                                    </div>
                                </CardBody>
                                <CardFooter />
                            </Card>
                        </GridItem>
                    </GridContainer>
                </div>
            </React.Fragment>
        )
    }
}

const mapStateToProps = (state, ownProps) => {
    return {
        app_state: state.app_state,
    }
}

// const mapDispatchToProps = (dispatch) => {
// return {
//   onLoginSuccess: (user_id) => {
//     dispatch(onLoginSuccess(user_id));
//   }
// };
// };

const PaymentRequestViewPageContainer = connect(
    mapStateToProps
    // mapDispatchToProps
)(injectIntl(withRouter(withStyles(style)(PaymentRequestViewPage))))

export default PaymentRequestViewPageContainer
