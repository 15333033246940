import React from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
// import { onLoginSuccess } from "../../redux/actions";
// import Link from onLoginSuccess../components/Link'
// import RegisterPage from "./RegisterPage";

// @material-ui/core components
import withStyles from '@material-ui/core/styles/withStyles'
import InputAdornment from '@material-ui/core/InputAdornment'
import Checkbox from '@material-ui/core/Checkbox'
import FormControlLabel from '@material-ui/core/FormControlLabel'
import Icon from '@material-ui/core/Icon'

// @material-ui/icons
import Language from '@material-ui/icons/Language'
import ThumbUp from '@material-ui/icons/ThumbUp'
import VpnLock from '@material-ui/icons/VpnLock'
import Face from '@material-ui/icons/Face'
import Email from '@material-ui/icons/Email'
// import LockOutline from "@material-ui/icons/LockOutline";
import Check from '@material-ui/icons/Check'

// core components
import GridContainer from 'components/Grid/GridContainer.jsx'
import GridItem from 'components/Grid/GridItem.jsx'
import Button from 'components/CustomButtons/Button.jsx'
import CustomInput from 'components/CustomInput/CustomInput.jsx'
import InfoArea from 'components/InfoArea/InfoArea.jsx'
import Card from 'components/Card/Card.jsx'
import CardBody from 'components/Card/CardBody.jsx'

import { API, Auth } from 'aws-amplify'
import queryString from 'query-string'

// import registerPageStyle from "./RegisterPageStyle";

import { FormattedMessage } from 'react-intl'

import {
    container,
    cardTitle,
} from '../../assets/jss/material-dashboard-pro-react.jsx'

import customCheckboxRadioSwitch from '../../assets/jss/material-dashboard-pro-react/customCheckboxRadioSwitch.jsx'
const registerPageStyle = {
    ...customCheckboxRadioSwitch,
    cardTitle: {
        ...cardTitle,
        textAlign: 'center',
    },
    container: {
        ...container,
        position: 'relative',
        zIndex: '3',
        // paddingTop: "23vh"
    },
    cardSignup: {
        borderRadius: '6px',
        boxShadow:
            '0 16px 24px 2px rgba(0, 0, 0, 0.14), 0 6px 30px 5px rgba(0, 0, 0, 0.12), 0 8px 10px -5px rgba(0, 0, 0, 0.2)',
        marginBottom: '100px',
        padding: '40px 0px',
        marginTop: '15vh',
    },
    center: {
        textAlign: 'center',
    },
    right: {
        textAlign: 'right',
    },
    left: {
        textAlign: 'left',
    },
    form: {
        padding: '0 20px',
        position: 'relative',
    },
    socialTitle: {
        fontSize: '18px',
    },
    inputAdornment: {
        marginRight: '18px',
        position: 'relative',
    },
    inputAdornmentIcon: {
        color: '#555',
    },
    customFormControlClasses: {
        margin: '0 12px',
    },
    checkboxLabelControl: {
        margin: '0',
    },
    checkboxLabel: {
        marginLeft: '6px',
        color: 'rgba(0, 0, 0, 0.26)',
    },
}

class RegistrationPage extends React.Component {
    constructor(props) {
        super(props)
        this.state = {
            checked: [],
            social: false,
            first_name: '',
            last_name: '',
            email: '',
            password: '',
            password_state: '',
            confirm_password: '',
            confirm_code: '',
            newUser: null,
            referral_code: this.props.match.params.team
                ? this.props.match.params.team
                : '',
            securities: false,
        }
        this.handleToggle = this.handleToggle.bind(this)
    }

    componentDidMount() {
        // console.log(this.state);
        // console.log(this.props)
        const query_strings = queryString.parse(this.props.location.search)
        // console.log(query_strings);
        if (query_strings) {
            if (query_strings.email) {
                this.setState({
                    social: true,
                    email: query_strings.email,
                })
            }
            if (query_strings.name) {
                this.setState({
                    first_name: query_strings.name.split(' ')[0],
                    last_name: query_strings.name.split(' ')[1],
                })
            }
            if (query_strings.source === 'google') {
                this.setState({
                    source_message: 'using your Google account',
                })
            }
            if (query_strings.source === 'facebook') {
                this.setState({
                    source_message: 'using your FaceBook account',
                })
            }
            if (query_strings.code === 'securities') {
                this.setState({
                    securities: true,
                })
            }
        }
    }

    handleToggle(value) {
        const { checked } = this.state
        const currentIndex = checked.indexOf(value)
        const newChecked = [...checked]

        if (currentIndex === -1) {
            newChecked.push(value)
        } else {
            newChecked.splice(currentIndex, 1)
        }

        this.setState({
            checked: newChecked,
        })
    }

    change(event, stateName, type, stateNameEqualTo) {
        switch (type) {
            case 'password':
                if (this.verifyLength(event.target.value, 1)) {
                    this.setState({ [stateName + '_state']: 'success' })
                } else {
                    this.setState({ [stateName + ' _state']: 'error' })
                }
                break
            case 'equalTo':
                if (
                    this.compare(
                        event.target.value,
                        this.state[stateNameEqualTo]
                    )
                ) {
                    this.setState({ [stateName + '_state']: 'success' })
                } else {
                    this.setState({ [stateName + '_state']: 'error' })
                }
                break
            default:
                break
        }
        this.setState({ [stateName]: event.target.value })
        // this.props.updateNewClientCreation(stateName, event.target.value)
    }

    handleChange = event => {
        this.setState({
            [event.target.id]: event.target.value,
        })
        // console.log(this.state);
    }

    handleSubmit = async event => {
        event.preventDefault()

        this.setState({ isLoading: true })

        try {
            const newUser = await Auth.signUp({
                username: this.state.email.toLowerCase(),
                password: this.state.password,
            })

            this.setState({
                newUser,
            })
            console.log(newUser)
            await this.create_new_user_in_database(
                newUser.userSub,
                newUser.user.username.toLowerCase(),
                this.state.first_name,
                this.state.last_name,
                this.state.referral_code.toLowerCase()
            )
            API.post('email18', `/client/send-signup-email`, {
                body: {
                    data: {
                        email: this.state.email.toLowerCase(),
                        first_name: this.state.first_name,
                        last_name: this.state.last_name,
                        password: this.state.password,
                    },
                },
            })
                .then(response => {
                    console.log(response)
                })
                .catch(error => {
                    console.log(error)
                })

            API.post('email', `/interaction/main`, {
                body: {
                    interaction: 'New Client Registration',
                    data: {
                        username: newUser.user.username,
                        cognito: newUser.userSub,
                    },
                    result: 'AWS Cognito user created - awaiting confirmation',
                },
            })
                .then(response => {
                    console.log(response)
                })
                .catch(error => {
                    console.log(error)
                })
        } catch (e) {
            alert(e.message)
            console.log(e)
            if (e.code === 'UsernameExistsException') {
                const newUser = await Auth.resendSignUp(this.state.email)
                this.setState({
                    newUser,
                })
            }
        }

        this.setState({ isLoading: false })
    }

    async create_new_user_in_database(
        aws_cognito_id,
        email,
        first_name,
        last_name,
        referral_code
    ) {
        API.post('portal', `/users/create`, {
            body: {
                email: email,
                aws_cognito_id: aws_cognito_id,
                first_name: first_name,
                last_name: last_name,
                record_created_datetime: new Date()
                    .toISOString()
                    .slice(0, 19)
                    .replace('T', ' '),
                record_modified_datetime: new Date()
                    .toISOString()
                    .slice(0, 19)
                    .replace('T', ' '),
                portal_account_created: 1,
                portal_email_confirmed: 0,
                language_id: 1,
                referral_code: 'dorman', //hard code dorman
            },
        })
            .then(response => {
                console.log(response)

                return response
            })
            .catch(error => {
                console.log(error)
            })
    }

    async confirmUser(user_id, data) {
        console.log(user_id, data)
        return API.put('portal', `/users/update/${user_id}`, {
            body: data,
        })
    }

    handleConfirmationSubmit = async event => {
        event.preventDefault()

        this.setState({ isLoading: true })

        try {
            await Auth.confirmSignUp(
                this.state.email.toLowerCase(),
                this.state.confirm_code
            )
            const signed_in_user = await Auth.signIn(
                this.state.email.toLowerCase(),
                this.state.password
            )

            // Create database entry here for new user
            // console.log(this.state.email);
            // console.log(this.state);
            // console.log(signed_in_user);

            // const database_result = await this.create_new_user_in_database(signed_in_user.username, this.state.email, this.state.first_name, this.state.last_name);
            // await this.create_new_user_in_database(signed_in_user.username, this.state.email.toLowerCase(), this.state.first_name, this.state.last_name, this.state.referral_code.toLowerCase());
            console.log(signed_in_user)
            const database_result = await this.confirmUser(
                signed_in_user.username,
                {
                    portal_email_confirmed: 1,
                    // portal_last_login_datetime: new Date().toISOString().slice(0, 19).replace('T', ' ')
                }
            )
            console.log(database_result)
            console.log(this.state)

            API.post('email', `/interaction/main`, {
                body: {
                    interaction: 'New Client Cognito Confirmation',
                    data: {
                        ...signed_in_user,
                        ...database_result,
                    },
                    result: 'AWS Cognito user: CONFIRMED',
                },
            })
                .then(response => {
                    console.log(response)
                })
                .catch(error => {
                    console.log(error)
                })

            // API.post('email', `/client/new-client-confirmed`, {
            //     body: {
            //         data: {
            //             email: this.state.email,
            //             first_name: this.state.first_name,
            //             last_name: this.state.last_name,
            //         },
            //     },
            // })
            //     .then(response => {
            //         console.log(response)
            //     })
            //     .catch(error => {
            //         console.log(error)
            //     })

            API.post('email', `/staff/new-client-created`, {
                body: {
                    data: {
                        email: this.state.email,
                        first_name: this.state.first_name,
                        last_name: this.state.last_name,
                    },
                },
            })
                .then(response => {
                    console.log(response)
                })
                .catch(error => {
                    console.log(error)
                })

            // this.props.userHasAuthenticated(true);
            this.props.history.push('/')
            // window.location.assign("http://localhost:3006")
            window.location.reload()
        } catch (e) {
            alert(e.message)
            this.setState({ isLoading: false })
        }
    }

    // function that returns true if value is valid password, false otherwise
    validatePassword(event, value, stateName) {
        // var passwordRex = /^(?=.*[a-z].*[a-z])(?=.*[A-Z].*[A-Z])(?=.*\d.*\d)(?=.*\W.*\W)[a-zA-Z0-9\S]{9,}$/;
        // This pattern requires at least two lowercase letters, two uppercase letters, two digits, and two special characters. There must be a minimum of 9 characters total, and no white space characters are allowed.
        // very strong password

        var passwordRex = /^(?=.*[A-Za-z])(?=.*\d)(?=.*\S)[A-Za-z\d\S]{6,}$/
        // This pattern requires at least 6 characters and contain at least 1 number and 1 character

        this.setState({ [stateName]: event.target.value })
        // console.log(event.target.value);
        if (passwordRex.test(event.target.value)) {
            // console.log("True");
            this.setState({
                password_state: 'success',
                password_helptext: '',
            })
            return true
        }
        // console.log("False");
        this.setState({
            password_state: 'error',
            password_helptext:
                'Must be at least 6 characters and contain at least 1 number',
        })
        return false
    }

    validateForm() {
        // console.log(this.state);
        /*
    if(this.state.password !== this.state.confirm_password){
      this.setState({
        password_state: "error",
        password_helptext: "Must be longer than 8 characters and contain at least 1 uppercase & 1 number & 1 symbol character",
      });
      return false;
    }
*/
        return (
            this.state.email.length > 0 &&
            this.state.password.length > 0 &&
            this.state.password === this.state.confirm_password &&
            this.state.checked[0] === 1
        )
    }

    validateConfirmationForm() {
        return this.state.confirm_code.length > 0
    }

    renderForm() {
        const { classes } = this.props

        //setup for language components
        let languageObj_en_json = require('../../translations/en.json')
        let languageObj_zh_json = require('../../translations/zh.json')
        let languageObj_de_json = require('../../translations/de.json')
        let languageObj_es_json = require('../../translations/es.json')
        let languageObj_fr_json = require('../../translations/fr.json')
        let languageObj_it_json = require('../../translations/it.json')
        let languageObj_pt_json = require('../../translations/pt.json')

        //this.props.language.language_current_ui
        let languageObj = ''
        switch (this.props.language.language_current_ui) {
            case 'es':
                languageObj = languageObj_es_json
                //menu_item_text = prop.name_es || prop.name;
                break
            case 'de':
                languageObj = languageObj_de_json
                //menu_item_text = prop.name_de || prop.name;
                break
            case 'zh':
                languageObj = languageObj_zh_json
                //menu_item_text = prop.name_zh || prop.name;
                break
            case 'fr':
                languageObj = languageObj_fr_json
                //menu_item_text = prop.name_fr || prop.name;
                break
            case 'it':
                languageObj = languageObj_it_json
                //menu_item_text = prop.name_it || prop.name;
                break
            case 'pt':
                languageObj = languageObj_pt_json
                //menu_item_text = prop.name_it || prop.name;
                break
            default:
                languageObj = languageObj_en_json
                //menu_item_text = prop.name;
                break
        }

        return (
            <form className={classes.form} onSubmit={this.handleSubmit}>
                <CustomInput
                    // labelText="First Name..."
                    id="first_name"
                    formControlProps={{
                        fullWidth: true,
                        className: classes.customFormControlClasses,
                    }}
                    inputProps={{
                        value: this.state.first_name,
                        onChange: event => {
                            this.handleChange(event)
                        },
                        startAdornment: (
                            <InputAdornment
                                position="start"
                                className={classes.inputAdornment}
                            >
                                <Face className={classes.inputAdornmentIcon} />
                            </InputAdornment>
                        ),
                        placeholder: languageObj['register.firstname'],
                    }}
                />
                <CustomInput
                    // labelText="Last Name..."
                    id="last_name"
                    formControlProps={{
                        fullWidth: true,
                        className: classes.customFormControlClasses,
                    }}
                    inputProps={{
                        value: this.state.last_name,
                        onChange: event => {
                            this.handleChange(event)
                        },
                        startAdornment: (
                            <InputAdornment
                                position="start"
                                className={classes.inputAdornment}
                            >
                                <Face className={classes.inputAdornmentIcon} />
                            </InputAdornment>
                        ),
                        placeholder: languageObj['register.lastname'],
                    }}
                />
                <CustomInput
                    // labelText="Email..."
                    id="email"
                    formControlProps={{
                        fullWidth: true,
                        className: classes.customFormControlClasses,
                    }}
                    inputProps={{
                        value: this.state.email,
                        onChange: event => {
                            this.handleChange(event)
                        },
                        startAdornment: (
                            <InputAdornment
                                position="start"
                                className={classes.inputAdornment}
                            >
                                <Email className={classes.inputAdornmentIcon} />
                            </InputAdornment>
                        ),
                        type: 'email',
                        placeholder: languageObj['register.email'],
                    }}
                />
                <CustomInput
                    // labelText="Password..."
                    success={this.state.password_state === 'success'}
                    error={this.state.password_state === 'error'}
                    id="password"
                    helpText={this.state.password_helptext}
                    formControlProps={{
                        fullWidth: true,
                        className: classes.customFormControlClasses,
                    }}
                    inputProps={{
                        value: this.state.password,
                        onChange: event =>
                            this.validatePassword(
                                event,
                                'password',
                                'password'
                            ),
                        type: 'password',
                        startAdornment: (
                            <InputAdornment
                                position="start"
                                className={classes.inputAdornment}
                            >
                                <Icon className={classes.inputAdornmentIcon}>
                                    lock_outline
                                </Icon>
                            </InputAdornment>
                        ),
                        placeholder: languageObj['register.password'],
                    }}
                />
                <CustomInput
                    // labelText="Confirm Password..."
                    success={this.state.confirm_password_state === 'success'}
                    error={this.state.confirm_password_state === 'error'}
                    id="confirm_password"
                    formControlProps={{
                        fullWidth: true,
                        className: classes.customFormControlClasses,
                    }}
                    inputProps={{
                        value: this.state.confirm_password,
                        onChange: event => {
                            this.handleChange(event)
                        },
                        type: 'password',
                        startAdornment: (
                            <InputAdornment
                                position="start"
                                className={classes.inputAdornment}
                            >
                                <Icon className={classes.inputAdornmentIcon}>
                                    lock_outline
                                </Icon>
                            </InputAdornment>
                        ),
                        placeholder: languageObj['register.confirmPassword'],
                    }}
                />
                {/*{this.state.social &&*/}
                {/*  <p><em>You will be able to log-in using your social media account, however please provide an account password still, for security purposes.</em></p>*/}
                {/*}*/}
                {/*<CustomInput*/}
                {/*  id="referral_code"*/}
                {/*  formControlProps={{*/}
                {/*    fullWidth: true,*/}
                {/*    className: classes.customFormControlClasses*/}
                {/*  }}*/}
                {/*  inputProps={{*/}
                {/*    value: this.state.referral_code,*/}
                {/*    onChange: event => {*/}
                {/*      this.handleChange(event);*/}
                {/*    },*/}
                {/*    placeholder: "Please enter referrer code if you have one"*/}
                {/*  }}*/}
                {/*/>*/}
                <FormControlLabel
                    classes={{
                        root: classes.checkboxLabelControl,
                        label: classes.checkboxLabel,
                    }}
                    control={
                        <Checkbox
                            tabIndex={-1}
                            value={this.state.agree_terms}
                            onClick={() => this.handleToggle(1)}
                            checkedIcon={
                                <Check className={classes.checkedIcon} />
                            }
                            icon={<Check className={classes.uncheckedIcon} />}
                            classes={{
                                checked: classes.checked,
                                root: classes.checkRoot,
                            }}
                        />
                    }
                    label={
                        <span>
                            <FormattedMessage
                                id="register.agreement"
                                defaultMessage={`I agree to the `}
                            />{' '}
                            <a
                                href="https://www.forexworldwide.com"
                                target="_blank"
                            >
                                {' '}
                                terms and conditions
                                {' '}
                            </a>
                            contained on www.forexworldwide.com.
                        </span>
                    }
                />

                <div className={classes.center}>
                    <Button
                        round
                        color="primary"
                        disabled={!this.validateForm()}
                        type="submit"
                        // onClick={() => this.props.history.push("/pages/login-page")}
                    >
                        <FormattedMessage
                            id="register.getStarted"
                            defaultMessage={`Get Started`}
                        />
                    </Button>
                </div>
            </form>
        )
    }

    renderConfirmationForm() {
        const { classes } = this.props
        return (
            <form
                className={classes.form}
                onSubmit={this.handleConfirmationSubmit}
            >
                <h5>Email validation</h5>
                <p>
                    Please enter the confirmation code which has been emailed to
                    your given address: {this.state.email}.
                </p>
                <CustomInput
                    // labelText="First Name..."
                    id="confirm_code"
                    formControlProps={{
                        fullWidth: true,
                        className: classes.customFormControlClasses,
                    }}
                    inputProps={{
                        value: this.state.confirm_code,
                        onChange: event => {
                            this.handleChange(event)
                        },
                        startAdornment: (
                            <InputAdornment
                                position="start"
                                className={classes.inputAdornment}
                            >
                                <Face className={classes.inputAdornmentIcon} />
                            </InputAdornment>
                        ),
                        placeholder: 'Confirmation Code...',
                    }}
                />
                <div className={classes.center}>
                    <Button
                        round
                        color="primary"
                        disabled={!this.validateConfirmationForm()}
                        type="submit"
                        // onClick={() => this.props.history.push("/pages/login-page")}
                    >
                        Validate Code
                    </Button>
                </div>
            </form>
        )
    }

    render() {
        const { classes } = this.props

        let custom_message = ''
        // console.log(this.props.match.params);
        switch (this.props.match.params.team) {
            case 'gostudy':
                custom_message = 'with GoStudy...'
                break
            case 'gostudyadmin':
                custom_message = 'with GoStudy Admin Team...'
                break
            case 'gostudyaustralia':
                custom_message = 'with GoStudy Australia...'
                break
            case 'gostudyitaly':
                custom_message = 'with GoStudy Italy...'
                break
            case 'gostudyfrance':
                custom_message = 'with GoStudy France...'
                break
            case 'gostudyspain':
                custom_message = 'with GoStudy Spain...'
                break
            case 'redhill':
                custom_message = 'with RedHill Education...'
                break
            case 'abcu':
                custom_message = 'with ABC University...'
                break
            case 'smithsonian':
                custom_message = 'with Smithsonian College...'
                break
            case 'smithsonianadelaide':
                custom_message = 'with Smithsonian College Adelaide...'
                break
            case 'smithsoniansydney':
                custom_message = 'with Smithsonian College Sydney...'
                break
        }
        console.log(this.state)
        return (
            <div className={classes.container}>
                <GridContainer justify="center">
                    <GridItem xs={12} sm={12} md={10}>
                        <Card className={classes.cardSignup}>
                            <h2 className={classes.cardTitle}>
                                <FormattedMessage
                                    id="register.registernow"
                                    defaultMessage={` Register Now`}
                                />
                            </h2>
                            <h5 className={classes.cardTitle}>
                                {custom_message}
                            </h5>
                            {this.state.securities && (
                                <h5 className={classes.cardTitle}>
                                    Securities & Derivatives Portal
                                </h5>
                            )}
                            <h5 className={classes.cardTitle}>
                                {this.state.source_message}
                            </h5>
                            <CardBody>
                                <GridContainer justify="center">
                                    <GridItem xs={12} sm={12} md={5}>
                                        <InfoArea
                                            title="Worldwide Money Transfers"
                                            description=""
                                            icon={Language}
                                            iconColor="info"
                                        />
                                        <InfoArea
                                            title="Smarter, faster, easier"
                                            description=""
                                            icon={ThumbUp}
                                            iconColor="info"
                                        />
                                        <InfoArea
                                            title="Australian owned & ASIC regulated"
                                            description=""
                                            icon={VpnLock}
                                            iconColor="info"
                                        />
                                    </GridItem>
                                    <GridItem xs={12} sm={8} md={5}>
                                        {this.state.newUser === null
                                            ? this.renderForm()
                                            : this.renderConfirmationForm()}
                                    </GridItem>
                                </GridContainer>
                            </CardBody>
                        </Card>
                    </GridItem>
                </GridContainer>
            </div>
        )
    }
}

RegistrationPage.propTypes = {
    classes: PropTypes.object.isRequired,
}

// export default withStyles(registerPageStyle)(RegisterPage);

// import { connect } from "react-redux";
// // import { onLoginSuccess } from "../../redux/actions";
// // import Link from onLoginSuccess../components/Link'
// import RegisterPage from "./RegisterPage";

const mapStateToProps = state => {
    return {
        app_state: state.app_state,
        language: state.language,
    }
}

const mapDispatchToProps = dispatch => {
    return {
        // onLoginSuccess: (user_id) => {
        //   dispatch(onLoginSuccess(user_id));
        // }
    }
}

const RegistrationPageContainer = connect(
    mapStateToProps,
    mapDispatchToProps
)(withStyles(registerPageStyle)(RegistrationPage))

export default RegistrationPageContainer
