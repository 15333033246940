import React from 'react'

import { createBrowserHistory } from 'history'
import { Router, Switch } from 'react-router-dom'

import indexRoutes from 'routes/index.jsx'

import Amplify, { API } from 'aws-amplify'
import config from './config/awsConfig'
import configBeneficiaries from './config/awsConfigBeneficaries'
import configClients from './config/awsConfigClients'
import configTransfers from './config/awsConfigTransfers'
import configForwards from './config/awsConfigForwards'
import configCountries from './config/awsConfigCountries'
import configCurrencies from './config/awsConfigCurrencies'
import configAdmin from './config/awsConfigAdmin'
import configEmail from './config/awsConfigEmail'
import configNews from './config/awsConfigNews'
import configTeams from './config/awsConfigTeams'
import configCurrencyAlerts from './config/awsConfigCurrencyAlerts'
import configIdentification from './config/awsConfigIdentification'
import configentitybanksaccount from './config/awsConfigentitybanksaccount'
import configentitybanks from './config/awsConfigentitybanks'
import configPayments from './config/awsConfigPayments'
import awsConfigForwardDrawDown from './config/awsConfigForwardDrawDown'
import awsConfigClientDocuments from './config/awsConfigClientDocuments'

import AppliedRoute from './components/Routes/AppliedRoute'
import { Auth } from 'aws-amplify'

//core for react-intl
import { IntlProvider, addLocaleData } from 'react-intl'
//import locale data and message data
import messages_zh from './translations/zh.json'
import messages_en from './translations/en.json'
import messages_de from './translations/de.json'
import messages_es from './translations/es.json'
import messages_fr from './translations/fr.json'
import messages_it from './translations/it.json'
import messages_pt from './translations/pt.json'

//import locale data and message data
import en from 'react-intl/locale-data/en'
import es from 'react-intl/locale-data/es'
import de from 'react-intl/locale-data/de'
import zh from 'react-intl/locale-data/zh'
import fr from 'react-intl/locale-data/fr'
import it from 'react-intl/locale-data/it'
import pt from 'react-intl/locale-data/pt'
import { IoT1ClickDevicesService } from 'aws-sdk/clients/all'

import dayjs from 'dayjs'
import advancedFormat from 'dayjs/plugin/advancedFormat'
import customParseFormat from 'dayjs/plugin/customParseFormat'
import localeData from 'dayjs/plugin/localeData'
import weekday from 'dayjs/plugin/weekday'
import weekOfYear from 'dayjs/plugin/weekOfYear'
import weekYear from 'dayjs/plugin/weekYear'

addLocaleData([...en, ...es, ...de, ...zh, ...fr, ...it, ...pt])



dayjs.extend(customParseFormat)
dayjs.extend(advancedFormat)
dayjs.extend(weekday)
dayjs.extend(localeData)
dayjs.extend(weekOfYear)
dayjs.extend(weekYear)

const messages = {
    zh: messages_zh,
    en: messages_en,
    de: messages_de,
    es: messages_es,
    fr: messages_fr,
    it: messages_it,
    pt: messages_pt,
}

Amplify.configure({
    Auth: {
        mandatorySignIn: true,
        region: config.cognito.REGION,
        userPoolId: config.cognito.USER_POOL_ID,
        identityPoolId: config.cognito.IDENTITY_POOL_ID,
        userPoolWebClientId: config.cognito.APP_CLIENT_ID,
    },
    Storage: {
        region: config.s3.REGION,
        bucket: config.s3.BUCKET,
        identityPoolId: config.cognito.IDENTITY_POOL_ID,
    },
    // Storage: {
    //   region: config.s3.REGION,
    //   bucket: config.s3.BUCKET,
    //   identityPoolId: config.cognito.IDENTITY_POOL_ID
    // },
    API: {
        endpoints: [
            {
                name: 'portal',
                endpoint: config.apiGateway.URL,
                // endpoint: "https://vva088v4o3.execute-api.ap-southeast-2.amazonaws.com/dev",
                region: config.apiGateway.REGION,
            },
            {
                name: 'usersNew',
                endpoint:
                    'https://qwzsjfgami.execute-api.ap-southeast-2.amazonaws.com/dev',
                region: config.apiGateway.REGION,
            },
            {
                name: 'beneficiaries',
                endpoint: configBeneficiaries.apiGateway.URL,
                region: configBeneficiaries.apiGateway.REGION,
            },
            {
                name: 'clients',
                endpoint: configClients.apiGateway.URL,
                region: configClients.apiGateway.REGION,
            },
            {
                name: 'transfers',
                endpoint: configTransfers.apiGateway.URL,
                region: configTransfers.apiGateway.REGION,
            },
            {
                name: 'forwards',
                endpoint: configForwards.apiGateway.URL,
                region: configForwards.apiGateway.REGION,
            },
            {
                name: 'forwardsDrawDown',
                endpoint: awsConfigForwardDrawDown.apiGateway.URL,
                region: awsConfigForwardDrawDown.apiGateway.REGION,
            },
            {
                name: 'countries',
                endpoint: configCountries.apiGateway.URL,
                region: configCountries.apiGateway.REGION,
            },
            {
                name: 'currencies',
                endpoint: configCurrencies.apiGateway.URL,
                region: configCurrencies.apiGateway.REGION,
            },
            {
                name: 'admin',
                endpoint: configAdmin.apiGateway.URL,
                region: configAdmin.apiGateway.REGION,
            },
            {
                name: 'email',
                endpoint: configEmail.apiGateway.URL,
                region: configEmail.apiGateway.REGION,
            },
            {
                name: 'news',
                endpoint: configNews.apiGateway.URL,
                region: configNews.apiGateway.REGION,
            },

            {
                name: 'currency-alerts',
                endpoint: configCurrencyAlerts.apiGateway.URL,
                region: configCurrencyAlerts.apiGateway.REGION,
            },
            {
                name: 'teams',
                endpoint: configTeams.apiGateway.URL,
                region: configTeams.apiGateway.REGION,
            },
            {
                name: 'identification',
                endpoint: 'https://1qv4zr7a8g.execute-api.ap-southeast-2.amazonaws.com/dev',
                region: configIdentification.apiGateway.REGION,
            },
            {
                name: 'entitybanks',
                endpoint: configentitybanks.apiGateway.URL,
                region: configentitybanks.apiGateway.REGION,
            },
            {
                name: 'entitybanksaccount',
                endpoint: configentitybanksaccount.apiGateway.URL,
                region: configentitybanksaccount.apiGateway.REGION,
            },
            {
                name: 'payments',
                endpoint: configPayments.apiGateway.URL,
                region: configPayments.apiGateway.REGION,
            },
            {
                name: 'worldpay',
                endpoint: 'https://portal-api.forexworldwide.com/worldpay',
                region: config.apiGateway.REGION,
            },
            {
                name: 'holding_accounts',
                endpoint:
                    'https://portal-api.forexworldwide.com/holdingaccounts',
                region: config.apiGateway.REGION,
            },
            {
                name: 'groups',
                endpoint: 'https://portal-api.forexworldwide.com/groups',
                region: config.apiGateway.REGION,
            },
            {
                name: 'divisions',
                endpoint: 'https://portal-api.forexworldwide.com/divisions',
                region: config.apiGateway.REGION,
            },
            {
                name: 'transfers_pending',
                endpoint:
                    'https://portal-api.forexworldwide.com/transfers-pending',
                region: config.apiGateway.REGION,
            },
            {
                name: 'client-documents',
                endpoint: 'https://vlpa2gia99.execute-api.ap-southeast-2.amazonaws.com/dev/documents/client',
                region: config.apiGateway.REGION,
            },
            {
                name: 'commons',
                endpoint: 'https://forqy11769.execute-api.ap-southeast-2.amazonaws.com/dev',
                region: config.apiGateway.REGION,
            },
            {
                name: 'rate-alerts',
                endpoint: 'https://lhe4od2dh6.execute-api.ap-southeast-2.amazonaws.com/dev',
                region: config.apiGateway.REGION,
            },
            {
                name: 'billPayments',
                endpoint: 'https://0wyg5riq5m.execute-api.ap-southeast-2.amazonaws.com/dev',
                region: config.apiGateway.REGION,
            },
            {
                name: 'email18',
                endpoint: 'https://4x8dv8qru7.execute-api.ap-southeast-2.amazonaws.com/dev',
                region: config.apiGateway.REGION,
            },
        ],
    },
})

const hist = createBrowserHistory()

class App extends React.Component {
    constructor(props) {
        super(props)
        this.state = {
            isAuthenticated: false,
            isAuthenticating: false,
            current_user: null,
        }
    }

    async componentDidMount() {
        this.loadFacebookSDK()

        try {
            // await Auth.currentSession();
            await Auth.currentAuthenticatedUser()
            // console.log("aaa")
            this.userHasAuthenticated(true)
        } catch (e) {
            // console.log(e);
            if (e !== 'No current user') {
                // alert(e);
                console.log(e)
            }
        }

        this.setState({ isAuthenticating: false })
    }

    loadFacebookSDK() {
        window.fbAsyncInit = function() {
            window.FB.init({
                appId: config.social.FB,
                autoLogAppEvents: true,
                xfbml: true,
                version: 'v3.1',
            })
        }

        ;(function(d, s, id) {
            var js,
                fjs = d.getElementsByTagName(s)[0]
            if (d.getElementById(id)) {
                return
            }
            js = d.createElement(s)
            js.id = id
            js.src = 'https://connect.facebook.net/en_US/sdk.js'
            fjs.parentNode.insertBefore(js, fjs)
        })(document, 'script', 'facebook-jssdk')
    }
    userHasAuthenticated = authenticated => {
        // console.log("user has authenticated", authenticated)
        if (authenticated) {
            Auth.currentAuthenticatedUser({
                bypassCache: false,
            })
                .then(user => {
                    console.log(user);
                    if (user.username) {
                        this.populateData(user.username)
                        // eslint-disable-next-line react/prop-types
                        this.props.onLoginSuccess(user.username)
                    } else if (user.email) {
                        this.populateDataByEmail(user.email)
                    }
                })
                .catch(err => {
                    console.err(err)
                })
        }

        this.setState({
            isAuthenticated: authenticated,
        })
    }

    async populateData(aws_cognito_id) {
        try {
            const user = await this.getUserProfile(aws_cognito_id)
            // console.log(user);

            this.setState({
                current_user: user,
            })
        } catch (e) {
            console.error(e)
        }
    }
    async populateDataByEmail(email) {
        try {
            const user = await this.getUserProfileByEmail(email)
            // console.log(user);
            // eslint-disable-next-line react/prop-types
            this.props.onLoginSuccess(user.aws_cognito_id)

            this.setState({
                current_user: user,
            })
        } catch (e) {
            console.error(e)
        }
    }

    async getUserProfile(aws_cognito_id) {
        // return API.get('portal', `/users/get-by-id/${aws_cognito_id}`)
        return API.get('usersNew', `/users-new/get-by-id/${aws_cognito_id}`)
    }

    async getUserProfileByEmail(email) {
        // API.get("portal", `/users/get-by-email/${email}`)
        //   .then(response => {
        //     console.log(response);
        //     return response;
        //   })
        //   .catch(error => {
        //     console.log(error);
        //     API.get("portal", `/users/get-by-email/${email}`)
        //       .then(response => {
        //         console.log(response);
        //         return response;
        //       })
        //       .catch(error => {
        //         console.log(error);
        //       });
        //   });

        return API.get('portal', `/users/get-by-email/${email}`)
    }

    repopulateData = async aws_cognito_id => {
        try {
            // const user = await API.get(
            //     'portal',
            //     `/users/get-by-id/${aws_cognito_id}`
            // )
            const user = await API.get(
                'usersNew',
                `/users-new/get-by-id/${aws_cognito_id}`
            )

            this.setState({
                current_user: user,
            })
        } catch (e) {
            console.error(e)
        }
    }

    set_current_user = user => {
        this.setState({ current_user: user })
    }

    handleLogout = async () => {
        try {
            await Auth.signOut()
            this.userHasAuthenticated(false)
            this.setState({
                current_user: null,
            })
        } catch (e) {
            alert(e.message)
        }
    }

    render() {
        //console.log(this.props.language.language_current_ui);
        const childProps = {
            isAuthenticated: this.state.isAuthenticated,
            userHasAuthenticated: this.userHasAuthenticated,
            set_current_user: this.set_current_user,
            handleLogout: this.handleLogout,
            current_user: this.state.current_user,
            reload_user: this.repopulateData,
        }
        return (
            !this.state.isAuthenticating && (
                <IntlProvider
                    locale={this.props.language.language_current_ui}
                    messages={messages[this.props.language.language_current_ui]}
                >
                    <Router history={hist}>
                        <Switch>
                            {indexRoutes.map((prop, key) => {
                                return (
                                    <AppliedRoute
                                        path={prop.path}
                                        component={prop.component}
                                        key={key}
                                        props={childProps}
                                    />
                                )
                            })}
                        </Switch>
                    </Router>
                </IntlProvider>
            )
        )
    }
}

export default App
