import { API } from 'aws-amplify'

export const FETCH_BENEFICIARY_LIST_REQUEST = 'FETCH_BENEFICIARY_LIST_REQUEST'
export const FETCH_BENEFICIARY_LIST_SUCCESS = 'FETCH_BENEFICIARY_LIST_SUCCESS'
export const FETCH_BENEFICIARY_LIST_FAILURE = 'FETCH_BENEFICIARY_LIST_FAILURE'
export const BENEFICIARY_CREATE_NEW_SHOW_UI = 'BENEFICIARY_CREATE_NEW_SHOW_UI'
export const BENEFICIARY_CREATE_NEW_START = 'BENEFICIARY_CREATE_NEW_START'
export const BENEFICIARY_CREATE_NEW_CANCEL = 'BENEFICIARY_CREATE_NEW_CANCEL'
export const BENEFICIARY_CREATE_NEW_COMPLETE = 'BENEFICIARY_CREATE_NEW_COMPLETE'
export const BENEFICIARY_SELECT_UI = 'BENEFICIARY_SELECT_UI'
export const BENEFICIARY_ENTITIES_LIST_IS_DIRTY =
    'BENEFICIARY_ENTITIES_LIST_IS_DIRTY'
export const BENEFICIARY_BENEFICIARY_EDIT = 'BENEFICIARY_BENEFICIARY_EDIT'
export const BENEFICIARY_SELECT_ENTITY_SUCCESS =
    'ENTITIES_SELECT_ENTITY_SUCCESS'
export const BENEFICIARY_SELECT_ENTITY_REQUEST =
    'BENEFICIARY_SELECT_ENTITY_REQUEST'
export const BENEFICIARY_NEW_BENEFICIARY_CREATE =
    'BENEFICIARY_NEW_BENEFICIARY_CREATE'

export function requestBENEFICIARYListRequest() {
    return {
        type: FETCH_BENEFICIARY_LIST_REQUEST,
    }
}

export function receiveBENEFICIARYList(json) {
    return {
        type: FETCH_BENEFICIARY_LIST_SUCCESS,
        beneficiaries: json,
        receivedAt: Date.now(),
    }
}

export function fetchBeneficiaryList() {
    return function(dispatch) {
        dispatch(requestBENEFICIARYListRequest)
        API.get('beneficiaries', `/beneficiaries/get`)
            .then(response => {
                // console.log(response)
                dispatch(receiveBENEFICIARYList(response))
            })
            .catch(error => {
                console.log(error)
            })
    }
}

export function fetchBeneficiaryList_New(id) {
    return function(dispatch) {
        dispatch(requestBENEFICIARYListRequest)
        API.get('beneficiaries', `/beneficiaries/get/${id}`)
            .then(response => {
                dispatch(receiveBENEFICIARYList(response))
            })
            .catch(error => {
                console.log(error)
            })
    }
}
export function fetchBeneficiaryListByClientId(clientId) {
    return function(dispatch) {
        dispatch(requestBENEFICIARYListRequest)
        API.get('beneficiaries', `/beneficiaries/list/${clientId}`)
            .then(response => {
                // console.log(response)
                dispatch(receiveBENEFICIARYList(response))
            })
            .catch(error => {
                console.log(error)
            })
    }
}

export function selectBeneficiaryUi(value) {
    return {
        type: BENEFICIARY_SELECT_UI,
        value: value,
    }
}

export function editBeneficiary(bene_id) {
    return {
        type: BENEFICIARY_BENEFICIARY_EDIT,
        value: bene_id,
    }
}

export async function create_new_beneficiary(data) {
    API.post('beneficiaries', `/beneficiaries/create/in-database`, {
        body: {
            ...data,
        },
    })
        .then(database_result => {
            API.post('email', `/interaction/main`, {
                body: {
                    interaction: 'New Beneficiary Created',
                    data: data,
                    result: database_result.payload.database_result.insertId,
                },
            })
                .then(response => {
                    // console.log(response)
                    // console.log(data)
                    API.post(
                        'email18',
                        `/beneficiary/send-add-beneficiary-email`,
                        {
                            body: {
                                data: {
                                    user_id: data.client_id,
                                    beneficiary_id:
                                        database_result.payload.database_result
                                            .insertId,
                                    // "email": this.state.email,
                                    // "first_name": this.state.first_name,
                                    // "last_name": this.state.last_name
                                },
                            },
                        }
                    )
                        .then(response => {
                            console.log(response)
                            return database_result
                        })
                        .catch(error => {
                            console.log(error)
                        })
                })
                .catch(error => {
                    console.log(error)
                })
            return database_result
        })
        .catch(error => {
            console.log(error)
        })
}

export function createNewBeneficiary(data) {
    return async function(dispatch) {
        // dispatch(createNewBeneficiaryStart());

        try {
            await create_new_beneficiary(data)
        } catch (e) {
            console.log(e)
        }
    }
}

export function updateNewBeneficiaryCreation(key, value) {
    // console.log(key, value);
    return {
        type: BENEFICIARY_NEW_BENEFICIARY_CREATE,
        value: { key, value },
    }
}

export function cancelCreateNewBeneficiary() {
    return {
        type: BENEFICIARY_CREATE_NEW_CANCEL,
    }
}

export function loadBeneficiaryDetailsRequest() {
    return {
        type: BENEFICIARY_SELECT_ENTITY_REQUEST,
    }
}

export function loadBeneficiaryDetailsSuccess() {
    return {
        type: BENEFICIARY_SELECT_ENTITY_SUCCESS,
    }
}

export function createNewBeneficiaryStart() {
    return {
        type: BENEFICIARY_CREATE_NEW_START,
    }
}

export function createNewBeneficiaryComplete() {
    return {
        type: BENEFICIARY_CREATE_NEW_COMPLETE,
    }
}
