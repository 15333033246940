import React from 'react'
import cx from 'classnames'
import { withRouter } from 'react-router-dom'
import { connect } from 'react-redux'
import PropTypes from 'prop-types'
import { Switch, Redirect } from 'react-router-dom'
// creates a beautiful scrollbar
import PerfectScrollbar from 'perfect-scrollbar'
import 'perfect-scrollbar/css/perfect-scrollbar.css'

// @material-ui/core components
import withStyles from '@material-ui/core/styles/withStyles'

// core components
import HeaderContainer from '../components/Header/HeaderContainer.js'
import SidebarContainer from '../components/Sidebar/Sidebar'
import dashboardRoutes from '../routes/dashboard.jsx'
import appStyle from '../assets/jss/material-dashboard-pro-react/layouts/dashboardStyle.jsx'
import image from '../assets/img/sidebar-fww-01.jpg'
import logo from '../assets/img/ForexWorldWide_Logo_160x150.png'
import AuthenticatedRoute from '../components/Routes/AuthenticatedRoute'
import TransferAlerts from '../components/Alerts/TransferAlerts.jsx'

import ReactGA from "react-ga4";
import ClientStatusAlert from '../components/Alerts/ClientStatusAlert.jsx'

ReactGA.initialize("G-WDBS85CGD2");

var ps

class DashboardPageConatiner extends React.Component {
    constructor(props) {
        super(props)
        this.state = {
            mobileOpen: false,
            miniActive: false,
            isAuthenticated: false,
        }
        this.resizeFunction = this.resizeFunction.bind(this)
    }

    componentDidMount() {
        if (navigator.platform.indexOf('Win') > -1) {
            ps = new PerfectScrollbar(this.refs.mainPanel, {
                suppressScrollX: true,
                suppressScrollY: false,
            })
            document.body.style.overflow = 'hidden'
        }
        window.addEventListener('resize', this.resizeFunction)
        // console.log('location*: ', window.location.href)
        ReactGA.send({ hitType: "pageview", page: window.location.href, title: window.location.title });

    }
    componentWillMount() {
        this.unlisten = this.props.history.listen((location, action) => {
            // console.log('location: ', location.pathname)
            ReactGA.send({ hitType: "pageview", page: location.pathname, title: location.pathname });

        });
    }
    
    componentWillUnmount() {
        this.unlisten();
        if (navigator.platform.indexOf('Win') > -1) {
            ps.destroy()
        }
        window.removeEventListener('resize', this.resizeFunction)
    }

    componentDidUpdate(e) {
        if (e.history.location.pathname !== e.location.pathname) {
            this.refs.mainPanel.scrollTop = 0
            if (this.state.mobileOpen) {
                this.setState({ mobileOpen: false })
            }
        }
    }

    handleDrawerToggle = () => {
        this.setState({ mobileOpen: !this.state.mobileOpen })
    }

    getRoute() {
        return this.props.location.pathname !== '/maps/full-screen-maps'
    }

    sidebarMinimize() {
        this.setState({ miniActive: !this.state.miniActive })
    }

    resizeFunction() {
        if (window.innerWidth >= 960) {
            this.setState({ mobileOpen: false })
        }
    }

    switchRoutes() {
        return (
            <Switch>
                {dashboardRoutes.map((prop, key) => {
                    if (prop.redirect)
                        return (
                            <Redirect
                                from={prop.path}
                                to={prop.pathTo}
                                key={key}
                            />
                        )
                    if (prop.collapse)
                        return prop.views.map((prop, key) => {
                            return (
                                <AuthenticatedRoute
                                    path={prop.path}
                                    component={prop.component}
                                    key={key}
                                    props={{
                                        isAuthenticated: this.props.isAuthenticated,
                                        current_user: this.props.current_user,
                                        reload_user: this.props.reload_user,
                                    }}
                                />
                            )
                        })
                    return (
                        <AuthenticatedRoute
                            path={prop.path}
                            component={prop.component}
                            key={key}
                            props={{
                                isAuthenticated: this.props.isAuthenticated,
                                current_user: this.props.current_user,
                                reload_user: this.props.reload_user,
                            }}
                        />
                    )
                })}
            </Switch>
        )
    }

    render() {
        const { classes, ...rest } = this.props
        const mainPanel =
            classes.mainPanel +
            ' ' +
            cx({
                [classes.mainPanelSidebarMini]: this.state.miniActive,
                [classes.mainPanelWithPerfectScrollbar]:
                    navigator.platform.indexOf('Win') > -1,
            })
        const style = {}
        // if (
        //     this.props.app_state &&
        //     this.props.app_state.current_team &&
        //     window.location.pathname.toString().includes('dashboard')
        // ) {
        //     const logo = require(`../assets/img/brand/${
        //         this.props.app_state.current_team.brand_dashboardImage
        //     }`)
        //     style['backgroundImage'] = `url(${logo})`
        //     style['backgroundSize'] = 'cover'
        // } else {
            style['backgroundColor'] = '#F0F0F0'
        // }

        return (
            <div className={classes.wrapper} style={style}>
                <SidebarContainer
                    routes={dashboardRoutes}
                    logoText={'Forex Worldwide'}
                    logo={logo}
                    image={image}
                    handleDrawerToggle={this.handleDrawerToggle}
                    open={this.state.mobileOpen}
                    color="blue"
                    bgColor="black"
                    miniActive={this.state.miniActive}
                    {...rest}
                />
                <div className={mainPanel} ref="mainPanel">
                    <HeaderContainer
                        sidebarMinimize={this.sidebarMinimize.bind(this)}
                        miniActive={this.state.miniActive}
                        routes={dashboardRoutes}
                        handleDrawerToggle={this.handleDrawerToggle}
                        {...rest}
                    />
                    {/* On the /maps/full-screen-maps route we want the map to be on full screen - this is not possible if the content and conatiner classes are present because they have some paddings which would make the map smaller */}
                    {this.getRoute() ? (
                        <div className={classes.content}>
                            <div className={classes.container}>
                                <ClientStatusAlert/>
                                <TransferAlerts/>
                                {this.switchRoutes()}
                            </div>
                        </div>
                    ) : (
                        <div className={classes.map}>{this.switchRoutes()}</div>
                    )}
                </div>
            </div>
        )
    }
}

const mapStateToProps = (state, ownProps) => {
    return {
        app_state: state.app_state,
    }
}

const mapDispatchToProps = dispatch => {
    return {}
}

const Dashboard = connect(
    mapStateToProps,
    mapDispatchToProps
)(DashboardPageConatiner)

export default withRouter(withStyles(appStyle)(Dashboard))
