import React from 'react'
// import PropTypes from "prop-types";
import { connect } from 'react-redux'

// javascript plugin used to create scrollbars on windows
import PerfectScrollbar from 'perfect-scrollbar'
import { NavLink } from 'react-router-dom'
import cx from 'classnames'

// @material-ui/core components
import withStyles from '@material-ui/core/styles/withStyles'
import Drawer from '@material-ui/core/Drawer'
import List from '@material-ui/core/List'
import ListItem from '@material-ui/core/ListItem'
import ListItemIcon from '@material-ui/core/ListItemIcon'
import ListItemText from '@material-ui/core/ListItemText'
import Hidden from '@material-ui/core/Hidden'
import Collapse from '@material-ui/core/Collapse'
import Icon from '@material-ui/core/Icon'

// core components
import HeaderLinks from 'components/Header/HeaderLinks.jsx'
//import HeaderLinksContainer from "components/Header/HeaderLinksContainer.js";
import sidebarStyle from 'assets/jss/material-dashboard-pro-react/components/sidebarStyle.jsx'

import userAvatar from '../../assets/img/faces/user.png'
// import gostudy from '../../assets/img/GOSTUDY_PrimaryUsage_White.png'
import Button from '../CustomButtons/Button'
import { Divider } from '@material-ui/core'
import ClearIcon from '@material-ui/icons/Clear'
// import { CreateNewFolder } from "@material-ui/icons";

var ps

class SidebarWrapper extends React.Component {
    componentDidMount() {
        if (navigator.platform.indexOf('Win') > -1) {
            ps = new PerfectScrollbar(this.refs.sidebarWrapper, {
                suppressScrollX: true,
                suppressScrollY: false,
            })
        }
    }

    componentWillUnmount() {
        if (navigator.platform.indexOf('Win') > -1) {
            ps.destroy()
        }
    }

    render() {
        const {
            className,
            user,
            brand,
            shortcut,
            headerLinks,
            links,
            classes,
        } = this.props

        return (
            <div className={className} ref="sidebarWrapper">
                <ClearIcon
                    style={{
                        float: 'right',
                        cursor: 'pointer',
                        marginRight: '3%',
                        marginTop: '3%',
                    }}
                    className={className.close}
                />
                {user}
                {/* <UserWarningContainer />
        <PasswordWarning />
        <InvoiceAlertContainer /> */}
                {shortcut}
                {headerLinks}
                {links}
            </div>
        )
    }
}

class Sidebar extends React.Component {
    constructor(props) {
        super(props)
        this.state = {
            openAvatar: false,
            openComponents: this.activeRoute('/components'),
            openForms: this.activeRoute('/forms'),
            openTables: this.activeRoute('/tables'),
            openMaps: this.activeRoute('/maps'),
            openPages: this.activeRoute('-page'),
            miniActive: true,
        }
        this.activeRoute.bind(this)
    }

    // verifies if routeName is the one active (in browser input)
    activeRoute(routeName) {
        return this.props.location.pathname.indexOf(routeName) > -1
            ? true
            : false
    }
    activeNestedRoute(props) {
        if (props.childRoutes != undefined) {
            if (this.props.location.pathname.indexOf(props.path) > -1) {
                return true
            } else {
                const locationPath = this.props.location.pathname.split('/')
                // check child routes
                if (locationPath[1] === props.basePath) {
                    return true
                } else {
                    return false
                }
            }
        } else {
            return this.props.location.pathname.indexOf(props.path) > -1
                ? true
                : false
        }
    }
    openCollapse(collapse) {
        var st = {}
        st[collapse] = !this.state[collapse]
        this.setState(st)
    }
    logOut() {
        this.props.handleLogout()
    }
    render() {
        const { classes, color, image, routes, bgColor } = this.props
        const rtlActive = null

        if (!this.props.app_state.current_team) {
            return null
        }
        if (!this.props.app_state.current_client) {
            return null
        }

        // ************************************************************************************************
        // SET UP USER
        // ************************************************************************************************
        let logo = require(`../../assets/img/brand/travel-logo.jpg`)
        let logoText = 'Forex WorldWide'
        const brandLogo = this.props.app_state.current_team.brand_sidebar_logo
        let imageStyle = classes.imgSecond
        let avatarImage = classes.withoutLogo
        if (this.props.app_state.current_team) {
            try {
                if (brandLogo) logo = require(`../../assets/img/brand/${brandLogo}`)
            } catch (err) {
                console.log(err)
                logo = require(`../../assets/img/brand/ForexWorldWide_Logo_160x150.png`)
            }

            switch (brandLogo) {
                case 'crocmedia.jpg':
                    avatarImage = classes.withLogo1
                    break
                case 'travel-logo.jpg':
                    avatarImage = classes.withLogo2
                    break
                case 'crocmedia_white.png':
                    avatarImage = classes.withLogo3
                    break
                case 'ForexWorldWide_Logo_160x150.png':
                    avatarImage = classes.withLogo4
                    break
                case 'GOSTUDY_PrimaryUsage_White.png':
                    avatarImage = classes.withLogo5
                    break
                case 'GOSTUDY_PrimaryUsage_White-old.png':
                    avatarImage = classes.withLogo7
                    break
                case 'idta.png':
                    avatarImage = classes.withLogo6
                    break
                case '4QPFa.png':
                    avatarImage = classes.logo4QPF;
                    break;
                case 'skiaspen.png':
                    avatarImage = classes.logoSkiaspen;
                    break;
                default:
                    avatarImage = classes.withoutLogo
                    break
            }
            if (this.props.app_state.current_team.team_shortname)
                logoText = this.props.app_state.current_team.team_shortname
        }
        const avatar_image = this.props.app_state.current_team
            .brand_sidebar_logo
            ? logo
            : userAvatar

        const itemText =
            classes.itemText +
            ' ' +
            cx({
                [classes.itemTextMini]:
                    this.props.miniActive && this.state.miniActive,
                [classes.itemTextMiniRTL]:
                    rtlActive && this.props.miniActive && this.state.miniActive,
                [classes.itemTextRTL]: rtlActive,
            })
        const userWrapperClass =
            classes.user +
            ' ' +
            cx({
                [classes.whiteAfter]: bgColor === 'white',
            })
        const photo =
            (this.props.app_state.current_team.brand_sidebar_logo
                ? classes.photoLogo
                : classes.photo) +
            ' ' +
            cx({
                [classes.photoRTL]: rtlActive,
            })
        var user = this.props.current_user ? (
            <div>
                <div className={photo}>
                    <img
                        src={avatar_image}
                        className={avatarImage + ' ' + classes.avatarImg}
                        alt="..."
                    />
                </div>
                <div style={{textAlign: 'center'}}>
                  <em>Powered by Forex WorldWide</em>
                </div>
                <br />
                <List className={classes.listSecondConfig}>
                    <ListItem className={classes.item + ' ' + classes.userItem}>
                        <NavLink
                            to="/user-profile"
                            className={
                                classes.itemLink +
                                ' ' +
                                classes.userCollapseButton +
                                ' ' +
                                classes.textCenter
                            }
                        >
                            {this.props.app_state &&
                            this.props.app_state.current_client.account_type ==
                                2 ? (
                                <div>
                                    <ListItemText
                                        primary={`${
                                            this.props.app_state.current_client
                                                .business_company_name
                                        }`}
                                        disableTypography={true}
                                        className={
                                            itemText +
                                            ' ' +
                                            classes.userItemText3
                                        }
                                    />
                                    <ListItemText
                                        primary={`${
                                            this.props.app_state.current_client
                                                .first_name
                                        } ${
                                            this.props.app_state.current_client
                                                .last_name
                                        }`}
                                        disableTypography={true}
                                        className={
                                            itemText +
                                            ' ' +
                                            classes.userItemText3
                                        }
                                    />
                                </div>
                            ) : (
                                <ListItemText
                                    primary={`${
                                        this.props.app_state.current_client
                                            .first_name
                                    } ${
                                        this.props.app_state.current_client
                                            .last_name
                                    }`}
                                    disableTypography={true}
                                    className={
                                        itemText + ' ' + classes.userItemText
                                    }
                                />
                            )}
                        </NavLink>
                    </ListItem>
                    <br />

                    {/* <ListItem className={classes.item + ' ' + classes.userItem}>
                        <NavLink
                            to="/user-profile"
                            className={
                                classes.itemLink +
                                ' ' +
                                classes.userCollapseButton +
                                ' ' +
                                classes.textCenter
                            }
                        >
                            <Button 
                                fullWidth={true} 
                                color="info" 
                                size="sm">
                                Manage my Account
                            </Button>
                        </NavLink>
                    </ListItem> */}
                    <ListItem className={classes.item + ' ' + classes.userItem}>
                        <NavLink
                            to="#"
                            className={
                                classes.itemLink +
                                ' ' +
                                classes.userCollapseButton +
                                ' ' +
                                classes.textCenter
                            }
                        >
                            <Button
                                fullWidth={true}
                                color="info"
                                size="sm"
                                onClick={() => this.logOut()}
                            >
                                Sign Out
                            </Button>
                        </NavLink>
                    </ListItem>
                    <br/>
                    <ListItem className={classes.item + ' ' + classes.userItem}>
                    <NavLink
                            to="/externalTransfer"
                            className={
                                classes.itemLink +
                                ' ' +
                                classes.userCollapseButton +
                                ' ' +
                                classes.textCenter
                            }
                        >
                            <Button
                                fullWidth={true} 
                                color="info"
                                size="sm"
                            >
                                Request a New Transfer
                            </Button>
                        </NavLink>
                    </ListItem>
                </List>
            </div>
        ) : (
            <span />
        )
        // ************************************************************************************************

        // ************************************************************************************************
        // SET UP BUILD SHORCUTS
        // ************************************************************************************************
        let build_shortcut = []

        // if (
        //   this.props.app_state.current_team.brand_send_to_code &&
        //   this.props.app_state.current_team.brand_send_to_description
        // ) {
        //   let to_reference = "-";
        //   switch (this.props.app_state.current_team.id) {
        //     case 24:
        //       if (this.props.app_state.current_client.external_reference) {
        //         to_reference = `${this.props.app_state.current_client.external_reference
        //           .toString()
        //           .replace("-", " ")}`;
        //       } else {
        //         to_reference = ``;
        //       }
        //       break;
        //     default:
        //       if (this.props.app_state.current_client.external_reference) {
        //         to_reference = `${this.props.app_state.current_client.external_reference
        //           .toString()
        //           .replace("-", " ")}`;
        //       } else {
        //         to_reference = ``;
        //       }
        //       break;
        //   }
        //   let to_code = this.props.app_state.current_team.brand_send_to_code
        //     .toString()
        //     .replace("CLIENTREF", to_reference);

        //   build_shortcut.push(
        //     <ListItem
        //       key={to_code}
        //       className={classes.item + " " + classes.userItem}
        //       style={{ marginBotton: 0 }}
        //     >
        //       <Button
        //         onClick={() =>
        //           window.location.replace(`/shortcut_transfer/${to_code}`)
        //         }
        //         color="blue"
        //         style={{ width: "100%", marginBottom: "1%", borderRadius: "0" }}
        //       >
        //         {this.props.app_state.current_team.brand_send_to_description}
        //       </Button>
        //     </ListItem>
        //   );
        // }
        // if (
        //   this.props.app_state.current_team.brand_send_from_code &&
        //   this.props.app_state.current_team.brand_send_from_description
        // ) {
        //   let from_reference = "-";
        //   switch (this.props.app_state.current_team.id) {
        //     case 24:
        //       if (this.props.app_state.current_client.external_reference) {
        //         from_reference = `${this.props.app_state.current_client.external_reference
        //           .toString()
        //           .replace("-", " ")}`;
        //       } else {
        //         from_reference = ``;
        //       }
        //       break;
        //     default:
        //       if (this.props.app_state.current_client.external_reference) {
        //         from_reference = `${this.props.app_state.current_client.external_reference
        //           .toString()
        //           .replace("-", " ")}`;
        //       } else {
        //         from_reference = ``;
        //       }
        //       break;
        //   }
        //   let from_code = this.props.app_state.current_team.brand_send_from_code
        //     .toString()
        //     .replace("CLIENTREF", from_reference);

        //   build_shortcut.push(
        //     <ListItem
        //       key={from_code}
        //       className={classes.item + " " + classes.userItem}
        //       style={{ marginBotton: 0 }}
        //     >
        //       <Button
        //         onClick={() =>
        //           window.location.replace(`/shortcut_transfer/${from_code}`)
        //         }
        //         color="blue"
        //         style={{ width: "100%", marginBottom: "1%", borderRadius: "0" }}
        //       >
        //         {this.props.app_state.current_team.brand_send_from_description}
        //       </Button>
        //     </ListItem>
        //   );
        // }

        let shortcut_wrapper = (
            <div className={userWrapperClass}>
                <List className={classes.list}>
                    {build_shortcut.map(item => {
                        return item
                    })}
                </List>
            </div>
        )

        // Only display shortcut if client is NOT pending and NOT incompletee
        if (
            this.props.app_state.current_client.account_status === 1 ||
            this.props.app_state.current_client.account_status === 5
        ) {
            shortcut_wrapper = null
        }
        // ************************************************************************************************

        // ************************************************************************************************
        // SET UP LINKS
        // ************************************************************************************************
        var links = (
            <List className={classes.list}>
                {routes.map((prop, key) => {
                    if (prop.redirect) {
                        return null
                    }
                    if (prop.invisible) {
                        return null
                    }
                    if (prop.beta && !(this.props.app_state.current_client.beta)) {
                        return null;
                    }
                    if (
                        !(
                            this.props.app_state.current_client
                                .account_status === 1
                        ) &&
                        prop.name == 'Pending Verification'
                    ) {
                        return null
                    }
                    if (
                        this.props.app_state.current_client.account_status === 1 &&
                        (
                            prop.name !== 'Pending Verification' &&
                            prop.name !== 'Identification' &&
                            prop.name !== 'Dashboard'
                        )
                    ) {
                        return null
                    }

                    if (
                            !(this.props.app_state.current_client.account_status === 5) &&
                            prop.name == 'Registration'
                        ) {
                        return null
                    }

                    if (
                        this.props.app_state.current_client.account_status === 5 &&
                        (
                            prop.name !== 'Registration' && 
                            prop.name !== 'Dashboard'
                        )
                    ) {
                        return null
                    }

                    if (
                        prop.hide_gostudy &&
                        (this.props.app_state.current_brand === 'gostudy' ||
                            this.props.app_state.current_brand === 'abcu')
                    ) {
                        return null
                    }
                    if (
                        prop.show_gostudy &&
                        (!(this.props.app_state.current_brand === 'gostudy') ||
                            !(this.props.app_state.current_brand === 'abcu'))
                    ) {
                        return null
                    }

                    switch (prop.code) {
                        case 'identification_list':
                            if (
                                !this.props.app_state.current_team
                                    .brand_sidebar_identification_list
                            ) {
                                return null
                            }
                            break
                        case 'new_transfer':
                            if (
                                !this.props.app_state.current_team
                                    .brand_sidebar_new_transfer
                            ) {
                                return null
                            }
                            break
                        case 'new_payment':
                            if (
                                !(
                                    this.props.app_state.current_brand ===
                                    'gostudy'
                                ) ||
                                !(this.props.app_state.current_brand === 'abcu')
                            ) {
                                return null
                            }
                            break
                        case 'currencytools':
                            if (
                                !(this.props.app_state.current_team.brand_sidebar_currencytools === 1) && 
                                !(this.props.app_state.current_client.ec_info_charts === 1)
                            ) {
                                return null
                            }
                            break
                        case 'ratealerts': 
                            return null
                            break
                        case 'multi_pay':
                            if (
                                !(
                                    this.props.app_state.current_team
                                        .brand_sidebar_multipay === 1
                                )
                            ) {
                                return null
                            }
                            break
                        case 'invoicelist':
                            if (
                                !(
                                    this.props.app_state.current_team
                                        .brand_sidebar_invoicelist === 1
                                )
                            ) {
                                return null
                            }
                            break
                        case 'paymentRequestList':
                            if (
                                !(
                                    this.props.app_state.current_team
                                        .brand_paymentRequests === 1
                                )
                            ) {
                                return null
                            }
                            break
                        case 'forwards':
                            if (
                                !(this.props.app_state.current_team.brand_sidebar_forwards === 1) || 
                                !(this.props.app_state.current_client.transfer_forwards_allowed === 1)
                                
                            ) {
                                return null
                            }
                            break
                        case 'searchtransfers':
                            if (
                                !(
                                    this.props.app_state.current_team
                                        .brand_sidebar_searchtransfers === 1
                                )
                            ) {
                                return null
                            }
                            break
                        case 'holding_account':
                            if (
                                !(this.props.app_state.current_team.brand_sidebar_ledger === 1) && 
                                !(this.props.app_state.current_client.feature_ledger === 1)
                            ) {
                                return null
                            }
                            break
                    }

                    if (prop.collapse) {
                        const navLinkClasses =
                            classes.itemLink +
                            ' ' +
                            cx({
                                [' ' +
                                classes.collapseActive]: this.activeRoute(
                                    prop.path
                                ),
                            })
                        const itemText =
                            classes.itemText +
                            ' ' +
                            cx({
                                [classes.itemTextMini]:
                                    this.props.miniActive &&
                                    this.state.miniActive,
                                [classes.itemTextMiniRTL]:
                                    rtlActive &&
                                    this.props.miniActive &&
                                    this.state.miniActive,
                                [classes.itemTextRTL]: rtlActive,
                            })
                        const collapseItemText =
                            classes.collapseItemText +
                            ' ' +
                            cx({
                                [classes.collapseItemTextMini]:
                                    this.props.miniActive &&
                                    this.state.miniActive,
                                [classes.collapseItemTextMiniRTL]:
                                    rtlActive &&
                                    this.props.miniActive &&
                                    this.state.miniActive,
                                [classes.collapseItemTextRTL]: rtlActive,
                            })
                        const itemIcon =
                            classes.itemIcon +
                            ' ' +
                            cx({
                                [classes.itemIconRTL]: rtlActive,
                            })
                        const caret =
                            classes.caret +
                            ' ' +
                            cx({
                                [classes.caretRTL]: rtlActive,
                            })

                        //setup a menu text module
                        //this.props.language.language_current_ui
                        let menu_item_text = ''
                        switch (this.props.language.language_current_ui) {
                            case 'es':
                                menu_item_text = prop.name_es || prop.name
                                break
                            case 'de':
                                menu_item_text = prop.name_de || prop.name
                                break
                            case 'zh':
                                menu_item_text = prop.name_zh || prop.name
                                break
                            case 'fr':
                                menu_item_text = prop.name_fr || prop.name
                                break
                            case 'it':
                                menu_item_text = prop.name_it || prop.name
                                break
                            case 'pt':
                                menu_item_text = prop.name_pt || prop.name
                                break
                            default:
                                menu_item_text = prop.name
                                break
                        }

                        return (
                            <ListItem key={key} className={classes.item}>
                                <NavLink
                                    to={'#'}
                                    className={navLinkClasses}
                                    onClick={() =>
                                        this.openCollapse(prop.state)
                                    }
                                >
                                    <ListItemIcon className={itemIcon}>
                                        {typeof prop.icon === 'string' ? (
                                            <Icon>{prop.icon}</Icon>
                                        ) : (
                                            <prop.icon />
                                        )}
                                    </ListItemIcon>
                                    <ListItemText
                                        primary={menu_item_text}
                                        secondary={
                                            <b
                                                className={
                                                    caret +
                                                    ' ' +
                                                    (this.state[prop.state]
                                                        ? classes.caretActive
                                                        : '')
                                                }
                                            />
                                        }
                                        disableTypography={true}
                                        className={itemText}
                                    />
                                </NavLink>
                                <Collapse
                                    in={this.state[prop.state]}
                                    unmountOnExit
                                >
                                    <List
                                        className={
                                            classes.list +
                                            ' ' +
                                            classes.collapseList
                                        }
                                    >
                                        {prop.views.map((prop, key) => {
                                            if (prop.redirect) {
                                                return null
                                            }
                                            if (prop.invisible) {
                                                return null
                                            }
                                            if (prop.beta && !(this.props.app_state.current_client.beta)) {
                                                return null;
                                              }

                                            if (
                                                prop.hide_gostudy &&
                                                (this.props.app_state
                                                    .current_brand ===
                                                    'gostudy' ||
                                                    this.props.app_state
                                                        .current_brand ===
                                                        'abcu')
                                            ) {
                                                return null
                                            }
                                            if (
                                                prop.show_gostudy &&
                                                (!(
                                                    this.props.app_state
                                                        .current_brand ===
                                                    'gostudy'
                                                ) ||
                                                    !(
                                                        this.props.app_state
                                                            .current_brand ===
                                                        'abcu'
                                                    ))
                                            ) {
                                                return null
                                            }

                                            switch (prop.code) {
                                                case 'identification_list':
                                                    if (
                                                        !this.props.app_state
                                                            .current_team
                                                            .brand_sidebar_identification_list
                                                    ) {
                                                        return null
                                                    }
                                                case 'new_transfer':
                                                    if (
                                                        !this.props.app_state
                                                            .current_team
                                                            .brand_sidebar_new_transfer
                                                    ) {
                                                        return null
                                                    }
                                                case 'new_payment':
                                                    if (
                                                        !(
                                                            this.props.app_state
                                                                .current_brand ===
                                                            'gostudy'
                                                        ) ||
                                                        !(
                                                            this.props.app_state
                                                                .current_brand ===
                                                            'abcu'
                                                        )
                                                    ) {
                                                        return null
                                                    }
                                                case 'currencytools':
                                                    if (
                                                        !(
                                                            this.props.app_state
                                                                .current_team
                                                                .brand_sidebar_currencytools ===
                                                            '1'
                                                        )
                                                    ) {
                                                        return null
                                                    }
                                                // case 'ratealerts': 
                                                //     return null
                                                //     break
                        
                                            }

                                            //setup a menu text module
                                            //this.props.language.language_current_ui
                                            let menu_item_text = ''
                                            switch (
                                                this.props.language
                                                    .language_current_ui
                                            ) {
                                                case 'es':
                                                    menu_item_text =
                                                        prop.name_es ||
                                                        prop.name
                                                    break
                                                case 'de':
                                                    menu_item_text =
                                                        prop.name_de ||
                                                        prop.name
                                                    break
                                                case 'zh':
                                                    menu_item_text =
                                                        prop.name_zh ||
                                                        prop.name
                                                    break
                                                case 'fr':
                                                    menu_item_text =
                                                        prop.name_fr ||
                                                        prop.name
                                                    break
                                                case 'it':
                                                    menu_item_text =
                                                        prop.name_it ||
                                                        prop.name
                                                    break
                                                case 'pt':
                                                    menu_item_text =
                                                        prop.name_pt ||
                                                        prop.name
                                                    break
                                                default:
                                                    menu_item_text = prop.name
                                                    break
                                            }
                                            const navLinkClasses =
                                                classes.collapseItemLink +
                                                ' ' +
                                                cx({
                                                    [' ' +
                                                    classes[
                                                        color
                                                    ]]: this.activeRoute(
                                                        prop.path
                                                    ),
                                                })
                                            const collapseItemMini =
                                                classes.collapseItemMini +
                                                ' ' +
                                                cx({
                                                    [classes.collapseItemMiniRTL]: rtlActive,
                                                })
                                            return (
                                                <ListItem
                                                    key={key}
                                                    className={
                                                        classes.collapseItem
                                                    }
                                                >
                                                    <NavLink
                                                        to={prop.path}
                                                        className={
                                                            navLinkClasses
                                                        }
                                                    >
                                                        <span
                                                            className={
                                                                collapseItemMini
                                                            }
                                                        >
                                                            {prop.mini}
                                                        </span>
                                                        <ListItemText
                                                            primary={
                                                                menu_item_text
                                                            }
                                                            disableTypography={
                                                                true
                                                            }
                                                            className={
                                                                collapseItemText
                                                            }
                                                        />
                                                    </NavLink>
                                                </ListItem>
                                            )
                                        })}
                                    </List>
                                </Collapse>
                                <Divider className={classes.divider} />
                            </ListItem>
                        )
                    }
                    const navLinkClasses =
                        classes.itemLink +
                        ' ' +
                        cx({
                            [' ' + classes[color]]: this.activeNestedRoute(
                                prop
                            ),
                        })
                    const itemText =
                        classes.itemText +
                        ' ' +
                        cx({
                            [classes.itemTextMini]:
                                this.props.miniActive && this.state.miniActive,
                            [classes.itemTextMiniRTL]:
                                rtlActive &&
                                this.props.miniActive &&
                                this.state.miniActive,
                            [classes.itemTextRTL]: rtlActive,
                        })
                    const itemIcon =
                        classes.itemIcon +
                        ' ' +
                        cx({
                            [classes.itemIconRTL]: rtlActive,
                        })

                    //setup a menu text module
                    //this.props.language.language_current_ui
                    let menu_item_text = ''
                    switch (this.props.language.language_current_ui) {
                        case 'es':
                            menu_item_text = prop.name_es || prop.name
                            break
                        case 'de':
                            menu_item_text = prop.name_de || prop.name
                            break
                        case 'zh':
                            menu_item_text = prop.name_zh || prop.name
                            break
                        case 'fr':
                            menu_item_text = prop.name_fr || prop.name
                            break
                        case 'it':
                            menu_item_text = prop.name_it || prop.name
                            break
                        case 'pt':
                            menu_item_text = prop.name_pt || prop.name
                            break
                        default:
                            menu_item_text = prop.name
                            break
                    }
                    return (
                        <ListItem key={key} className={classes.item}>
                            <NavLink to={prop.path} className={navLinkClasses}>
                                <ListItemIcon className={itemIcon}>
                                    {typeof prop.icon === 'string' ? (
                                        <Icon>{prop.icon}</Icon>
                                    ) : (
                                        <prop.icon />
                                    )}
                                </ListItemIcon>
                                <ListItemText
                                    primary={menu_item_text}
                                    disableTypography={true}
                                    className={itemText}
                                />
                            </NavLink>
                            <Divider className={classes.divider} />
                        </ListItem>
                    )
                })}
            </List>
        )
        // ************************************************************************************************

        // ====================================================================
        // BRAND SETTINGS
        // ====================================================================

        const logoNormal =
            classes.logoNormal +
            ' ' +
            cx({
                [classes.logoNormalSidebarMini]:
                    this.props.miniActive && this.state.miniActive,
                [classes.logoNormalSidebarMiniRTL]:
                    rtlActive && this.props.miniActive && this.state.miniActive,
                [classes.logoNormalRTL]: rtlActive,
            })
        const logoMini =
            classes.logoMini +
            ' ' +
            cx({
                [classes.logoMiniRTL]: rtlActive,
            })
        const logoClasses =
            classes.logo +
            ' ' +
            cx({
                [classes.whiteAfter]: bgColor === 'white',
            })
        var brand = (
            <React.Fragment>
                <div className={logoClasses + ' ' + classes.textCenter}>
                    <a
                        href="https://www.forexworldwide.com"
                        className={logoMini}
                    >
                        <img src={logo} alt="logo" className={imageStyle} />
                    </a>
                </div>
            </React.Fragment>
        )
        const drawerPaper =
            classes.drawerPaper +
            ' ' +
            cx({
                [classes.drawerPaperMini]:
                    this.props.miniActive && this.state.miniActive,
                [classes.drawerPaperRTL]: rtlActive,
            })
        const sidebarWrapper =
            classes.sidebarWrapper +
            ' ' +
            cx({
                [classes.drawerPaperMini]:
                    this.props.miniActive && this.state.miniActive,
                [classes.sidebarWrapperWithPerfectScrollbar]:
                    navigator.platform.indexOf('Win') > -1,
            })

        return (
            <div ref="mainPanel">
                {/* ========================================================================== */}
                {/* THIS IS THE VERSION TO BE SHOWN ON MOBILE DEVICES */}
                {/* ========================================================================== */}
                <Hidden mdUp implementation="css">
                    <Drawer
                        variant="temporary"
                        anchor="left"
                        open={this.props.open}
                        classes={{
                            paper:
                                drawerPaper +
                                ' ' +
                                classes[bgColor + 'Background'],
                        }}
                        onClose={this.props.handleDrawerToggle}
                        ModalProps={{
                            keepMounted: true, // Better open performance on mobile.
                        }}
                    >
                        <SidebarWrapper
                            className={sidebarWrapper}
                            brand={brand}
                            user={user}
                            shortcut={shortcut_wrapper}
                            headerLinks={<HeaderLinks />}
                            links={links}
                        />
                        {image !== undefined ? (
                            <div
                                className={classes.background}
                                style={{
                                    backgroundImage: 'url(' + image + ')',
                                }}
                            />
                        ) : null}
                    </Drawer>
                </Hidden>
                {/* ========================================================================== */}

                {/* ========================================================================== */}
                {/* THIS IS THE VERSION FOR DESKTOP DEVICES */}
                {/* ========================================================================== */}
                <Hidden smDown implementation="css">
                    <Drawer
                        onMouseOver={() => this.setState({ miniActive: false })}
                        onMouseOut={() => this.setState({ miniActive: true })}
                        anchor="left"
                        variant="permanent"
                        open
                        classes={{
                            paper:
                                drawerPaper +
                                ' ' +
                                classes[bgColor + 'Background'],
                        }}
                    >
                        <SidebarWrapper
                            className={sidebarWrapper}
                            brand={brand}
                            user={user}
                            shortcut={shortcut_wrapper}
                            links={links}
                        />
                        {image !== undefined ? (
                            <div
                                className={classes.background}
                                style={{
                                    backgroundImage: 'url(' + image + ')',
                                }}
                            />
                        ) : null}
                    </Drawer>
                </Hidden>
                {/* ========================================================================== */}
            </div>
        )
    }
}

Sidebar.defaultProps = {
    bgColor: 'blue',
}

const mapStateToProps = state => {
    return {
        app_state: state.app_state,
        language: state.language,
    }
}

const mapDispatchToProps = dispatch => {
    return {}
}

const SidebarContainer = connect(
    mapStateToProps,
    mapDispatchToProps
)(withStyles(sidebarStyle)(Sidebar))

export default SidebarContainer
