import React from 'react'
import { connect } from 'react-redux'

import Datetime from 'react-datetime'
import PropTypes from 'prop-types'

// @material-ui/core components
import withStyles from '@material-ui/core/styles/withStyles'

// core components
import GridContainer from 'components/Grid/GridContainer.jsx'
import GridItem from 'components/Grid/GridItem.jsx'
import Card from 'components/Card/Card.jsx'
import CardHeader from 'components/Card/CardHeader.jsx'
import CardBody from 'components/Card/CardBody.jsx'

import FormControl from '@material-ui/core/FormControl'
import selectStyles from 'assets/jss/material-dashboard-pro-react/customSelectStyle.jsx'
import { cardTitle } from '../../../assets/jss/material-dashboard-pro-react'
import { API } from 'aws-amplify'
import HorizontalStepper from '../../Timeline/HorizontalStepper'

import TransferDetailRemittanceCommunication from './TransferDetailRemittanceCommunication'

const styles = {
    selectStyles,
    cardTitle,
    cardIconTitle: {
        ...cardTitle,
        marginTop: '15px',
        marginBottom: '0px',
        '& small': {
            fontSize: '80%',
            fontWeight: '400',
        },
    },
    cardCategory: {
        marginTop: '10px',
        color: '#999999 !important',
        textAlign: 'center',
    },
    description: {
        color: '#999999',
    },
    updateProfileButton: {
        float: 'right',
    },
    title: {
        color: '#3C4858',
        textDecoration: 'none',
    },

    formCategory: {
        marginBottom: '0',
        color: '#999999',
        fontSize: '14px',
        padding: '10px 0 10px',
    },
    registerButton: {
        float: 'right',
    },
    flexEnd: {
        display: 'flex',
        justifyContent: 'space-between',
    },

    tableHeaders: {
        width: 150,
        textAlign: 'left',
        verticalAlign: 'top',
        paddingTop: 0,
        paddingBottom: 0,
        paddingLeft: 8,
    },
    tableCells: {
        textAlign: 'left',
        verticalAlign: 'top',
        paddingTop: 0,
        paddingBottom: 0,
    },
    backgroundBlue: {
        backgroundColor: '#348feb20',
    },
    pageHeadings: {
        fontFamily: 'Arial',
        fontWeight: 600,
        fontSize: 22,
    },
    pageSubHeadings: {
        fontFamily: 'Arial',
        fontWeight: 600,
        fontSize: 18,
    },
}

class TransferDetailRemittance extends React.Component {
    constructor(props) {
        super(props)
        this.state = {
            // register form
            loaded: false,
            is_loading: true,
            audCollection: false
        }
    }

    componentDidMount() {
        // console.log("FROM DETAIL: ");
        // console.log(this.props);

        if (this.props.remitting_bank_id) {
            // console.log("FROM DETAIL2: ");
            API.get(
                'entitybanksaccount',
                `/get/id/${this.props.remitting_bank_id}`
            )
                .then(response => {
                    // console.log("entitybanksaccount");
                    // console.log(response);
                    this.setState({
                        loaded: true,
                        is_loading: false,
                        entity_bank_account: response,
                    })
                    return response
                })
                .then(response => {
                    API.get('entitybanks', `/get/id/${response.entity_bank_id}`)
                        .then(response => {
                            // console.log("entitybanks");
                            // console.log(response);
                            this.setState({
                                loaded: true,
                                is_loading: false,
                                entity_bank: response,
                            })

                            API.get(
                                'countries',
                                `/countries/get/${response.address_country}`
                            )
                                .then(response => {
                                    this.setState({
                                        entity_bank_address_country:
                                            response.full_name,
                                    })

                                    return response
                                })
                                .catch(error => {
                                    console.log(error)
                                })

                            return response
                        })
                        .catch(error => {
                            console.log(error)
                        })
                    API.get(
                        'countries',
                        `/countries/get/${response.address_country}`
                    )
                        .then(response => {
                            this.setState({
                                entity_bank_account_address_country:
                                    response.full_name,
                            })

                            return response
                        })
                        .catch(error => {
                            console.log(error)
                        })
                })
                .catch(error => {
                    console.log(error)
                })
        }

        if (this.props.currency_from_id) {
            API.get(
                'currencies',
                `/currencies/get/${this.props.currency_from_id}`
            )
                .then(response => {
                    this.setState({
                        currency_from: response,
                    })
                })
                .catch(error => {
                    console.log(error)
                })
        }

        if (this.props.currency_to_id) {
            API.get(
                'currencies',
                `/currencies/get/${this.props.currency_to_id}`
            )
                .then(response => {
                    // console.log(response);
                    this.setState({
                        currency_to: response,
                    })
                })
                .catch(error => {
                    console.log(error)
                })
        }
        if ((this.props.currency_from_id == 1) && (this.props.currency_to_id == 1)) {
            this.setState({
                audCollection: true
            })
        }
        // if ((this.state.currency_from && this.state.currency_from.id == 1) && (this.state.currency_to && this.state.currency_to.id == 1)) {
        //     this.setState({
        //         audCollection: true
        //     })
        // }
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        if (prevProps.remitting_bank_id !== this.props.remitting_bank_id) {
            if (this.props.remitting_bank_id) {
                API.get(
                    'entitybanksaccount',
                    `/get/id/${this.props.remitting_bank_id}`
                )
                    .then(response => {
                        this.setState({
                            loaded: true,
                            is_loading: false,
                            entity_bank_account: response,
                        })

                        return response
                    })
                    .then(response => {
                        API.get(
                            'entitybanks',
                            `/get/id/${response.entity_bank_id}`
                        )
                            .then(response => {
                                this.setState({
                                    loaded: true,
                                    is_loading: false,
                                    entity_bank: response,
                                })

                                return response
                            })
                            .catch(error => {
                                console.log(error)
                            })
                    })
                    .catch(error => {
                        console.log(error)
                    })
            }
        }
        if (prevProps.currency_from_id !== this.props.currency_from_id) {
            API.get(
                'currencies',
                `/currencies/get/${this.props.currency_from_id}`
            )
                .then(response => {
                    this.setState({
                        currency_from: response,
                    })
                })
                .catch(error => {
                    console.log(error)
                })
            if ((this.props.currency_from_id == 1) && (this.props.currency_to_id == 1)) {
                this.setState({
                    audCollection: true
                })
            }
        }

    }

    getFullDate = s => {
        let showDate = ''
        if (s.getMonth() >= 9) {
            if (s.getDate() > 9)
                showDate =
                    s.getFullYear() +
                    '-' +
                    (s.getMonth() + 1) +
                    '-' +
                    s.getDate()
            else
                showDate =
                    s.getFullYear() +
                    '-' +
                    (s.getMonth() + 1) +
                    '-0' +
                    s.getDate()
        } else if (s.getMonth() < 9) {
            if (s.getDate() > 9)
                showDate =
                    s.getFullYear() +
                    '-0' +
                    (s.getMonth() + 1) +
                    '-' +
                    s.getDate()
            else
                showDate =
                    s.getFullYear() +
                    '-0' +
                    (s.getMonth() + 1) +
                    '-0' +
                    s.getDate()
        }
        return showDate
    }

    renderNullRemittingBankId = () => {
        if (!this.state.currency_to || !this.state.currency_from) {
            return null
        }
        const adjusted_remittance_reference =
            'FS' +
            this.props.app_state.current_client.id.toString().padStart(6, '0')

        const remittanceTotal = Number(this.props.amount_from)
        const feeVisibleAmount = Number(this.props.fee_visible_amount)
        const totalRemittance = remittanceTotal + feeVisibleAmount

        return (
            <React.Fragment>
                <form onSubmit={this.handleSubmit}>
                    <GridContainer>
                        {this.props.no_stepper ? null : (
                            <GridItem xs={12} sm={12} md={12}>
                                <HorizontalStepper
                                    transfer_status={this.props.transfer_status}
                                />
                            </GridItem>
                        )}
                        <GridItem
                            xs={12}
                            sm={12}
                            md={this.props.no_admin ? 12 : 6}
                        >
                            <Card style={{ height: '90%' }}>
                                <CardHeader>
                                    <h4>Remittance Information</h4>
                                </CardHeader>
                                <CardBody>
                                    <GridContainer>
                                        <GridItem xs={12}>
                                            {this.state.audCollection != true && 
                                            <p>
                                                Your foreign exchange
                                                transaction is now in process
                                                and awaiting settlement.
                                            </p>
                                            }
                                            {this.state.audCollection  &&
                                            <p>
                                                Your <strong>AUD Collection transaction</strong> is now in process.
                                            </p>
                                            }
                                            {this.state.audCollection != true && 
                                            <p>
                                                We confirm the exchange rate of
                                                {` `}
                                                <strong>
                                                    {Number(
                                                        this.props.rate
                                                    ).toFixed(4)}
                                                    {` `}
                                                </strong>
                                                and the amount of
                                                {` `}
                                                <strong>
                                                    {Number(
                                                        this.props.amount_to
                                                    ).toFixed(2)}
                                                    {` `}
                                                </strong>
                                                <div
                                                    className={`currency-flag currency-flag-${this.state.currency_to.iso_alpha_3.toLowerCase()}`}
                                                />
                                                {` `}
                                                <strong>
                                                    {
                                                        this.state.currency_to
                                                            .full_name
                                                    }
                                                    {` `}
                                                </strong>
                                                will be credited to your
                                                nominated beneficiary.
                                            </p>
                                            }
                                            {this.state.audCollection  &&
                                                <p>
                                                We confirm the amount of
                                                {` `}
                                                <strong>
                                                    {Number(
                                                        this.props.amount_to
                                                    ).toFixed(2)}
                                                    {` `}
                                                </strong>
                                                <div
                                                    className={`currency-flag currency-flag-${this.state.currency_to.iso_alpha_3.toLowerCase()}`}
                                                />
                                                {` `}
                                                <strong>
                                                    {
                                                        this.state.currency_to
                                                            .full_name
                                                    }
                                                    {` `}
                                                </strong>
                                                will be credited to your
                                                nominated beneficiary.
                                            </p>
                                            }
                                            <p>
                                                You will need to remit your
                                                {` `}
                                                <strong>
                                                    {Number(
                                                        totalRemittance
                                                    ).toFixed(2)}
                                                </strong>
                                                {` `}
                                                <div
                                                    className={`currency-flag currency-flag-${this.state.currency_from.iso_alpha_3.toLowerCase()}`}
                                                />
                                                {` `}
                                                <strong>
                                                    {
                                                        this.state.currency_from
                                                            .full_name
                                                    }
                                                    {` `}
                                                </strong>
                                                to our Client Settlement
                                                Account.
                                                {` `}
                                                {feeVisibleAmount > 0 && (
                                                    <>
                                                        This amount includes the
                                                        conversion amount of{' '}
                                                        {remittanceTotal.toFixed(
                                                            2
                                                        )}{' '}
                                                        and a fee of{' '}
                                                        {feeVisibleAmount.toFixed(
                                                            2
                                                        )}
                                                        .
                                                    </>
                                                )}
                                            </p>
                                            <p>
                                                <strong>
                                                    Details of this will be
                                                    emailed to you shortly.
                                                </strong>
                                            </p>
                                        </GridItem>
                                    </GridContainer>
                                </CardBody>
                            </Card>
                        </GridItem>
                    </GridContainer>
                </form>
            </React.Fragment>
        )
    }

    render() {
        const { classes } = this.props
        // console.log(this.props)

        if (
            !this.props.app_state.current_client ||
            // !this.state.loaded ||
            !this.state.currency_from ||
            !this.state.currency_to
        ) {
            // console.log("render null")
            // console.log(this.props.app_state.current_client)
            // console.log(this.state.loaded)
            // console.log(this.state.currency_from)
            // console.log(this.state.currency_to)
            return null
        }

        if (this.props.ledgerFrom) {
            return (
                <React.Fragment>
                    <GridContainer>
                        {/* <GridItem xs={12} sm={12} md={12}>
                            <HorizontalStepper
                                transfer_status={this.props.transfer_status}
                            />
                        </GridItem> */}
                        <GridItem xs={12} sm={12} md={12}>
                            <Card style={{ height: '90%' }}>
                                <CardHeader>
                                    <h4>Remittance Information</h4>
                                </CardHeader>
                                <CardBody>
                                    <p>
                                        Remittance for this transfer is coming
                                        fom your Currency Balance.
                                    </p>
                                    <p>
                                        No payment is required to process this
                                        transfer and it is now proceeding.
                                    </p>
                                </CardBody>
                            </Card>
                        </GridItem>
                    </GridContainer>
                </React.Fragment>
            )
        }

        if (!this.state.entity_bank || !this.state.entity_bank_account) {
            return this.renderNullRemittingBankId()
        }

        if (!this.state.loaded) {
            // console.log('!this.state.loaded')
            return null
        }

        // let remittance_reference = `${this.state.entity_bank_account.reference_prefix || ""}${this.props.client_id}`;
        let adjusted_remittance_reference
        if (this.state.entity_bank_account.reference_mandatory) {
            adjusted_remittance_reference = this.state.entity_bank_account
                .reference_mandatory
        } else if (this.state.entity_bank_account.reference_prefix) {
            adjusted_remittance_reference =
                this.state.entity_bank_account.reference_prefix +
                this.props.app_state.current_client.id
                    .toString()
                    .padStart(6, '0')
        } else {
            adjusted_remittance_reference =
                // 'FS' +
                this.props.app_state.current_client.id
                    .toString()
                    .padStart(6, '0')
        }

        const remittanceTotal = Number(this.props.amount_from)
        const feeVisibleAmount = Number(this.props.fee_visible_amount)
        const totalRemittance = remittanceTotal + feeVisibleAmount

        return (
            <React.Fragment>
                <form onSubmit={this.handleSubmit}>
                    <GridContainer>
                        {this.props.no_stepper ? null : (
                            <GridItem xs={12} sm={12} md={12}>
                                <HorizontalStepper
                                    transfer_status={this.props.transfer_status}
                                />
                            </GridItem>
                        )}
                        <GridItem
                            xs={12}
                            sm={12}
                            md={this.props.no_admin ? 12 : 6}
                        >
                            <Card style={{ height: '90%' }}>
                                <CardHeader>
                                    <h3 className={classes.pageHeadings}>
                                        Remittance Information
                                    </h3>
                                </CardHeader>
                                <CardBody>
                                    <GridContainer>
                                        <GridItem xs={12}>
                                            <p>
                                                Your foreign exchange
                                                transaction is now in process and
                                                awaiting settlement.
                                            </p>
                                            <p>
                                                We confirm the exchange rate of
                                                {` `}
                                                <strong>
                                                    {Number(
                                                        this.props.rate
                                                    ).toFixed(4)}
                                                    {` `}
                                                </strong>
                                                and the amount of
                                                {` `}
                                                <strong>
                                                    {Number(
                                                        this.props.amount_to
                                                    ).toFixed(2)}
                                                    {` `}
                                                    <div
                                                        className={`currency-flag currency-flag-${this.state.currency_to.iso_alpha_3.toLowerCase()}`}
                                                    />
                                                    {` `}
                                                    {
                                                        this.state.currency_to
                                                            .full_name
                                                    }
                                                    {` `}
                                                </strong>
                                                will be credited to your
                                                nominated beneficiary.
                                            </p>
                                            <p>
                                                Please remit your{' '}
                                                <strong>
                                                    {totalRemittance.toFixed(2)}
                                                    {` `}
                                                </strong>
                                                <div
                                                    className={`currency-flag currency-flag-${this.state.currency_from.iso_alpha_3.toLowerCase()}`}
                                                />
                                                {` `}
                                                <strong>
                                                    {
                                                        this.state.currency_from
                                                            .full_name
                                                    }
                                                    {` `}
                                                </strong>
                                                to the
                                                {` `}
                                                <strong>
                                                    {
                                                        this.state
                                                            .entity_bank_account
                                                            .eba_accout_name
                                                    }
                                                    {` `}
                                                    Client Settlement Account
                                                </strong>
                                                {` `}
                                                using your Mandatory Reference{' '}
                                                <strong>
                                                    {
                                                        adjusted_remittance_reference
                                                    }
                                                </strong>
                                                .{`  `}
                                                {feeVisibleAmount ? (
                                                    <>
                                                        This amount includes the
                                                        conversion amount of{' '}
                                                        {remittanceTotal.toFixed(
                                                            2
                                                        )}{' '}
                                                        and a fee of{' '}
                                                        {feeVisibleAmount.toFixed(
                                                            2
                                                        )}
                                                        .
                                                    </>
                                                ) : ''}
                                            </p>
                                        </GridItem>
                                    </GridContainer>
                                    <GridContainer>
                                        <GridItem xs={12} sm={12} md={12}>
                                            <table
                                                style={{
                                                    width: '100%',
                                                    border: '1px solid #a1a1a1',
                                                }}
                                            >
                                                <tbody>
                                                    <tr
                                                        className={
                                                            classes.backgroundBlue
                                                        }
                                                    >
                                                        <th
                                                            className={
                                                                classes.tableHeaders
                                                            }
                                                        >
                                                            Deposit Amount
                                                        </th>
                                                        <td
                                                            className={
                                                                classes.tableCells
                                                            }
                                                        >
                                                            <React.Fragment>
                                                                <strong>
                                                                    {totalRemittance.toFixed(
                                                                        2
                                                                    )}
                                                                    {` `}
                                                                    <div
                                                                        className={`currency-flag currency-flag-${this.state.currency_from.iso_alpha_3.toLowerCase()}`}
                                                                    />
                                                                    {` `}
                                                                    {
                                                                        this
                                                                            .state
                                                                            .currency_from
                                                                            .iso_alpha_3
                                                                    }
                                                                </strong>
                                                            </React.Fragment>
                                                        </td>
                                                    </tr>
                                                </tbody>
                                            </table>

                                            <h5
                                                className={
                                                    classes.pageSubHeadings
                                                }
                                                style={{ marginTop: 24 }}
                                            >
                                                Client Settlement Account
                                            </h5>
                                            <table
                                                style={{
                                                    width: '100%',
                                                    border: '1px solid #a1a1a1',
                                                }}
                                            >
                                                <tbody>
                                                    <tr
                                                        className={
                                                            classes.backgroundBlue
                                                        }
                                                    >
                                                        <th
                                                            className={
                                                                classes.tableHeaders
                                                            }
                                                        >
                                                            Account Name
                                                        </th>
                                                        <td
                                                            className={
                                                                classes.tableCells
                                                            }
                                                        >
                                                            <React.Fragment>
                                                                <strong>
                                                                    {
                                                                        this
                                                                            .state
                                                                            .entity_bank_account
                                                                            .eba_accout_name
                                                                    }
                                                                </strong>
                                                            </React.Fragment>
                                                        </td>
                                                    </tr>
                                                    <tr>
                                                        <th
                                                            className={
                                                                classes.tableHeaders
                                                            }
                                                        >
                                                            Account Address
                                                        </th>
                                                        <td
                                                            className={
                                                                classes.tableCells
                                                            }
                                                        >
                                                            <React.Fragment>
                                                                {
                                                                    this.state
                                                                        .entity_bank_account
                                                                        .address_line_1
                                                                }
                                                                {` `}
                                                                {
                                                                    this.state
                                                                        .entity_bank_account
                                                                        .address_line_2
                                                                }
                                                                {` `}
                                                                {
                                                                    this.state
                                                                        .entity_bank_account
                                                                        .address_suburb
                                                                }
                                                                {` `}
                                                                {
                                                                    this.state
                                                                        .entity_bank_account
                                                                        .address_state
                                                                }
                                                                {` `}
                                                                {
                                                                    this.state
                                                                        .entity_bank_account
                                                                        .address_postcode
                                                                }
                                                                <br />
                                                                {
                                                                    this.state
                                                                        .entity_bank_account
                                                                        .entity_bank_account_address_country
                                                                }
                                                            </React.Fragment>
                                                        </td>
                                                    </tr>
                                                    <tr
                                                        className={
                                                            classes.backgroundBlue
                                                        }
                                                    >
                                                        <th
                                                            className={
                                                                classes.tableHeaders
                                                            }
                                                        >
                                                            Bank Name
                                                        </th>
                                                        <td
                                                            className={
                                                                classes.tableCells
                                                            }
                                                        >
                                                            <React.Fragment>
                                                                <strong>
                                                                    {
                                                                        this
                                                                            .state
                                                                            .entity_bank
                                                                            .eb_long_name
                                                                    }
                                                                </strong>
                                                            </React.Fragment>
                                                        </td>
                                                    </tr>
                                                    <tr>
                                                        <th
                                                            className={
                                                                classes.tableHeaders
                                                            }
                                                        >
                                                            Bank Address
                                                        </th>
                                                        <td
                                                            className={
                                                                classes.tableCells
                                                            }
                                                        >
                                                            <React.Fragment>
                                                                {
                                                                    this.state
                                                                        .entity_bank
                                                                        .address_line_1
                                                                }
                                                                {` `}
                                                                {
                                                                    this.state
                                                                        .entity_bank
                                                                        .address_line_2
                                                                }
                                                                {` `}
                                                                {
                                                                    this.state
                                                                        .entity_bank
                                                                        .address_suburb
                                                                }
                                                                {` `}
                                                                {
                                                                    this.state
                                                                        .entity_bank
                                                                        .address_state
                                                                }
                                                                {` `}
                                                                {
                                                                    this.state
                                                                        .entity_bank
                                                                        .address_postcode
                                                                }
                                                                <br />
                                                                {
                                                                    this.state
                                                                        .entity_bank
                                                                        .entity_bank_account_address_country
                                                                }
                                                            </React.Fragment>
                                                        </td>
                                                    </tr>
                                                    {this.state
                                                        .entity_bank_account
                                                        .eba_swift && (
                                                        <tr
                                                            className={
                                                                classes.backgroundBlue
                                                            }
                                                        >
                                                            <th
                                                                className={
                                                                    classes.tableHeaders
                                                                }
                                                            >
                                                                SWIFT Code
                                                            </th>
                                                            <td
                                                                className={
                                                                    classes.tableCells
                                                                }
                                                            >
                                                                <React.Fragment>
                                                                    {
                                                                        this
                                                                            .state
                                                                            .entity_bank_account
                                                                            .eba_swift
                                                                    }
                                                                </React.Fragment>
                                                            </td>
                                                        </tr>
                                                    )}
                                                    {this.state
                                                        .entity_bank_account
                                                        .eba_accout_number && (
                                                        <tr>
                                                            <th
                                                                className={
                                                                    classes.tableHeaders
                                                                }
                                                            >
                                                                Account Number
                                                            </th>
                                                            <td
                                                                className={
                                                                    classes.tableCells
                                                                }
                                                            >
                                                                <React.Fragment>
                                                                    {
                                                                        this
                                                                            .state
                                                                            .entity_bank_account
                                                                            .eba_accout_number
                                                                    }
                                                                </React.Fragment>
                                                            </td>
                                                        </tr>
                                                    )}
                                                    {this.state
                                                        .entity_bank_account
                                                        .eba_sortcode && (
                                                        <tr
                                                            className={
                                                                classes.backgroundBlue
                                                            }
                                                        >
                                                            <th
                                                                className={
                                                                    classes.tableHeaders
                                                                }
                                                            >
                                                                Sort Code
                                                            </th>
                                                            <td
                                                                className={
                                                                    classes.tableCells
                                                                }
                                                            >
                                                                <React.Fragment>
                                                                    {
                                                                        this
                                                                            .state
                                                                            .entity_bank_account
                                                                            .eba_sortcode
                                                                    }
                                                                </React.Fragment>
                                                            </td>
                                                        </tr>
                                                    )}
                                                    {this.state
                                                        .entity_bank_account
                                                        .eba_bsb && (
                                                        <tr
                                                            className={
                                                                classes.backgroundBlue
                                                            }
                                                        >
                                                            <th
                                                                className={
                                                                    classes.tableHeaders
                                                                }
                                                            >
                                                                BSB
                                                            </th>
                                                            <td
                                                                className={
                                                                    classes.tableCells
                                                                }
                                                            >
                                                                <React.Fragment>
                                                                    {
                                                                        this
                                                                            .state
                                                                            .entity_bank_account
                                                                            .eba_bsb
                                                                    }
                                                                </React.Fragment>
                                                            </td>
                                                        </tr>
                                                    )}
                                                    {this.state
                                                        .entity_bank_account
                                                        .aba_routing_number && (
                                                        <tr
                                                            className={
                                                                classes.backgroundBlue
                                                            }
                                                        >
                                                            <th
                                                                className={
                                                                    classes.tableHeaders
                                                                }
                                                            >
                                                                ABA Routing
                                                            </th>
                                                            <td
                                                                className={
                                                                    classes.tableCells
                                                                }
                                                            >
                                                                <React.Fragment>
                                                                    {
                                                                        this
                                                                            .state
                                                                            .entity_bank_account
                                                                            .aba_routing_number
                                                                    }
                                                                </React.Fragment>
                                                            </td>
                                                        </tr>
                                                    )}
                                                    {this.state
                                                        .entity_bank_account
                                                        .iban && (
                                                        <tr>
                                                            <th
                                                                className={
                                                                    classes.tableHeaders
                                                                }
                                                            >
                                                                IBAN
                                                            </th>
                                                            <td
                                                                className={
                                                                    classes.tableCells
                                                                }
                                                            >
                                                                <React.Fragment>
                                                                    {
                                                                        this
                                                                            .state
                                                                            .entity_bank_account
                                                                            .iban
                                                                    }
                                                                </React.Fragment>
                                                            </td>
                                                        </tr>
                                                    )}
                                                </tbody>
                                            </table>
                                            <br />
                                            <table
                                                style={{
                                                    width: '100%',
                                                    border: '1px solid #a1a1a1',
                                                }}
                                            >
                                                <tbody>
                                                    <tr
                                                        className={
                                                            classes.backgroundBlue
                                                        }
                                                    >
                                                        <th
                                                            className={
                                                                classes.tableHeaders
                                                            }
                                                        >
                                                            Mandatory Reference
                                                        </th>
                                                        <td
                                                            className={
                                                                classes.tableCells
                                                            }
                                                        >
                                                            <React.Fragment>
                                                                <strong>
                                                                    {
                                                                        adjusted_remittance_reference
                                                                    }
                                                                </strong>
                                                            </React.Fragment>
                                                        </td>
                                                    </tr>
                                                </tbody>
                                            </table>
                                        </GridItem>
                                    </GridContainer>
                                </CardBody>
                            </Card>
                        </GridItem>

                        {this.props.no_admin ? null : (
                            <GridItem xs={12} sm={12} md={6}>
                                <Card style={{ height: '90%' }}>
                                    <CardHeader>
                                        <h6>Administration</h6>
                                    </CardHeader>
                                    <CardBody>
                                        <GridContainer>
                                            <GridItem xs={12} sm={12} md={6}>
                                                Date payment received:
                                                <br />
                                                <FormControl fullWidth>
                                                    <Datetime
                                                        closeOnSelect={true}
                                                        inputProps={{
                                                            disabled: !this
                                                                .props
                                                                .edit_mode,
                                                            placeholder: this.getFullDate(
                                                                new Date(
                                                                    this.state.payout_datetime
                                                                )
                                                            ),
                                                            //value: this.getFullDate(new Date(this.state.transaction_datetime)),
                                                        }}
                                                        onChange={event => {
                                                            let selDate = this.getFullDateTime(
                                                                new Date(
                                                                    event.toDate()
                                                                )
                                                            )
                                                            this.setState({
                                                                payout_datetime: selDate,
                                                            })
                                                        }}
                                                    />
                                                </FormControl>
                                            </GridItem>
                                        </GridContainer>
                                        {/* <GridContainer>
                    <GridItem xs={12}>
                      <h6>Communication</h6>
                      <TransferDetailRemittanceCommunication
                        id={this.props.client_id}
                        rate={this.props.rate}
                        amount_to={this.props.amount_to}
                        currency_to={this.state.currency_to.full_name}
                        currency_to_iso3={this.state.currency_to.iso_alpha_3}
                        amount_from={this.props.amount_from}
                        currency_from={this.state.currency_from.full_name}
                        currency_from_iso3={this.state.currency_from.iso_alpha_3}
                        eba_account_name={this.state.entity_bank_account.eba_accout_name}
                        enabled={true}
                        remitting_bank_id={this.props.remitting_bank_id}
                        remittance_reference={remittance_reference}
                      />
                    </GridItem>
                  </GridContainer> */}
                                    </CardBody>
                                </Card>
                            </GridItem>
                        )}
                    </GridContainer>
                    {/*<Button*/}
                    {/*  color="primary"*/}
                    {/*  type="submit"*/}
                    {/*  className={classes.updateProfileButton}*/}
                    {/*  disabled={!this.props.edit_mode}*/}
                    {/*>*/}
                    {/*  Update Transfer*/}
                    {/*</Button>*/}
                </form>
            </React.Fragment>
        )
    }
}

TransferDetailRemittance.propTypes = {
    classes: PropTypes.object.isRequired,
}

// export default withStyles(styles)(TransferDetailRemittance);
const mapStateToProps = state => {
    return {
        app_state: state.app_state,
        language: state.language,
        // transfers_dashboard: state.transfers_dashboard
    }
}

const mapDispatchToProps = dispatch => {
    return null
}

const TransferDetailRemittanceContainer = connect(
    mapStateToProps,
    mapDispatchToProps
)(withStyles(styles)(TransferDetailRemittance))

export default TransferDetailRemittanceContainer
